import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadBalanceService } from '../load-balance-service/load-balance.service';
import { LoadBalanceRecordRow } from 'src/app/shared/models/request-loadBalance-record.model';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { take } from 'cypress/types/lodash';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-load-balance-dashboard',
  templateUrl: './load-balance-dashboard.component.html',
  styleUrls: ['./load-balance-dashboard.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule]
})
export class LoadBalanceDashboardComponent implements OnInit, OnDestroy {

  loadBalanceRecords:LoadBalanceRecordRow[]= []
  operatorsForm: FormArray;
  private unsubscribe = new Subject<void>()

  constructor(private loadBalanceService:LoadBalanceService, private fb: FormBuilder) {

    this.operatorsForm = this.fb.array([])

  }

  ngOnInit(): void {
    this.getUsers()
  }

  getUsers(){
    this.loadBalanceService.getLoadBalance().pipe(takeUntil(this.unsubscribe)).subscribe((loadbalance) => {
      this.loadBalanceRecords = loadbalance.content.map((item) => ({...item, selected:false}))
      this.initForm()
    })
  }

  initForm(){
    this.loadBalanceRecords.forEach((record) => {
      const group = this.fb.group({
        onVacation: new FormControl(record.onVacation),
        weight: new FormControl(record.weight)
      })
      this.operatorsForm.push(group)
    })
  }

  getOperatorFormGroup(index: number): FormGroup {
    return this.operatorsForm.at(index) as FormGroup;
  }

  updateOperatorVacation(index: number): void {
    const record = this.loadBalanceRecords[index];
    const formGroup = this.operatorsForm.at(index) as FormGroup;

    const updatedData = {
      ...record,
      onVacation: formGroup.get('onVacation')?.value,
    };
    this.loadBalanceService.updateVacation(updatedData).pipe(takeUntil(this.unsubscribe)).subscribe((res) => console.log(res, "response"))
  }

  updateOperatorWeight(index: number): void {
    const record = this.loadBalanceRecords[index];
    const formGroup = this.operatorsForm.at(index) as FormGroup;

    const updatedData = {
      ...record,
      weight: formGroup.get('weight')?.value,
    };
    this.loadBalanceService.updateWeight(updatedData).pipe(takeUntil(this.unsubscribe)).subscribe((res) => console.log(res, "response"))
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
}
