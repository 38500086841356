<div class="grid" [formGroup]="form">
  <div>
    <label class="label" for="applications">N° di candidature</label>
    <input type="number" id="applications" formControlName="applications" min="0" readonly>
  </div>
  <div>
    <label class="label" for="selected">N° in cui è stata selezionata</label>
    <input type="number" id="selected" formControlName="selected" min="0" readonly>
  </div>
  <div>
    <label class="label" for="discarded">N° in cui è stata scartata</label>
    <input type="number" id="discarded" formControlName="discarded" min="0" readonly>
  </div>
  <div>
    <label class="label" for="chosen">N° in cui è stata scelta</label>
    <input type="number" id="chosen" formControlName="chosen" min="0" readonly>
  </div>
  <div>
    <label class="label" for="ignored">N° in cui non è stata scelta</label>
    <input type="number" id="ignored" formControlName="ignored" min="0" readonly>
  </div>
  <div>
    <label class="label" for="substituted">N° in cui è stata sostituita</label>
    <input type="number" id="substituted" formControlName="substituted" min="0" readonly>
  </div>
  <div>
    <label class="label" for="chosendate">Ultima data in cui è stata scelta</label>
    <input type="date" id="chosendate" formControlName="chosenDate" readonly>
  </div>
  <div>
    <label class="label" for="latestApplication">Ultima candidatura</label>
    <input type="date" id="latestApplication" formControlName="latestApplication" readonly>
  </div>
</div>
<h4 class="mt-5 mb-3">Richieste in cui è coinvolta</h4>

<form [formGroup]="filters" class="d-flex mb-3 py-3 gap-3 align-items-end">
  <div class="w-25 d-flex flex-column gap-1">
    <label for="phase" class="form-label">Fase candidatura</label>
    <select class="form-select" aria-label="Default select example" id="phase" formControlName="phase">
      <option value="">TUTTE</option>
      <option value="Candidata">CANDIDATA</option>
      <option value="Selezionata">SELEZIONATA</option>
      <option value="Scelta">SCELTA</option>
      <option value="Scartata">SCARTATA</option>
    </select>
  </div>
  <button class="applyButton btn btn-primary text-light" (click)="applyFilters()">
    Applica
  </button>
</form>
<table class="table table-striped p-3">
  <thead>
    <th>Numero richiesta</th>
    <th>Fase candidatura</th>
    <th>Data aggiornamento candidatura</th>
    <th>Motivo per cui è stata scartata</th>
  </thead>
  <tbody>
    <tr *ngFor="let row of engagedRequests">
      <td><a [href]="openRequestPage(row.requestID)" target="_blank">{{row.requestID}}</a></td>
      <td>{{row.phase}}</td>
      <td>{{row.timestamp | date: 'dd MMM yyyy'}}</td>
      <td>{{row.discardedReason === '' ? '-' : row.discardedReason }}</td>
    </tr>
    <tr *ngIf="engagedRequests.length === 0">
      <td colspan="10" class="text-center">Non sono state trovate richieste.</td>
    </tr>
  </tbody>
</table>
<div class="row">
  <p class="col">Sono presenti <b>{{collectionSize}}</b> richieste in totale.</p>
  <ngb-pagination class="col d-flex justify-content-end" *ngIf="engagedRequests.length > 0" [maxSize]="5"
    [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSize"
    (pageChange)="onPageChange()"></ngb-pagination>
</div>
