<div class="container p-3">
  <div>
    <h2 class="text-center text-primary">Prodotti</h2>
    <h4 class="text-center pointer" (click)="collapse.toggle()">
      Filtri<i
        class="bi ms-2"
        style="font-size: 16px"
        [ngClass]="isCollapsed ? 'bi-caret-down-fill' : 'bi-caret-up-fill'"
      ></i>
    </h4>
    <form
      class="row m-3 p-3 bg-info rounded"
      [formGroup]="filters"
      #collapse="ngbCollapse"
      [(ngbCollapse)]="isCollapsed"
    >
      <div class="col-6 mb-3">
        <label for="service" class="form-label">Seleziona Servizio</label>
        <app-fh-service-select
          [value]="filters.get('service')?.value"
          (serviceSelected)="updateFHService($event)"
          [serviceID]="serviceIDfilter"
        ></app-fh-service-select>
      </div>

      <div class="col-6 mb-3">
        <label for="searchKey" class="form-label">Search Key</label>
        <input
          type="string"
          id="searchKey"
          class="form-control"
          formControlName="searchKey"
        />
      </div>
      <button
        class="col-3 btn btn-primary m-auto text-light"
        (click)="collapse.toggle(); applyFilters()"
      >
        Applica
      </button>
      <button class="col-3 btn btn-secondary m-auto text-light" (click)="collapse.toggle(); resetFilters()">reset</button>
    </form>

    <div class="d-flex flex-row-reverse gap-2">
      <button class="btn btn-info" (click)="createProduct()"><i class="bi bi-plus-square"></i></button>
    </div>

    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Nome</th>
          <th scope="col">Nome servizio</th>
          <th scope="col">Business Line</th>
          <th scope="col">Tipo di cliente</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let product of products">
          <th scope="row">{{ product.id }}</th>
          <td>{{ product.name }}</td>
          <td>{{ product.service.name }}</td>
          <td>{{ product.service.businessLine }}</td>
          <td>{{ product.service.customerType }}</td>
          <td>
            <a [routerLink]="['/products', product.id]">
            <button
              class="btn btn-primary px-2 py-1"
              (click)="openProductDetail(product.id)"
            >
              <i class="bi bi-search text-light pointer"></i>
            </button>
          </a>
          </td>
          <td>
            <button
              class="btn btn-danger px-2 py-1"
              (click)="deleteProduct(product.id)"
            >
              <i class="bi bi-x-lg text-light pointer"></i>
            </button>
          </td>
        </tr>
        <tr *ngIf="products.length === 0">
          <td colspan="10" class="text-center">
            Non sono stati trovati prodotti.
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <p class="col">
        Sono presenti <b>{{ collectionSize }}</b> prodotti in totale.
      </p>
      <ngb-pagination
        class="col d-flex justify-content-end"
        *ngIf="products.length > 0"
        [maxSize]="5"
        [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="collectionSize"
        (pageChange)="onPageChange()"
      ></ngb-pagination>
    </div>
  </div>
</div>

<app-alert *ngIf="showDeleteConfirm" (save)="confirmDelete($event)" [data]="{ type: undefined }"
  [title]="'Conferma eliminazione'" [message]="'Sei sicuro di voler eliminare il prodotto ' + selectedProductID + '?'">
</app-alert>
