import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { RequestOperationalInfo } from 'npx-family-happy-common';
import { Subject, takeUntil } from 'rxjs';
import { RequestService } from '../request-service/request.service';
import { CommonModule } from '@angular/common';
import { CaregiverSelectionComponent } from '../caregiver-scoreboard/caregiver-selection/caregiver-selection.component';

@Component({
  selector: 'app-request-operational-info',
  templateUrl: './request-operational-info.component.html',
  styleUrls: ['./request-operational-info.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, CaregiverSelectionComponent]
})
export class RequestOperationalInfoComponent implements OnInit, OnDestroy {
  @Input() inputData!: RequestOperationalInfo;
  @Input() requestID!: number;
  @Input() requestCategory!: string;
  @Output() dataUpdated = new EventEmitter<RequestOperationalInfo>();

  form = new UntypedFormGroup({
    adTitle: new UntypedFormControl(''),
    status: new UntypedFormControl('NUOVA', Validators.required),
    reminder: new UntypedFormControl('', Validators.required),
    notes: new UntypedFormControl(''),
    parentAttitude: new UntypedFormControl('')
  })

  time = new FormControl('3600000')

  private unsubscribe = new Subject<void>();

  constructor(private requestService: RequestService) { }

  ngOnInit(): void {
    this.initForm();
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((_) => {
        this.pushDataChanges();
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getCategoryLabel() {
    switch (this.requestCategory) {
      case 'NURSE':
        return 'Badanti';
      case 'PETSITTER':
        return 'Petsitter';
      case 'BABYSITTER':
      default:
        return 'Babysitter';
    }
  }

  setReminder() {
    if (this.form.get('reminder')?.valid) {
      const formValue = this.form.value;
      const date = new Date(formValue.reminder);
      const time = (this.time.value !== null) ? parseInt(this.time.value) : 3600000
      this.requestService.setReminder(date, time);
    }
  }

  generateFormLink() {
    this.requestService.generateParentFormLink();
  }

  private initForm() {
    this.form.setValue({
      adTitle: this.inputData.adTitle,
      status: this.inputData.status,
      reminder: this.inputData.reminder,
      notes: this.inputData.notes,
      parentAttitude: this.inputData.parentAttitude
    });
  }

  private pushDataChanges() {
    const formValue = this.form.value;
    const newOpInfo: RequestOperationalInfo = {
      adTitle: formValue.adTitle,
      status: formValue.status,
      statusUpdated: this.inputData.statusUpdated,
      reminder: formValue.reminder,
      notes: formValue.notes,
      parentAttitude: formValue.parentAttitude
    }
    this.dataUpdated.emit(newOpInfo);
  }
}
