import { EmailTemplateService } from './../email-services/email.service';
import { MailTemplateRow } from './../../../shared/models/mail.model';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { FHService, MailTemplate, ModalService } from 'npx-family-happy-common';
import { CommonModule } from '@angular/common';
import { FHServiceSelectComponent } from 'src/app/shared/components/custom-select/fh-service-select/fh-service-select.component';
import { NgbCollapseModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';

@Component({
  selector: 'app-email-dashboard',
  templateUrl: './email-dashboard.component.html',
  styleUrls: ['./email-dashboard.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, FHServiceSelectComponent, NgbCollapseModule, RouterModule, NgbPaginationModule, AlertComponent]
})
export class EmailDashboardComponent implements OnInit {
  filters = new UntypedFormGroup({
    serviceID: new UntypedFormControl(null),
    type: new UntypedFormControl(null),
  })
  serviceIDfilter!: number | null

  showDeleteConfirm = false
  isCollapsed = true

  page = 1
  pageSize = 10
  collectionSize = 0
  selectedMailID = 0
  mails: MailTemplateRow[] = []

  private unsubscribe = new Subject<void>();

  constructor(private emailTemplateService: EmailTemplateService, private router: Router, private modalService: ModalService) {

    this.updateFilters()
  }

  createMailTemplate() {
    this.router.navigate(['/emails/create']);
  }

  openMailDetail(id: number) {
    this.router.navigate(['/emails/' + id]);
  }
  deleteProduct(id: number) {
    this.selectedMailID = id;
    this.showDeleteConfirm = true;
  }
  onPageChange() {
    if (this.page > 0) {
      this.loadMails();
    }
  }
  confirmDelete(answer: boolean) {
    if (answer && this.selectedMailID > -1) {
      this.emailTemplateService.deleteTemplateByID(this.selectedMailID).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
        this.modalService.showSuccessMessage('Template eliminato');
        this.loadMails()
      });
    }
    this.showDeleteConfirm = false;
  }

  private loadMails() {
    this.emailTemplateService.getAllTemplates(this.page - 1, this.pageSize, this.filters.value).pipe(takeUntil(this.unsubscribe)).subscribe((mailsPage) => {
      this.mails = mailsPage.content.map((item) => ({ ...item, selected: false }));
      this.page = mailsPage.number + 1;
      this.pageSize = mailsPage.size;
      this.collectionSize = mailsPage.totalElements;
    })
  }

  applyFilters() {
    this.emailTemplateService.setFilters(this.filters.value)
    this.loadMails();
  }

  updateFilters() {
    this.emailTemplateService.getFilters().subscribe((filters) => {
      this.serviceIDfilter = filters.serviceID
      this.filters.setValue({
        serviceID: filters.serviceID,
        type: filters.type,
      })
    })
  }

  resetFilters() {
    this.filters.setValue({
      type: null,
      serviceID: null
    })
    this.serviceIDfilter = null
    this.emailTemplateService.setFilters(this.filters.value)
    this.loadMails();
  }

  updateFHService(service: FHService | undefined) {
    if (service === undefined) {
      this.filters.controls.serviceID.reset();
    } else {
      this.filters.controls.serviceID.setValue(
        service?.id
      );
    }
  }

  ngOnInit(): void {
    this.loadMails()
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

}
