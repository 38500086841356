import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, FormControlName, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { CaregiverSelectionService } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/caregiver-selection.service';
import { CaregiverSubRow } from 'src/app/shared/models/caregiver-subscription.model';
import { getURLRelated } from 'src/app/shared/utils.functions';

@Component({
  selector: 'app-caregiver-subscription-dashboard',
  templateUrl: './caregiver-subscription-dashboard.component.html',
  styleUrls: ['./caregiver-subscription-dashboard.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, NgbCollapseModule, NgbPaginationModule]
})
export class CaregiverSubscriptionDashboardComponent
  implements OnInit, OnDestroy
{
  isCollapsed = false;
  businessLine: string = 'BABYSITTER';
  pageSize: number = 10;
  page: number = 1;
  caregiverPage: Array<CaregiverSubRow> = [];
  collectionSize = 0;
  private unsubscribe = new Subject<void>();

  filters = new UntypedFormGroup({
    businessLine: new UntypedFormControl(''),
  });

  constructor(private caregiverSelectionService: CaregiverSelectionService) {}

  applyFilters() {
    this.page = 1;
    this.businessLine = this.filters.controls.businessLine.value;
    this.loadCaregivers();
  }
  ngOnInit(): void {
    this.loadCaregivers();
  }
  private loadCaregivers() {
    this.caregiverSelectionService
      .getAllCaregiverNotSubscribed(
        this.businessLine,
        this.pageSize,
        this.page - 1
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((caregiverPage) => {
        if(this.businessLine === "BABYSITTER"){
          this.caregiverPage = caregiverPage.content.map((item) => ({
            ...item,
            selected: false,
            docRefLink: getURLRelated('BABYSITTER', item.docRef),
            requestIDLink: getURLRelated('REQUEST', item.requestID.toString()),
          }));
          this.page = caregiverPage.number + 1;
          this.pageSize = caregiverPage.size;
          this.collectionSize = caregiverPage.totalElements;
        } else if (this.businessLine === "PETSITTER"){
          this.caregiverPage = caregiverPage.content.map((item) => ({
            ...item,
            selected: false,
            docRefLink: getURLRelated('PETSITTER', item.docRef),
            requestIDLink: getURLRelated('REQUEST', item.requestID.toString()),
          }));
          this.page = caregiverPage.number + 1;
          this.pageSize = caregiverPage.size;
          this.collectionSize = caregiverPage.totalElements;
        } else if(this.businessLine === "NURSE"){
          this.caregiverPage = caregiverPage.content.map((item) => ({
            ...item,
            selected: false,
            docRefLink: getURLRelated('NURSE', item.docRef),
            requestIDLink: getURLRelated('REQUEST', item.requestID.toString()),
          }));
          this.page = caregiverPage.number + 1;
          this.pageSize = caregiverPage.size;
          this.collectionSize = caregiverPage.totalElements;
        }
      });
  }
  onPageChange() {
    if (this.page > 0) {
      this.loadCaregivers();
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
