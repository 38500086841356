import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Nurse, CertificateService } from 'npx-family-happy-common';

@Component({
  selector: 'app-cv-nurse-template',
  templateUrl: './cv-nurse-template.component.html',
  styleUrls: ['./cv-nurse-template.component.scss'],
  standalone:true,
  imports:[CommonModule]
})
export class CvNurseTemplateComponent implements OnInit {
  @Input() set nurse(nurse: Nurse) {
    this._nurse = nurse;
    if (this._nurse !== undefined) {
      this.showReference = this._nurse.reference && ((this._nurse.reference.f_surname !== null && this._nurse.reference.f_surname !== '') ||
        (this._nurse.reference.f_second_surname !== null && this._nurse.reference.f_second_surname !== ''));
      this.certificates = this._nurse.certificates.nurseCertificateList.filter(item => item !== '').slice(0, 3);
      this.initAvailabilieties();
      this.initAttitude();
      this.initSubjects();
    }
  };
  @Input() isProduction = false;
  @Input() mailServiceURL = '';
  @Input() showModal = false;

  @Input() set generatePages(value: boolean) {
    if (value) {
      this.calcPages();
    } else {
      this.generationDone = false;
    }
  }

  @Input() currentPage!: number;
  //flags: BabysitterLanguage[] = [];
  availabilities: string[] = [];
  homeworkSubjects: string[] = [];
  showReference = false;
  attitude: string = '';
  _nurse!: Nurse;
  pages: string[] = [];
  generationDone: boolean = false;
  previewMode: boolean = true;
  certificates: string[] = [];
  @Output() sendPages = new EventEmitter<number>();

  constructor(public sanitizer: DomSanitizer, private certificateService: CertificateService) { }

  ngOnInit(): void {
    /* this.nurseService.getSingleNurse('TO-01734').subscribe((nurses) => {
      this._nurse = nurses[0];
      console.log('Nurse loaded:', this._nurse);
      this.showReference = (this._nurse.reference.f_surname !== null && this._nurse.reference.f_surname !== '') ||
        (this._nurse.reference.f_second_surname !== null && this._nurse.reference.f_second_surname !== '');
      this.initAvailabilieties();
      this.initAttitude();
      this.initSubjects();
      //this.calcPages();
      this.currentPage = 0;
      this.previewMode = true;
    }) */
    /* console.log(this._nurse, !this._nurse && history.state.data);
    if (!this._nurse && history.state.data) {

      //this._nurse = history.state.data;
    } */
  }

  calcPages() {
    this.pages = [];
    let rightColumn = document.getElementById('right-column') as HTMLDivElement;
    let elY = rightColumn.getBoundingClientRect().top;
    let aboutMe = document.getElementById('about-me-section') as HTMLDivElement;
    //let experienceTitle = document.getElementById('experience-title') as HTMLDivElement;
    let experienceSection = document.getElementById('experience-section') as HTMLDivElement;
    let reference = document.getElementById('reference');
    let currentPage = 0;
    /* console.log('experience-section:', experienceSection.children); */

    //this.pages.push(aboutMe.outerHTML);
    //this.pages[0] += experienceTitle.outerHTML + '<div class="experience-section">';
    this.pages[0] += `<div class="experience-range">`;

    /* for (let i = 0; i < experienceSection.children.length; i++) {
      const element = experienceSection.children[i];
      let newExperienceCluster = '';
      if (element.localName === 'p') {
        this.pages[currentPage] += element.outerHTML;
      }
      if (element.localName === 'div' && element.id !== 'homework-experience') {
        for (let j = 0; j < element.children.length; j++) {
          const child = element.children[j];
          if (i === 1) {
            if (child.localName === 'p') {
              this.pages[currentPage] += child.outerHTML;
            } else {
              for (let k = 0; k < child.children.length; k++) {
                const listItem = child.children[k];
                if (k === 0) {
                  this.pages[currentPage] += '<ul>';
                }
                if ((listItem.getBoundingClientRect().bottom - elY + (currentPage > 0 ? 100 : 50)) / 750 <= (currentPage + 1)) {
                  this.pages[currentPage] += listItem.outerHTML;
                  if (k === child.children.length - 1) {
                    this.pages[currentPage] += '</ul>';
                  }
                } else {
                  this.pages[currentPage] += '</div></div>';
                  //this.pages.push(experienceTitle.outerHTML + '<div class="experience-section"><div class="experience-range">');
                  currentPage++;
                  this.pages[currentPage] += '<ul>';
                  this.pages[currentPage] += listItem.outerHTML;
                  if (k === child.children.length - 1) {
                    this.pages[currentPage] += '</ul>';
                  }
                }
              }
            }
          } else {
            if (child.localName === 'p') {
              newExperienceCluster += child.outerHTML;
              if (j === 1) {
                if ((child.getBoundingClientRect().bottom / 750 - elY + (currentPage > 0 ? 100 : 50)) <= (currentPage + 1)) {
                  this.pages[currentPage] += newExperienceCluster;
                } else {
                  this.pages[currentPage] += '</div></div>';
                  //this.pages.push(experienceTitle.outerHTML + '<div class="experience-section"><div class="experience-range">');
                  currentPage++;
                  this.pages[currentPage] += newExperienceCluster;
                }
              }
            } else {
              for (let k = 0; k < child.children.length; k++) {
                const listItem = child.children[k];
                if (k === 0) {
                  this.pages[currentPage] += '<ul>';
                }
                if ((listItem.getBoundingClientRect().bottom - elY + (currentPage > 0 ? 100 : 50)) / 750 <= (currentPage + 1)) {
                  this.pages[currentPage] += listItem.outerHTML;
                  if (k === child.children.length - 1) {
                    this.pages[currentPage] += '</ul>';
                  }
                } else {
                  this.pages[currentPage] += '</div></div>';
                  //this.pages.push(experienceTitle.outerHTML + '<div class="experience-section"><div class="experience-range">');
                  currentPage++;
                  this.pages[currentPage] += '<ul>';
                  this.pages[currentPage] += listItem.outerHTML;
                  if (k === child.children.length - 1) {
                    this.pages[currentPage] += '</ul>';
                  }
                }
              }
            }
          }
        }
      }
      if (element.id === 'homework-experience') {
        for (let j = 0; j < element.children.length; j++) {
          const child = element.children[j];
          if (child.localName === 'p') {
            newExperienceCluster += child.outerHTML;
          } else {
            for (let k = 0; k < child.children.length; k++) {
              const elem = child.children[k];
              if (elem.localName === 'p') {
                newExperienceCluster += elem.outerHTML;
                if (k === 1) {
                  if ((elem.getBoundingClientRect().bottom - elY + (currentPage > 0 ? 100 : 50)) / 750 <= (currentPage + 1)) {
                    this.pages[currentPage] += newExperienceCluster;
                  } else {
                    this.pages[currentPage] += '</div></div>';
                    //this.pages.push(experienceTitle.outerHTML + '<div class="experience-section"><div class="experience-range">');
                    currentPage++;
                    this.pages[currentPage] += newExperienceCluster;
                  }
                }
              } else {
                for (let l = 0; l < elem.children.length; l++) {
                  const listItem = elem.children[l];
                  if (l === 0) {
                    this.pages[currentPage] += '<ul>';
                  }
                  if ((listItem.getBoundingClientRect().bottom - elY + (currentPage > 0 ? 100 : 50)) / 750 <= (currentPage + 1)) {
                    this.pages[currentPage] += listItem.outerHTML;
                    if (l === elem.children.length - 1) {
                      this.pages[currentPage] += '</ul>';
                    }
                  } else {
                    this.pages[currentPage] += '</div></div>';
                    //this.pages.push(experienceTitle.outerHTML + '<div class="experience-section"><div class="experience-range">');
                    currentPage++;
                    this.pages[currentPage] += '<ul>';
                    this.pages[currentPage] += listItem.outerHTML;
                    if (l === elem.children.length - 1) {
                      this.pages[currentPage] += '</ul>';
                    }
                  }
                }
              }
            }
          }
        }
      }
    } */
    this.pages[currentPage] += '</div></div>';
    if (reference && this.showReference) {
      this.pages[currentPage] += reference.outerHTML;
    }
    this.generationDone = true;
    this.sendPages.emit(this.pages.length);

    /* console.log('pages:', this.pages); */
  }

  generatePDF() {
    const template = document.getElementById('cv-page-container') as HTMLElement;
    /* this.certificateService.createPDFCVFromHTML(template, this._babysitter, false, false); */
  }

  private initAvailabilieties() {
    this.availabilities = [];
  }

  private initAttitude() {
    this.attitude = '';
    if (this._nurse.rating.attitude === 'Facilitatore') {
      this.attitude = `Questo profilo è tendenzialmente interessato a comportamenti armoniosi verso le persone e l’ambiente che lo circonda. Risulta essere una persona che ha fiducia in sé stessa, con una buona capacità di riflessione. Fa tesoro delle proprie esperienze per migliorare. Ha buona capacità a stringere legami, anche profondi, con le persone con cui viene in contatto. Ha un approccio tendenzialmente pacato e presenta una spiccata abilità nel trovare il proprio equilibrio.`;
    }
    if (this._nurse.rating.attitude === 'Disciplina') {
      this.attitude = `Questo profilo è ottimista e consapevole delle proprie capacità. È una persona comprensiva ed equilibrata. Esprime un forte senso di responsabilità e dovere ed è caratterizzato da una marcata propensione al fare “bene” e alla perseveranza. Predilige ambienti organizzati e strutturati.`;
    }
    if (this._nurse.rating.attitude === 'Accondiscendente') {
      this.attitude = `Questo profilo è caratterizzato principalmente dall’ottimismo. Ha fiducia nel futuro, nel prossimo e nel mondo in generale. E’ una persona dinamica e fantasiosa. E’ mossa prevalentemente da nobili sentimenti, non ama il conflitto né i pensieri negativi. Persona flessibile, aperta ai cambiamenti, affronta gli imprevisti con buon entusiasmo.`;
    }
    if (this._nurse.rating.attitude === 'Esecutore') {
      this.attitude = `Questo profilo ama aiutare gli altri. E’ saggio ma anche prudente e accetta favorevolmente di essere guidato soprattutto quando si trova ad affrontare una situazione per la prima volta. E’ una persona altruista che prende a cuore la salute degli altri. E’ sensibile ed è attento a non sovrastare gli altri.`;
    }
    if (this.attitude === '') {
      this.attitude = `Questo profilo non ha ancora svolto il test psicoattitudinale.`;
    }
  }

  private initSubjects() {
    this.homeworkSubjects = [];
  }

  taskCheck(cluster: number, task: string) {
    switch (cluster) {
      case 1:
        return this._nurse.exp.cluster1.tasks1.split('-').some(task1 => task1 === task);

      case 2:
        return this._nurse.exp.cluster2.tasks2.split('-').some(task2 => task2 === task);

      default:
        return false;
    }
  }

  changePage(forward: boolean) {
    if (forward && this.currentPage < this.pages.length - 1) {
      this.currentPage++;
    }
    if (!forward && this.currentPage > 0) {
      this.currentPage--;
    }
  }

  sendCV() {
    this.certificateService.sendCVByMailToNurse(this._nurse, this.isProduction, this.mailServiceURL, this.showModal);
  }
}
