import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Colf } from 'npx-family-happy-common';
import { RouterStateManagerService } from 'src/app/shared/services/router-state-manager/router-state-manager.service';
import { environment } from 'src/environments/environment';
import { CvColfTemplateComponent } from '../cv-colf-template/cv-colf-template.component';

@Component({
  selector: 'app-cv-colf-template-wrapper',
  templateUrl: './cv-colf-template-wrapper.component.html',
  styleUrls: ['./cv-colf-template-wrapper.component.scss'],
  standalone:true,
  imports:[CommonModule, CvColfTemplateComponent]
})
export class CvColfTemplateWrapperComponent implements OnInit {

  isProduction = environment.production;
  mailServiceURL = environment.mailServiceURL;
  colf!: Colf;

  constructor(private stateManager: RouterStateManagerService) { }

  ngOnInit(): void {
    this.stateManager.getColfState().subscribe((state) => (state.colf) ? this.colf = state.colf : undefined )
  }

}
