export class User {
  uid: string;
  name: string;
  surname: string;
  photoURL: string;
  email: string;
  phone: string;
  role: string;

  constructor(uid: string,
    name: string,
    surname: string,
    photoURL: string,
    email: string,
    phone: string,
    role = 'USER') {
      this.uid = uid;
      this.name = name;
      this.surname = surname;
      this.photoURL = photoURL;
      this.email = email;
      this.phone = phone;
      this.role = role;
  }
}
