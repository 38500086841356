import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullValuesTablePipe',
  standalone:true
})
export class NullValuesTablePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return value !== undefined && value !== '' ? value : '-';
  }

}
