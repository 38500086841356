import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone:true,
  imports:[CommonModule, FormsModule]
})
export class AlertComponent implements OnInit {
  @Output() save = new EventEmitter<boolean>();
  @Input() data: any;
  @Input() title!: string;
  @Input() message!: string;

  constructor() { }

  ngOnInit(): void {
  }

}
