<div id="cv-page-container" [ngClass]="{ 'cv-preview': previewMode }" *ngIf="_petsitter">
  <div id="blue-tr-decorative"></div>
  <div id="pink-tr-decorative"></div>
  <div id="img-container">
    <img [src]="_petsitter.info.photo" alt="">
  </div>
  <div id="fh-banner">
    <img src="/assets/logo_banner.png" alt="">
  </div>
  <div id="registry-info">
    <h2>{{_petsitter.info.name}}</h2>
    <p>{{_petsitter.info.age}} anni</p>
    <!-- <p>{{_petsitter.info.address}}</p>
    <p>{{_petsitter.info.phone}}</p>
    <p>{{_petsitter.info.email}}</p> -->
  </div>
  <div class="d-grid w-100 skills-container">
    <div id="left-column">
      <div id="about-me-section">
        <div class="title-with-icon">
          <div class="title-icon"></div>
          <p>Su di me</p>
        </div>
        <p class="text" style="font-size: 10pt; line-height: 14px;">{{_petsitter.info.description}}</p>
      </div>

      <div id="experience-section" *ngIf="_petsitter.exp.total > 0">
        <div class="title-with-icon mb-3">
          <div class="title-icon">

          </div>
          <p>Esperienza</p>
        </div>
        <div class="experience-cluster" *ngIf="_petsitter.exp.cluster1.cluster1Age > 0">
          <div class="title-with-icon">
            <p>Con cuccioli:</p>
            <p>{{ _petsitter.exp.cluster1.cluster1Age > 1 ? _petsitter.exp.cluster1.cluster1Age + ' anni' : '1 anno' }}</p>
          </div>
          <ul>
            <li *ngIf="taskCheck(1, 'peeing')">insegnare a fare i bisogni all'esterno</li>
            <li *ngIf="taskCheck(1, 'games')">giochi educativi ({{_petsitter.exp.cluster1.games}})</li>
            <li *ngIf="taskCheck(1, 'social')">socializzazione</li>
            <li *ngIf="taskCheck(1, 'muzzle')">utilizzo della museruola</li>
            <li *ngIf="taskCheck(1, 'other')">altri ausili: ({{_petsitter.exp.cluster1.help1}})</li>
          </ul>
        </div>
        <div class="experience-cluster" *ngIf="_petsitter.exp.cluster2.cluster2Age > 0">
          <div class="title-with-icon">
            <p>Con adulti:</p>
            <p>{{ _petsitter.exp.cluster2.cluster2Age > 1 ? _petsitter.exp.cluster2.cluster2Age + ' anni' : '1 anno' }}</p>
          </div>
          <ul>
            <li *ngIf="taskCheck(2, 'interactions')">interazione con altri cani/gatti</li>
            <li *ngIf="taskCheck(2, 'walking')">passeggiate esterne</li>
          </ul>
        </div>
        <div class="experience-cluster" *ngIf="_petsitter.exp.cluster3.cluster3Age > 0">
          <div class="title-with-icon">
            <p>Con anziani:</p>
            <p>{{ _petsitter.exp.cluster3.cluster3Age > 1 ? _petsitter.exp.cluster3.cluster3Age + ' anni' : '1 anno' }}</p>
          </div>
          <ul>
            <li *ngIf="taskCheck(3, 'helpers')">utilizzo ausili di movimento ({{_petsitter.exp.cluster3.help2}})</li>
            <li *ngIf="taskCheck(3, 'diseases')">conoscere le eventuali patologie degli animali e gestirle</li>
          </ul>
        </div>
      </div>
      <div id="skills-section" style="margin-top: -10px" *ngIf="_petsitter.lang.drugs || _petsitter.lang.mentalIllness || _petsitter.lang.disability">
        <div class="title-with-icon">
          <div class="title-icon">

          </div>
          <p>Competenze</p>
        </div>
        <div class="text d-flex gap-1 mb-3">
          <ul class="m-0">
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_petsitter.lang.drugs">somministrazione farmaci</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_petsitter.lang.mentalIllness">esperienza con animali con problemi comportamentali</li>
          </ul>
          <ul class="m-0">
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_petsitter.lang.disability">esperienza con animali con disabilità psichiche</li>
          </ul>
        </div>
      </div>

    </div>
    <div id="right-column">
      <div id="certificates">
        <div class="title-with-icon">
          <div class="title-icon">

          </div>
          <p>Certificazioni</p>
        </div>
        <ul class="text">
          <li style="font-size: 10pt; line-height: 14px;">Prevenzione malattie parassitosi del cane e del gatto</li>
          <li style="font-size: 10pt; line-height: 14px;">Brevi nozioni di pronto soccorso</li>
          <li style="font-size: 10pt; line-height: 14px;">Il gatto non è un piccolo cane</li>
          <li style="font-size: 10pt; line-height: 14px;">Un po' di educazione civica, regole per andare d'accordo con tutti, proprietari di altri cani e no</li>
          <li style="font-size: 10pt; line-height: 14px;">Ogni età ha le sue esigenze, problematiche più frequenti nel cane e nel gatto</li>
          <li style="font-size: 10pt; line-height: 14px;" *ngFor="let cert of certificates">{{cert}}</li>
        </ul>
      </div>
      <div id="availabilities">
        <div class="title-with-icon">
          <div class="title-icon">

          </div>
          <p>Disponibilità</p>
        </div>
        <div class="text d-flex gap-3 mb-3">
          <ul class="m-0">
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_petsitter.avail.hosting">Pet sitting a casa del sitter</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_petsitter.avail.homeService">Pet sitting a domicilio</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_petsitter.avail.grooming">Toelettatura</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_petsitter.avail.pension">Pensione</li>
          </ul>
          <ul class="m-0">
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_petsitter.avail.walking">Passeggiata cani</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_petsitter.avail.nightShift">Soggiorno notturno</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_petsitter.avail.veterinarian">Accompagnamento a visite veterinarie</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_petsitter.avail.cleaning">Pulizia lettiera</li>
          </ul>
        </div>
        <div class="d-grid gap-5" style="grid-template-columns: 1fr 1fr;">
          <div>
            <div class="title-with-icon small-title px-3" style="border-top-right-radius: 0px;">
              <p>Posso curare</p>
            </div>
            <p class="fw-bold subtitle mt-2">Cani:</p>
            <ul style="list-style-type: circle;">
              <li class="category-check" style="font-size: 10pt;" *ngIf="checkCategory('dogHealing', 'dog')"><i class="bi bi-check-circle" *ngIf="checkCategory('dogHealing', 'dog')"></i>0 - 7 kg</li>
              <li class="category-check" style="font-size: 10pt;" *ngIf="checkCategory('dogHealing', 'dog1')"><i class="bi bi-check-circle" *ngIf="checkCategory('dogHealing', 'dog1')"></i>7 - 15 kg</li>
              <li class="category-check" style="font-size: 10pt;" *ngIf="checkCategory('dogHealing', 'dog2')"><i class="bi bi-check-circle" *ngIf="checkCategory('dogHealing', 'dog2')"></i>15 - 30 kg</li>
              <li class="category-check" style="font-size: 10pt;" *ngIf="checkCategory('dogHealing', 'dog3')"><i class="bi bi-check-circle" *ngIf="checkCategory('dogHealing', 'dog3')"></i>30 - 45 kg</li>
              <li class="category-check" style="font-size: 10pt;" *ngIf="checkCategory('dogHealing', 'dog4')"><i class="bi bi-check-circle" *ngIf="checkCategory('dogHealing', 'dog4')"></i>45+ kg</li>
            </ul>
            <p class="fw-bold subtitle" *ngIf="checkCategory('catHealing', 'cat')">Gatti:</p>
            <ul style="list-style-type: circle;" *ngIf="checkCategory('catHealing', 'cat')">
              <li class="category-check" style="font-size: 10pt;" *ngIf="checkCategory('catHealing', 'cat')"><i class="bi bi-check-circle" *ngIf="checkCategory('catHealing', 'cat')"></i>(Non vi sono limiti)</li>
            </ul>
          </div>
          <div>
            <div class="title-with-icon small-title" style="border-top-left-radius: 0px;">
              <p>Posso ospitare</p>
            </div>
            <p class="fw-bold subtitle mt-2">Cani:</p>
            <ul style="list-style-type: circle;">
              <li class="category-check" style="font-size: 10pt;" *ngIf="checkCategory('dogHosting', 'dog')"><i class="bi bi-check-circle" *ngIf="checkCategory('dogHosting', 'dog')"></i>0 - 7 kg</li>
              <li class="category-check" style="font-size: 10pt;" *ngIf="checkCategory('dogHosting', 'dog1')"><i class="bi bi-check-circle" *ngIf="checkCategory('dogHosting', 'dog1')"></i>7 - 15 kg</li>
              <li class="category-check" style="font-size: 10pt;" *ngIf="checkCategory('dogHosting', 'dog2')"><i class="bi bi-check-circle" *ngIf="checkCategory('dogHosting', 'dog2')"></i>15 - 30 kg</li>
              <li class="category-check" style="font-size: 10pt;" *ngIf="checkCategory('dogHosting', 'dog3')"><i class="bi bi-check-circle" *ngIf="checkCategory('dogHosting', 'dog3')"></i>30 - 45 kg</li>
              <li class="category-check" style="font-size: 10pt;" *ngIf="checkCategory('dogHosting', 'dog4')"><i class="bi bi-check-circle" *ngIf="checkCategory('dogHosting', 'dog4')"></i>45+ kg</li>
            </ul>
            <p class="fw-bold subtitle" *ngIf="checkCategory('catHosting', 'cat')">Gatti:</p>
            <ul style="list-style-type: circle;" *ngIf="checkCategory('catHosting', 'cat')">
              <li class="category-check" style="font-size: 10pt;"><i class="bi bi-check-circle" *ngIf="checkCategory('catHosting', 'cat')"></i>(Non vi sono limiti)</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="generationDone" [innerHTML]="sanitizer.bypassSecurityTrustHtml(pages[currentPage])"></div> -->
    </div>
  </div>
  <div id="footer"></div>
</div>

<div *ngIf="previewMode" class="page-buttons">
  <img src="../../../../assets/icon/previous-icon.svg" (click)="changePage(false)"
    [ngStyle]="{ 'opacity': currentPage > 0 ? '100%' : '50%', 'cursor': currentPage > 0 ? 'pointer' : 'default' }" />
  <button (click)="sendCV()">Invia</button>
  <img src="../../../../assets/icon/next-icon.svg" (click)="changePage(true)"
    [ngStyle]="{ 'opacity': currentPage < pages.length - 1 ? '100%' : '50%', 'cursor': currentPage < pages.length - 1 ? 'pointer' : 'default' }" />
</div>
