import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Page } from "npx-family-happy-common";
import { BehaviorSubject, Subject, map, tap } from "rxjs";
import { CaregiverEngagementFilters } from "src/app/shared/components/caregiver-engagement/caregiver-engagement-models/caregiver-engagement-filters.interface";
import { CaregiverSubscriptionInterface } from "src/app/sellers/caregiver-subscription/caregiver-subscription.interface";
import { CaregiverSelectionEngagementRecord } from "src/app/shared/models/caregiver-selection-engagement-record.model";
import { CaregiverStats } from "src/app/shared/models/caregiver-stats.model";
import { CaregiverSelectionRecord } from "src/app/shared/models/caregiverSelectionRecord.model";
import { environment } from "src/environments/environment";
import { param } from "cypress/types/jquery";

export const DEFAULT_ENGAGED_FILTERS:CaregiverEngagementFilters = {
  phase:''
};

@Injectable({
  providedIn: 'root'
})

export class CaregiverSelectionService implements OnDestroy {

  // filters = new Subject<any>()
  filters = new BehaviorSubject<any>(null)
  filterOldReq = new BehaviorSubject<boolean>(false)
  private baseURL = environment.caregiverSelectionAPI;
  private caregiverRecordsObservable = new Subject<CaregiverSelectionRecord[]>();

  constructor(private http: HttpClient) { }

  ngOnDestroy(): void {
    this.resetFilters()
  }

  getCaregiverByRequestID(id: number, latitude?: number, longitude?: number, removeOld = false) {
    const params = [
      `id=${id}`
    ]
    if (latitude && longitude) {
      params.push(`latitude=${latitude}`, `longitude=${longitude}`)
    }
    if (removeOld) {
      params.push(`removeOld=${removeOld}`);
    }
    return this.http.get<CaregiverSelectionRecord[]>(`${this.baseURL}/?${params.join('&')}`).pipe(tap((records) => this.caregiverRecordsObservable.next(records)));
  }

  addCaregiverToFavorite(id:number, isFavorite:boolean){
    return this.http.patch<CaregiverSelectionRecord>(`${this.baseURL}/${id}/favorite?isFavorite=${isFavorite}`, '')
  }

  getAllCaregiverNotSubscribed(businessLine:string, pageSize:number, page:number){
    let params = ''
    if(businessLine !== ''){
      params = [
        `businessLine=${businessLine}`,
        `pageNumber=${page}`,
        `pageSize=${pageSize}`
      ].join('&');
    } else {
      params = [
        `pageNumber=${page}`,
        `pageSize=${pageSize}`
      ].join('&');
    }
    return this.http.get<Page<CaregiverSubscriptionInterface>>(`${this.baseURL}/caregivers-not-subscribed?${params}`);
  }

  getCaregiverStats(docRef: string) {
    return this.http.get<CaregiverStats>(`${this.baseURL}/${docRef}/stats`)
      .pipe(map((stats) => ({
        ...stats,
        latestApplication: stats.latestApplication ? new Date(stats.latestApplication) : null,
        latestChosen: stats.latestChosen ? new Date(stats.latestChosen) : null
      })));
  }

  updateCaregiverSelectionCategory(record: CaregiverSelectionRecord, type: string, discardedReason:string) {
    let newRecord = {}
    if(type === "DISCARDED"){
      newRecord = {
        id: record.id,
        date: new Date(),
        status: true,
        type: type,
        discardedReason: discardedReason
      }
    } else {
      newRecord = {
        id: record.id,
        date: new Date(),
        status: true,
        type: type,
      }
    }
    return this.http.patch<any>(`${this.baseURL}/`, newRecord);
  }

  addBabysitterSelectionSuitable(record: CaregiverSelectionRecord) {
    let newRecord = {
      requestID: record.requestID,
      babysitter: record.babysitter,
      petsitter: null,
      nurse: null
    }
    return this.http.post<any>(`${this.baseURL}/`, newRecord);
  }

  addPetsitterSelectionSuitable(record: CaregiverSelectionRecord) {
    let newRecord = {
      requestID: record.requestID,
      petsitter: record.petsitter,
      babysitter: null,
      nurse: null
    }
    return this.http.post<any>(`${this.baseURL}/`, newRecord);
  }

  addNurseSelectionSuitable(record: CaregiverSelectionRecord) {
    let newRecord = {
      requestID: record.requestID,
      babysitter: null,
      petsitter: null,
      nurse: record.nurse
    }
    return this.http.post<any>(`${this.baseURL}/`, newRecord);
  }

  addColfSelectionSuitable(record: CaregiverSelectionRecord) {
    let newRecord = {
      requestID: record.requestID,
      babysitter: null,
      petsitter: null,
      nurse: null,
      colf: record.colf
    }
    return this.http.post<any>(`${this.baseURL}/`, newRecord);
  }

  setFilters(value: any) {
    this.filters.next(value)
  }

   setOldReq(value: boolean) {
    this.filterOldReq.next(value)
  }

  getFilters() {
    return this.filters.asObservable()
  }

    getOldReq() {
    return this.filterOldReq.asObservable()
  }

  resetFilters() {
    this.filters.next(null)
  }

  getStoredCaregiverRecords() {
    return this.caregiverRecordsObservable.asObservable();
  }

  getEngagedRequestsByDocRef(page: number, pageSize: number, docRef:String, filters = DEFAULT_ENGAGED_FILTERS) {
    const params = [
      `pageNumber=${page}`,
      `pageSize=${pageSize}`,
      `docRef=${docRef}`,
      `${this.convertFilterObjectToQueryParam(filters)}`,
    ].join('&');

    return this.http.get<Page<CaregiverSelectionEngagementRecord>>(`${this.baseURL}/requests?${params}`);
  }

  convertFilterObjectToQueryParam(filters: any) {
    let queryString = '';
    Object.entries(filters).forEach((entry: [string, any | null]) => {
      if (entry[1] !== null && entry[1] !== '') {
        queryString += `${queryString === '' ? '' : '&'}${entry[0]}=${encodeURIComponent(entry[1])}`;
      }
    });
    return queryString;
  }
}
