<div class="p-3" *ngIf="score">
  <h2>Punteggio Caregiver</h2>
  <table class="table table-striped align-middle">
    <thead class="fw-bold">
      <th scope="col">Tipo punteggio</th>
      <td scope="col">Valore punteggio</td>
      <td scope="col">Operazioni</td>
    </thead>
    <tbody>
      <tr>
        <td>Offset (Punteggio iniziale e correzioni)</td>
        <td class="second-table-column"><input class="text-end" id="scoreOffset" name="scoreOffset" type="number" [(ngModel)]="score.scoreOffset" (change)="updateTotal()"></td>
        <td class="text-center pointer" (click)="saveOffset()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-floppy-fill text-primary" viewBox="0 0 16 16">
            <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5v-13Z"/>
            <path d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5V16Zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V0ZM9 1h2v4H9V1Z"/>
          </svg>
        </td>
      </tr>
      <tr>
        <td>Fedeltà F+H (3Pt per ogni rinnovo)</td>
        <td class="fw-bold text-end second-table-column" data-testid="subRenewal">{{score.subRenewal}}</td>
        <td></td>
      </tr>
      <tr>
        <td>Professionale al Colloquio (2Pt ogni volta che la caregiver è stata scelta)</td>
        <td class="fw-bold text-end second-table-column" data-testid="chosen">{{score.chosen}}</td>
        <td></td>
      </tr>
      <tr>
        <td>Buona Condotta (3Pt ogni volta che il genitore che l'ha scelta rinnova)</td>
        <td class="fw-bold text-end second-table-column" data-testid="parentPayment">{{score.parentPayment}}</td>
        <td></td>
      </tr>
      <tr>
        <td>Certificazione Disostruzione (2Pt)</td>
        <td class="fw-bold text-end second-table-column" data-testid="disostuzioneCertified">{{score.disostuzioneCertified}}</td>
        <td></td>
      </tr>
      <tr>
        <td>Porta un'amica o un genitore (5Pt a Babysitter/Genitore pagante)</td>
        <td class="fw-bold text-end second-table-column" data-testid="referral">{{score.referral}}</td>
        <td class="text-center pointer" (click)="saveScore(5, 5)"><i class="bi bi-patch-plus-fill text-primary"></i></td>
      </tr>
      <tr>
        <td>Referenze con n. di Tel da poter chiamare (2PT a referenza)</td>
        <td class="fw-bold text-end second-table-column" data-testid="referenceScore">{{score.referenceScore}}</td>
        <td class="text-center pointer" (click)="saveScore(6, 2)"><i class="bi bi-patch-plus-fill text-primary"></i></td>
      </tr>
      <tr>
        <td>Condivisione Post sui Social (2 Pt a Post)</td>
        <td class="fw-bold text-end second-table-column" data-testid="postSharing">{{score.postSharing}}</td>
        <td class="text-center pointer" (click)="saveScore(7, 2)"><i class="bi bi-patch-plus-fill text-primary"></i></td>
      </tr>
      <tr>
        <td>Recensioni Google (3Pt una recensione)</td>
        <td class="fw-bold text-end second-table-column" data-testid="googleReviews">{{score.googleReviews}}</td>
        <td class="text-center pointer" (click)="saveScore(8, 3)"><i class="bi bi-patch-plus-fill text-primary"></i></td>
      </tr>
      <tr>
        <td class="fw-bold">Totale accumulato:</td>
        <td class="fw-bold text-end second-table-column" data-testid="overall">{{score.overall}}</td>
        <td></td>
      </tr>
    </tbody>
  </table>
  <p>Data ultimo aggiornamento: <span class="fw-semibold" data-testid="latestUpdate">{{score.latestUpdate | date: 'yyyy-MM-dd HH:mm:ss':'UTC+2'}}</span></p>
</div>
<div class="p-3" *ngIf="scoreHistory && scoreHistory.length > 0">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title class="fw-bold">
        Storico Punteggi
      </mat-panel-title>
    </mat-expansion-panel-header>
    <tr class="d-flex gap-5" *ngFor="let record of scoreHistory">
      <th class="fw-normal">{{ record.timestamp | date: 'yyyy-MM-dd HH:mm:ss':'UTC+2'}}</th>
      <th class="fw-bold">{{ getLabel(record.type) }}</th>
    </tr>
  </mat-expansion-panel>
</div>
