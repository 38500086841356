import { ProvinceService } from './../../services/province-service/province.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/authentication/auth/auth.service';
import { UserStorageService } from 'src/app/authentication/user-storage/user-storage.service';
import { User } from '../../models/user.model';
import { UserManagementService } from 'src/app/backoffice/user-management/user-management-service/user-management.service';
import { CommonModule } from '@angular/common';
import { ProvinceFilterComponent } from 'npx-family-happy-common';
import { AlertComponent } from '../modals/alert/alert.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
  standalone:true,
  imports:[CommonModule, ProvinceFilterComponent, AlertComponent, NgbDropdownModule]
})
export class HeaderBarComponent implements OnInit {
  @Output() showMenu = new EventEmitter();
  user!: User;
  showAlert: boolean = false;
  editForm: UntypedFormGroup;
  provincia: string | null = null;

  constructor(
    private userStorage: UserStorageService,
    private router: Router,
    private auth: AuthService,
    private management: UserManagementService,
    private provinceService: ProvinceService
  ) {
    this.editForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      surname: new UntypedFormControl('', [Validators.required]),
      type: new UntypedFormControl('nameSurname')
    });
  }

  ngOnInit(): void {
    this.provincia = this.provinceService.getProvince().getValue();

    this.userStorage.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
        this.editForm.patchValue({ name: this.user?.name, surname: this.user?.surname })
      }
    });
  }

  signOut() {
    this.auth.signOut().then(() => {
      this.router.navigate(['/login']);
      console.log('Signed out');
    })
  }

  editNameSurname() {
    this.showAlert = true;
  }

  closeEditModal() {
    this.showAlert = false;
  }

  updateProvincia(newValue: string) {
    this.provinceService.setProvince(newValue);
  }

  onAlertAction(save: boolean) {
    if (save) {
      const name = this.editForm.value.name
      const surname = this.editForm.value.surname
      this.userStorage.setUser(this.user as User)
      this.management.changeUserName(this.user.uid, name, surname).subscribe((user) => {
        this.closeEditModal()
      });
    }
    else this.closeEditModal()
  }
}
