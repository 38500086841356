import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/authentication/message-service/message-service.service';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from '../auth/auth.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone:true,
  imports:[CommonModule,ReactiveFormsModule]
})
export class LoginComponent implements OnInit {
  signInForm: UntypedFormGroup;
  signUpForm: UntypedFormGroup;
  section = 'login';
  errorMessage = '';
  passwordVisible = false;

  constructor(private auth: AuthService, private messageService: MessageService) {
    this.messageService.setMessage('');
    this.messageService.getMessage().subscribe((message) => {
      this.errorMessage = message;
    });
    this.signInForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
    });
    this.signUpForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      surname: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      phone: new UntypedFormControl('', [Validators.required, Validators.pattern(new RegExp('^((00|\\+)\\d{2})?\\s?\\d{3}\\s?\\d{7}$'))]),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      privacy: new UntypedFormControl(false, [Validators.required, Validators.requiredTrue])
    });
  }

  ngOnInit(): void {

  }

  /* Get FormControl from the Form in order to check its validity state */
  getFormControl(formControlName: string, pattern: string) {
    const tmp = this.signUpForm.get(formControlName);
    if (tmp) {
      if (tmp.errors) {
        return tmp.invalid && tmp.touched && tmp.errors[pattern];
      }
    }
    return undefined;
  }

  /* Show the password filled in the input element */
  showPassword() {
    this.passwordVisible = !this.passwordVisible;
    const passwordField = document.querySelector('#signinPassword');
    if (passwordField) {
      if (this.passwordVisible) {
        passwordField.setAttribute('type', 'text');
      } else {
        passwordField.setAttribute('type', 'password');
      }
    }
  }

  //Get the email of the user and try to send a reset password email
  forgotPassword() {
    const email = this.signInForm.get('email');
    if (email) {
      if (email.status === 'VALID') {
        this.auth.forgotPassword(email.value);
      } else {
        this.messageService.setMessage('Per favore, fornisci un indirizzo email valido.');
      }
    }
  }

  //Sign in with email and password provided by the user
  signIn() {
    const email = this.signInForm.value.email;
    const password = this.signInForm.value.password;

    this.auth.login(email, password).then(() => {
      this.onClose();
    });
  }

  /* After validating the data, sign up the user with the data provided */
  signUp() {
    if (this.signUpForm.status === 'VALID') {
      const form = this.signUpForm.value;
      if (form.privacy) {
        const email = form.email;
        const password = form.password;
        const user = new User('', form.name, form.surname, '', email, form.phone);
        this.auth.signUp(email, password, user).then(() => {
          this.onClose();
        });;
      }
    } else {
      const form = this.signUpForm.get('privacy');
      if (form) {
        if (form.invalid) {
          // eslint-disable-next-line max-len
          this.messageService.setMessage('Prima di proseguire si prega di prendere visione e accettare Termini e Condizioni e le normative per la Privacy.');
        }
      }
    }
  }

  /* Authenticate user with Google */
  loginWithGoogle() {
    this.auth.loginWithGoogle().then(() => {
      this.onClose();
    });;
  }

  onClose() {

  }

}
