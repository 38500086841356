<div class="container p-3">
  <div>
    <h2 class="text-center text-primary">Bilanciamento Carico FamilyTutor</h2>
  </div>
  <table class="table table-hover table-striped mt-5">
    <thead>
      <tr>
        <!-- <th scope="col">ID</th> -->
        <th scope="col">Nome Operatore</th>
        <th scope="col">In Vacanza</th>
        <!-- <th scope="col">Carico</th> -->
        <th scope="col">Peso</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let record of loadBalanceRecords; let i = index" [formGroup]="getOperatorFormGroup(i)">
        <!-- <th scope="row">{{record.id}}</th> -->
        <td>{{record.operator}}</td>
        <td><select class="form-control" formControlName="onVacation" (change)="updateOperatorVacation(i)">
          <option value="true">Si</option>
          <option value="false">No</option>
        </select></td>
        <!-- <td>{{record.load}}</td> -->
        <td><input class="form-control" type="number" min="0" max="1" step="0.1" formControlName="weight" (change)="updateOperatorWeight(i)"></td>
      </tr>
      <tr *ngIf="loadBalanceRecords.length === 0">
        <td colspan="10" class="text-center">Non sono stati trovati operatori</td>
      </tr>
    </tbody>
  </table>
</div>

