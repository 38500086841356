import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fhDate',
  standalone:true
})
export class FhDatePipe implements PipeTransform {

  transform(value: Date, ...args: unknown[]): unknown {
    return new Date(value).toLocaleDateString();
  }

}
