<div class="row p-3">
  <form class="col-8" action="" [formGroup]="form">
    <div class="mb-3" *ngIf="type === 'BABYSITTER'">
      <label for="role" class="form-label">Ruolo</label>
      <input type="text" class="form-control" id="role" placeholder="es. Babysitter" formControlName="role">
    </div>
    <div class="mb-3" *ngIf="type === 'BABYSITTER'">
      <label for="childInfo" class="form-label">Informazioni Bambin*</label>
      <input type="text" class="form-control" id="childInfo" placeholder="es. 1 bambino di 5 anni"
        formControlName="childInfo">
    </div>
    <div class="mb-3">
      <label for="city" class="form-label">Città</label>
      <input type="text" class="form-control" id="city" placeholder="es. Torino - San Salvario" formControlName="city">
    </div>
    <div class="mb-3">
      <label for="description" class="form-label">Descrizione</label><i class="bi bi-info-circle-fill ms-2 text-primary" placement="top" ngbTooltip="Questo campo non accetta a capo. Tutti gli a capo, verranno rimossi e sostituiti con uno spazio."></i>
      <textarea class="form-control" id="description" placeholder="es. accompagnare il bambino in piscina il pomeriggio e aiutarlo con i compiti"
        formControlName="description" rows="6"></textarea>
    </div>
    <div class="mb-3">
      <label for="starting" class="form-label">Tempistiche</label>
      <input type="text" class="form-control" id="starting" placeholder="es. Inizio stimato il Prima possibile" formControlName="starting">
    </div>
    <div class="mb-3">
      <label for="wage" class="form-label">Remunerazione netta</label>
      <input type="text" class="form-control" id="wage" placeholder="es. 10.00€/ora" formControlName="wage">
    </div>
    <button class="btn mb-3 text-light px-4 py-2" style="background-color: #f44336;"
      (click)="submit()">Invia</button>
  </form>
  <app-smartphone-container class="col-4" [data]="form.value" [type]="type"></app-smartphone-container>
</div>
