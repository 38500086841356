import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caregiverCardDate',
  standalone:true
})
export class CaregiverCardDatePipe implements PipeTransform {

  transform(value: Date | null, ...args: unknown[]): unknown {
    if(!value){
      return "-/-/----"
    }
    return new Date(value).toLocaleDateString();
  }

}
