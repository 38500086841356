import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProvinceService {
  private province = new BehaviorSubject<string|null>('');

  constructor() { }

  setProvince(province: string) {
    this.province.next(province);
  }

  getProvince() {
    return this.province;
  }
}
