import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page, Product } from 'npx-family-happy-common';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

export const DEFAULT_PRODUCT_FILTERS: ProductsFilters = {
  serviceID: null,
  searchKey: null,
};

@Injectable({
  providedIn: 'root',
})
export class ProductServiceDashboard {

  private baseURL = environment.productsAPI;
  filters = new BehaviorSubject<ProductsFilters>(DEFAULT_PRODUCT_FILTERS)

  constructor(private http: HttpClient) { }
  getAllProducts(
    page: number,
    pageSize = 20,
    filters = DEFAULT_PRODUCT_FILTERS
  ) {
    const params = [
      `pageNumber=${page}`,
      `pageSize=${pageSize}`,
      `${this.convertFilterObjectToQueryParam(filters)}`,
    ].join('&');
    return this.http.get<Page<Product>>(`${this.baseURL}/list?${params}`);
  }
  getProductByID(id: number) {
    return this.http.get<Product>(`${this.baseURL}/${id}`);
  }

  saveProduct(product: Product) {
    return this.http.post<Product>(`${this.baseURL}/`, product);
  }

  deleteProductById(id: number) {
    return this.http.delete<Product>(`${this.baseURL}/${id}`);
  }

  setFilters(filters:any){
    this.filters.next(filters)
  }

  getFilters(){
    return this.filters.asObservable()
  }

  private convertFilterObjectToQueryParam(filters: ProductsFilters) {
    let queryString = '';
    Object.entries(filters).forEach((entry: [string, any | null]) => {
      if (
        entry[1] !== null &&
        ((typeof entry[1] === 'string' && entry[1] !== '') ||
          (typeof entry[1] === 'number' && entry[1] >= 0))
      ) {
        queryString += `${queryString === '' ? '' : '&'}${entry[0]}=${encodeURIComponent(entry[1])}`;
      }
    });
    return queryString;
  }
}

export interface ProductsFilters {
  serviceID: number | null;
  searchKey: string | null;
}
