import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';

/// <reference types="@angular/localize" />


if (environment.production) {
  enableProdMode();
}

  bootstrapApplication(AppComponent, appConfig).catch(e => console.error(e));
