import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-status-badge',
  templateUrl: './payment-status-badge.component.html',
  styleUrls: ['./payment-status-badge.component.scss'],
  standalone:true,
  imports:[CommonModule]
})
export class PaymentStatusBadgeComponent implements OnInit {
  @Input() status!: string;

  constructor() { }

  ngOnInit(): void {
  }

}
