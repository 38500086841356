import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateString',
  standalone:true,
})
export class TruncateStringPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value.length > 150) {
      return value.slice(0, 150) + '...';
    } else {
      return value;
    }
  }

}
