import { productRow } from './../../../shared/models/products.models';
import { ModalService, Product, ProvinceFilterComponent } from 'npx-family-happy-common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Price } from 'npx-family-happy-common';
import { Subject, takeUntil } from 'rxjs';
import { PriceCreationBody, PriceService } from '../prices-service/price.service';
import { CommonModule } from '@angular/common';
import { ProductSelectComponent } from 'src/app/shared/components/custom-select/product-select/product-select.component';
import { ProvinceSelectComponent } from 'src/app/shared/components/province-select/province-select.component';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
import { NgbHighlight } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-price-detail',
  templateUrl: './price-detail.component.html',
  styleUrls: ['./price-detail.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, ProductSelectComponent, ProvinceFilterComponent, ProvinceSelectComponent, AlertComponent, NgbHighlight]
})
export class PriceDetailComponent implements OnInit, OnDestroy {
  form = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    amount: new UntypedFormControl(null, [Validators.required]),
    productName: new UntypedFormControl(null, [Validators.required]),
    productID: new UntypedFormControl(null, [Validators.required]),
    productServiceName: new UntypedFormControl(null, [Validators.required]),
    productServiceId: new UntypedFormControl(null, [Validators.required]),
    productServiceBusinessLine: new UntypedFormControl(null, [Validators.required]),
    productServiceCustomerType: new UntypedFormControl(null, [Validators.required]),
    productServiceScript: new UntypedFormControl(' '),
    productStripeID: new UntypedFormControl(''),
    productType: new UntypedFormControl(null, [Validators.required]),
    province: new UntypedFormControl(null, [Validators.required]),
    stripeID: new UntypedFormControl(''),
    product: new UntypedFormControl(null),
    productServiceDisabled: new UntypedFormControl(null)
  })

  selectedPrice: Price | undefined
  isNew = true
  showDeleteConfirm = false
  formInvalid = false

  private unsubscribe = new Subject<void>();

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private priceService: PriceService, private modalService: ModalService) { }


  goBackToPrices() {
    this.router.navigate(['/prices/']);
  }

  savePrice() {
    if (this.form.valid) {
      const formValue = this.form.value;
      if (this.isNew) {
        const price: PriceCreationBody = {
          stripeID: formValue.stripeID,
          amount: Math.round(formValue.amount * 100),
          provinces: formValue.province,
          product: {
            id: formValue.productID,
            name: formValue.productName,
            service: {
              id: formValue.productServiceId,
              businessLine: formValue.productServiceBusinessLine,
              customerType: formValue.productServiceCustomerType,
              name: formValue.productServiceName,
              script: '',
              disabled: formValue.productServiceDisabled
            },
            type: formValue.productType,
            stripeID: formValue.productStripeID
          }
        }
        this.priceService.createPrice(price).subscribe(() => this.modalService.showSuccessMessage('Prezzo aggiornato'));
      } else {
        const price: Price = {
          id: formValue.id,
          stripeID: formValue.stripeID,
          amount: Math.round(formValue.amount * 100),
          province: formValue.province,
          product: {
            id: formValue.productID,
            name: formValue.productName,
            service: {
              id: formValue.productServiceId,
              businessLine: formValue.productServiceBusinessLine,
              customerType: formValue.productServiceCustomerType,
              name: formValue.productServiceName,
              script: '',
              disabled: formValue.productServiceDisabled
            },
            type: formValue.productType,
            stripeID: formValue.productStripeID
          }
        }
        this.priceService.updatePrice(price).subscribe(() => this.modalService.showSuccessMessage('Prezzo aggiornato'));
      }
    } else {
      this.formInvalid = true
    }
  }

  deletePrice() {
    this.showDeleteConfirm = true;
  }

  confirmDelete(answer: boolean) {
    if (answer && this.selectedPrice && this.selectedPrice.id > -1) {
      this.priceService.deletePriceById(this.selectedPrice.id).subscribe(() => {
        this.modalService.showSuccessMessage('Prodotto eliminato');
        this.goBackToPrices();
      });
    }
    this.showDeleteConfirm = false;
  }

  validatorErrorMessage(fieldName: string) {
    const field = this.form.get(fieldName);
    let message = ''
    if (field?.errors) {
      if (field?.errors['required']) {
        message = `Questo campo è obbligatorio`
      }
    }
    return message
  }

  updateProducts(product: Product | undefined) {
    if (product === undefined) {
      this.form.controls.productServiceBusinessLine.setValue('')
      this.form.controls.productServiceCustomerType.setValue('')
      this.form.controls.productServiceName.setValue('')
      this.form.controls.productServiceId.setValue('')
      this.form.controls.productServiceScript.setValue('')
      this.form.controls.productID.setValue('')
      this.form.controls.productName.setValue('')
      this.form.controls.productID.setValue('')
      this.form.controls.productType.setValue('')
      this.form.controls.productServiceDisabled.setValue(null)
    } else {
      this.form.controls.productServiceBusinessLine.setValue(product?.service.businessLine)
      this.form.controls.productServiceCustomerType.setValue(product?.service.customerType)
      this.form.controls.productServiceName.setValue(product?.service.name)
      this.form.controls.productServiceId.setValue(product?.service.id)
      this.form.controls.productServiceScript.setValue(product?.service.script)
      this.form.controls.productID.setValue(product?.id)
      this.form.controls.productName.setValue(product?.name)
      this.form.controls.productID.setValue(product?.id)
      this.form.controls.productType.setValue(product.type),
      this.form.controls.productServiceDisabled.setValue(product?.service.disabled)
    }
  }

  updateProvincia(province: string | undefined) {
    if (province === undefined) {
    } else {
      this.form.controls.province.setValue(province)
    }
  }

  initForm(price: Price) {
    this.form.setValue({
      ...price,
      amount: price.amount / 100,
      id: price.id,
      productID: price.product.id,
      productName: price.product.name,
      productServiceName: price.product.service.name,
      productServiceId: price.product.service.id,
      productServiceBusinessLine: price.product.service.businessLine,
      productServiceCustomerType: price.product.service.customerType,
      productServiceDisabled: price.product.service.disabled,
      productServiceScript: price.product.service.script,
      productStripeID: price.product.stripeID,
      productType: price.product.type,
      province: price.province,
      stripeID: price.stripeID
    })
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe((param) => {
      const id = param.get('id') ?? -1;
      if (id === 'create') {
        this.isNew = true;
      } else {
        if (id === -1) {
          this.goBackToPrices();
        } else {
          this.priceService.getPriceByID(+id).subscribe((price: Price) => {
            this.selectedPrice = price;
            this.isNew = false;
            this.initForm(price);
          })
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  handleValuesChange(selectedValues: string[]) {
    this.form.controls.province.setValue(selectedValues)
  }
}

