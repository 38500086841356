<div class="p-3">
  <div class="elements-limit-container">
    <label for="elements-number">N. elementi per pagina</label>
    <select id="elements-number" [formControl]="updatesPerPage">
      <option value="10">10</option>
      <option value="25">25</option>
      <option value="50">50</option>
    </select>
  </div>

  <table>
    <thead>
      <th>Operazione</th>
      <th>Data</th>
    </thead>
    <tbody>
      <tr *ngFor="let update of requestUpdates">
        <td class="pe-3">{{ update.description }}</td>
        <td>{{ update.timestamp | date: 'dd/MM/yyyy HH:mm' }}</td>
      </tr>
    </tbody>
  </table>

  <button class="link-button mt-3" *ngIf="showPageButton" (click)="moreUpdates()">Carica altri risultati</button>
</div>
