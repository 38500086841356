import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalService, RequestCategory } from 'npx-family-happy-common';

export interface WhatsappBody {
  recipients: string[],
  role: string;
  childInfo: string;
  city: string;
  description: string;
  starting: string;
  wage: string;
  requestID: string;
  type: string;
}

@Component({
  selector: 'app-smartphone-container',
  templateUrl: './smartphone-container.component.html',
  styleUrls: ['./smartphone-container.component.scss'],
  standalone:true,
  imports:[CommonModule]
})
export class SmartphoneContainerComponent implements OnInit {
  @Input() data: WhatsappBody = {
    recipients: [],
    role: 'babysitter',
    childInfo: '1 bambino di 5 anni',
    city: 'Milano - Duomo',
    description: 'badare al bambino tra le 14:00 e le 18:00 dal lunedì al venerdì e aiutarlo nel fare i compiti',
    starting: 'dal 10 settembre 2023 - Lavoro continuativo',
    wage: '11,00€/ora',
    requestID: '4020',
    type: 'BABYSITTER_AD'
  }
  @Input() type: string = 'BABYSITTER';

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  copyTextToClipboard(requestCategory: string) {
    let message: string = '';
    switch (requestCategory) {
      case 'NURSE':
        message = `Richiesta n. ${this.data.requestID}\n
        Siamo alla ricerca di un* Badante nella città di ${this.data.city} per ${this.data.description}\n\n
        Inizio stimato: ${this.data.starting}
          Remunerazione netta: ${this.data.wage}\n\n
          Family+Happy`;
        break;
      case 'PETSITTER':
        message = `Richiesta n. ${this.data.requestID}\n
        Siamo alla ricerca di un* Petsitter nella città di ${this.data.city} per ${this.data.description}\n\n
        Inizio stimato: ${this.data.starting}
          Remunerazione netta: ${this.data.wage}\n\n
          Family+Happy`;
        break;
        case 'COLF':
          message = `Richiesta n. ${this.data.requestID}\n
          Siamo alla ricerca di un* Colf nella città di ${this.data.city} per ${this.data.description}\n\n
          Inizio stimato: ${this.data.starting}
            Remunerazione netta: ${this.data.wage}\n\n
            Family+Happy`;
          break;
      case 'BABYSITTER':
      default:
        message = `Richiesta n. ${this.data.requestID}\n
          Siamo alla ricerca di ${this.data.role} per ${this.data.childInfo} nella città di ${this.data.city} per ${this.data.description}\n\n
          Tempistiche: ${this.data.starting}
          Remunerazione netta: ${this.data.wage}\n\n
          Family+Happy`;
    }
    navigator.clipboard.writeText(message);
    this.modalService.showSuccessMessage(`Messaggio copiato!`);
  }

}
