import { CommonModule } from '@angular/common';
import { ServiceServices } from './../service-services/service.services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ModalService } from 'npx-family-happy-common';
import { Subject, takeUntil } from 'rxjs';
import { serviceRow } from 'src/app/shared/models/services.model';
import { NgbCollapseModule, NgbDropdownModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';

@Component({
  selector: 'app-services-dashboard',
  templateUrl: './services-dashboard.component.html',
  styleUrls: ['./services-dashboard.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, NgbCollapseModule, RouterModule, NgbPaginationModule, AlertComponent]
})
export class ServicesDashboardComponent implements OnInit, OnDestroy {
  filters = new UntypedFormGroup({
    name: new UntypedFormControl(null),
    businessLine: new UntypedFormControl(null),
    customerType: new UntypedFormControl(null)
  })

  services: Array<serviceRow> = []
  private unsubscribe = new Subject<void>();
  showDeleteConfirm:boolean = false;
  selectedServiceID:number = -1


  isCollapsed:boolean = true;
  page:number = 1;
  pageSize:number = 10;
  collectionSize:number = 0

  constructor(private serviceServices: ServiceServices, private router: Router, private modalService:ModalService) {

    this.updateFilters()
   }

  ngOnInit(): void {
    this.loadService()
  }

  openServiceDetail(id:number){
    this.router.navigate([`services/${id}`])
  }

  createService(){
    this.router.navigate(['services/create'])
  }

  onPageChange() {
    if (this.page > 0) {
      this.loadService();
    }
  }
  deleteService(id:number) {
    this.selectedServiceID = id;
    this.showDeleteConfirm = true;
  }

  confirmDelete(answer: boolean) {
    if (answer && this.selectedServiceID > -1) {
      this.serviceServices.deleteServiceById(this.selectedServiceID).subscribe(() => {
        this.modalService.showSuccessMessage('Servizio eliminato');
        this.loadService();
      });
    }
    this.showDeleteConfirm = false;
  }

  applyFilters(){
    this.serviceServices.setFilters(this.filters.value)
    this.page = 1
    this.loadService()
  }

  updateFilters(){
    this.serviceServices.getFilters().subscribe((filters) => {
      this.filters.patchValue({
        ...filters
      })
    })
  }

  resetFilters(){
    this.filters.setValue({
      name: null,
      businessLine: null,
      customerType: null
    })
    this.serviceServices.setFilters(this.filters.value)
    this.loadService();
  }

  private loadService() {
    this.serviceServices.getAllServices(this.page -1, this.pageSize, this.filters.value).pipe(takeUntil(this.unsubscribe)).subscribe((servicePage) => {
      this.services = servicePage.content.map((item) => ({...item, selected:false}));
      this.page = servicePage.number +1;
      this.pageSize = servicePage.size;
      this.collectionSize = servicePage.totalElements;
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
