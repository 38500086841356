import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MailVariableTooltipComponent } from '../../mail-variables-tooltip/mail-variables-tooltip.component';

@Component({
  selector: 'app-variable-mail-template',
  templateUrl: './variable-mail-template.component.html',
  styleUrls: ['./variable-mail-template.component.scss'],
  standalone:true,
  imports:[CommonModule, MailVariableTooltipComponent, ReactiveFormsModule]
})
export class VariableMailTemplateComponent {

  entityForm = new UntypedFormGroup({
    singolare: new UntypedFormControl(null),
    plurale: new UntypedFormControl(null)
  })

  @Output() savePlural = new EventEmitter<string>();
  @Output() save = new EventEmitter<string>();
  @Input() title!: string;
  @Input() message!: string;
  @Input() modal!: any;
  closeResult = '';
  openInputPlural = false
  stringaSingolare = ""
  stringaPlurale = ""
  messageEntity = `Inserisci questa variabile per gestire la casistica in cui la mail si riferisca a uno o più caregiver e vuoi usare diciture singolari o plurali.<br><b>Ad esempio</b>: "A loro/Al Profilo ho già anticipato che sono stati/è stato da lei selezionati/selezionato".`;

  selectedVariable: any = {}
  selectedText = ''
  anySelected = false

  variablesList: { label: string, text: string, descrizione: string }[] = [
    { label: 'Nome Genitore', text: '${parent}', descrizione: 'Nome del genitore' },
    { label: "Nome Operatore", text: '${operator}', descrizione: "Nome dell'operatore" },
    { label: 'Nome Caregiver Scelta', text: '${caregiverName}', descrizione: 'Nome caregiver scelta' },
    { label: "Paga Oraria Caregiver", text: '${hourWage}', descrizione: "Paga oraria del caregiver" },
    { label: "Lista Caregiver", text: '${contactList}', descrizione: "Lista contatti caregiver" },
    { label: "Costo Copertura Mensile", text: '${copertura}', descrizione: "Costo Copertura Mensile" },
    { label: "Costo Copertura Giornaliera", text: '${copertura_daily}', descrizione: "Costo Copertura Giornaliera" },
    { label: "Link Disdetta Abbonamento", text: '${cancelURL}', descrizione: "Link di disdetta abbonamento" },
    { label: "Telefono Operatore", text: '${phone}', descrizione: "Numero di telefono dell'operatore" },
    { label: "Mail Servizio Clienti", text: '${clientServiceMail}', descrizione: "Mail del servizio clienti" },
    { label: "Numero Ore", text: '${hours_amount}', descrizione: "Numero di ore" },
    { label: "Link Pagamento Pacchetto Ore", text: '${hours_payment_url}', descrizione: "Link di pagamento per il pacchetto ore" },
    { label: "Costo Pacchetto Ore", text: '${hours_price}', descrizione: "Costo pacchetto ore" },
    { label: "Singolare-Plurale", text: `\${plural} ? ${this.stringaSingolare} : ${this.stringaPlurale}`, descrizione: "Variabile per inserire valori per singolare e plurale: apre un modale per customizzare i valori" },
    { label: "Costo Pacchetto", text: '${price}', descrizione: "Costo del pacchetto" },
    { label: "Link Pagamento Pacchetto", text: '${paymentURL}', descrizione: "Link di pagamento per il pacchetto" },
    { label: "N° Caregiver Selezionate", text: '${nBabysitter}', descrizione: "Numero di caregiver selezionate" },
    { label: "Rimborso Benzina", text: '${fuel_refund}', descrizione: "Rimborso Benzina" },
    { label: 'Quota avvio servizio', text: '${service_start}', descrizione: 'Quota avvio servizio' }
  ].sort((a, b) => a.label.localeCompare(b.label));

  pluralList = [
    { label: "Caregiver Selezionate", text: `\${pluralSelected} ? ${this.stringaSingolare} : ${this.stringaPlurale}`, descrizione: "Variabile per inserire valori per singolare e plurale per le caregiver selezionate", isSelected: false },
    { label: "Caregiver Scelte", text: `\${pluralChosen} ? ${this.stringaSingolare} : ${this.stringaPlurale}`, descrizione: "Variabile per inserire valori per singolare e plurale per le caregiver scelte", isSelected: false },
  ]

  constructor(private modalService: NgbModal) { }

  chooseEntity() {
    this.stringaSingolare = this.entityForm.controls.singolare.value
    this.stringaPlurale = this.entityForm.controls.plurale.value
    if (this.selectedVariable.isSelected === true) {
      if (this.selectedVariable.label === 'Caregiver Selezionate') {
        this.save.emit(`\${pluralSelected ? '${this.stringaPlurale}' : '${this.stringaSingolare}'}`)
        this.modalService.dismissAll()
      } else if (this.selectedVariable.label === 'Caregiver Scelte') {
        this.save.emit(`\${pluralChosen ? '${this.stringaPlurale}' : '${this.stringaSingolare}'}`)
        this.modalService.dismissAll()
      }
    } else {
      this.anySelected = true
    }
  }

  selectVar(variable: any) {
    this.selectedVariable = variable;
    this.pluralList.forEach((variable) => variable.isSelected = false)
    this.selectedVariable.isSelected = true
  }

  open(content: any) {
    this.openInputPlural = true
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }

}

