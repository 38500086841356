import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FHService, ModalService } from 'npx-family-happy-common';
import { ServiceServices } from '../../service-services/service.services';
import { Subject, takeUntil } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
import { TextEditorComponent } from 'src/app/shared/components/text-editor/text-editor.component';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, AlertComponent, TextEditorComponent]
})
export class ServiceDetailComponent implements OnInit {
  form = new UntypedFormGroup({
    businessLine: new UntypedFormControl(null, [Validators.required]),
    customerType: new UntypedFormControl(null, [Validators.required]),
    id: new UntypedFormControl(null),
    name: new UntypedFormControl(null, [Validators.required]),
    script: new UntypedFormControl(null, [Validators.required]),
    disabled: new UntypedFormControl(null)
  });

  isNew: boolean = false;
  showDeleteConfirm:boolean = false;
  selectedService: FHService | undefined;
  scriptText!: string;
  editorTitle = "Script dei Servizi"
  localVariable = ''
  formInvalid = false
  isDirty = false
  haveText = false

  private unsubscribe = new Subject<void>();

  constructor(
    private serviceServices: ServiceServices,
    private modalService: ModalService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activeRoute.paramMap
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((param) => {
        const id = param.get('id') ?? -1;
        if (id === 'create') {
          this.isNew = true;
        } else {
          if (id === -1) {
            this.goBackToService();
          } else {
            this.serviceServices
              .getServiceByID(+id)
              .subscribe((service: FHService) => {
                this.selectedService = service;
                this.initForm(service);
              });
          }
        }
      });
  }

  saveService() {
    if (this.form.valid) {
      const formValue = this.form.value;
      const service: FHService = {
        ...formValue,
      };
      this.serviceServices
        .saveService(service)
        .subscribe(() =>
          this.modalService.showSuccessMessage(
            'Servizio registrato con successo'
          )
        );
    } else {
      this.isDirty = true
      this.haveText = false
      this.formInvalid = true
    }
  }

  deleteService(){
    this.showDeleteConfirm = true
  }

  confirmDelete(answer: boolean) {
    if (answer && this.selectedService && this.selectedService.id > -1) {
      this.serviceServices.deleteServiceById(this.selectedService.id).subscribe(() => {
        this.modalService.showSuccessMessage('Servizio eliminato');
        this.goBackToService();
      });
    }
    this.showDeleteConfirm = false;
  }
  validatorErrorMessage(fieldName:string){
    const field = this.form.get(fieldName);
    let message = ''
    if(field?.errors){
      if(field?.errors['required']){
        message = `Questo campo è obbligatorio`
      }
    }
    return message
  }

  goBackToService() {
    this.router.navigate(['/services']);
  }
  saveScript(event:string){
    this.isDirty = true
    this.haveText = true
    this.form.controls.script.setValue(event)
  }

  initForm(service: FHService) {
    this.form.setValue({
      ...service,
      businessLine: service.businessLine,
      customerType: service.customerType,
      id: service.id,
      name: service.name,
      script: service.script,
      disabled: service.disabled
    });
    this.haveText = true
    this.scriptText = this.form.controls.script.value;
  }
}
