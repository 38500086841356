<div id="cv-page-container" [ngClass]="{ 'cv-preview': previewMode }" *ngIf="_nurse">
  <div id="blue-tr-decorative"></div>
  <div id="pink-tr-decorative"></div>
  <div id="img-container">
    <img [src]="_nurse.info.photo" alt="">
  </div>
  <div id="fh-banner">
    <img src="/assets/logo_banner.png" alt="">
  </div>
  <div id="registry-info">
    <h2>{{_nurse.info.name}}</h2>
    <p>{{_nurse.info.age}} anni</p>
    <!-- <p>{{_nurse.info.address}}</p>
    <p>{{_nurse.info.phone}}</p>
    <p>{{_nurse.info.email}}</p> -->
  </div>
  <div class="d-grid w-100 skills-container">
    <div id="left-column">
      <div id="about-me-section">
        <div class="title-with-icon">
          <p>Su di me</p>
        </div>
        <p class="text" style="font-size: 10pt; line-height: 14px;">{{_nurse.info.description}}</p>
      </div>

      <div id="school-section" style="margin-top: -10px">
        <div class="title-with-icon">
          <p>Formazione</p>
        </div>
        <div class="text d-grid my-2">
          <ul class="m-0">
            <li style="font-size: 10pt; line-height: 14px;">Certificazione Family+Happy Assistenza all'Anziano</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngFor="let cert of certificates">{{cert}}</li>
          </ul>
        </div>
      </div>


      <div id="skills-section" style="margin-top: -10px"
        *ngIf="_nurse.lang.specialities.injections || _nurse.lang.specialities.dressings || _nurse.lang.specialities.glycemia || _nurse.lang.specialities.technology">
        <div class="title-with-icon">
          <p>Competenze</p>
        </div>
        <div class="text d-grid my-2">
          <ul class="m-0">
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_nurse.lang.specialities.injections">Iniezioni sicure
            </li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_nurse.lang.specialities.dressings">Medicazioni</li>
          </ul>
          <ul class="m-0">
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_nurse.lang.specialities.glycemia">Controllo glicemia
              con apposito apparecchio</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_nurse.lang.specialities.technology">Utilizzo di
              apparecchi tecnologici es. ricette elettroniche</li>
          </ul>
        </div>
      </div>

      <div id="language-section" style="margin-top: -10px">
        <div class="title-with-icon">
          <p>Lingue parlate</p>
        </div>
        <div class="text d-grid my-2">
          <ul class="m-0">
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_nurse.lang.italian === 'Madrelingua' || _nurse.lang.italian === 'Ottimo'">Italiano</li>
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_nurse.lang.english === 'Madrelingua' || _nurse.lang.english === 'Ottimo'">Inglese</li>
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_nurse.lang.spanish === 'Madrelingua' || _nurse.lang.spanish === 'Ottimo'">Spagnolo</li>
            <li style="font-size: 10pt; line-height: 14px;">Referenze su richiesta</li>
          </ul>
        </div>
      </div>
    </div>
    <div id="right-column">
      <div id="experience-section" *ngIf="_nurse.exp.total > 0">
        <div class="title-with-icon mb-3">
          <p>Esperienza professionale</p>
        </div>
        <div class="experience-cluster" *ngIf="_nurse.exp.cluster1.cluster1Age > 0">
          <div class="title-with-icon">
            <p>Anziani autosufficienti</p>
            <p>{{ _nurse.exp.cluster1.cluster1Age > 1 ? _nurse.exp.cluster1.cluster1Age + ' anni' : '1 anno' }}</p>
          </div>
          <ul>
            <li *ngIf="taskCheck(1, 'assistance')">Assistenza e compagnia</li>
            <li *ngIf="taskCheck(1, 'care')">Cura personale e igiene</li>
            <li *ngIf="taskCheck(1, 'drugs')">Somministrazione di medicinali</li>
            <li *ngIf="taskCheck(1, 'cooking')">Preparazione pasti</li>
            <li *ngIf="taskCheck(1, 'housing')">Aiuto faccende domestiche</li>
            <li *ngIf="taskCheck(1, 'other')">Utilizzo degli ausili: ({{_nurse.exp.cluster1.help1}})</li>
          </ul>
        </div>
        <div class="experience-cluster" *ngIf="_nurse.exp.cluster2.cluster2Age > 0">
          <div class="title-with-icon">
            <p>Anziani non autosufficienti</p>
            <p>{{ _nurse.exp.cluster2.cluster2Age > 1 ? _nurse.exp.cluster2.cluster2Age + ' anni' : '1 anno' }}</p>
          </div>
          <ul>
            <li *ngIf="taskCheck(2, 'assistance')">Assistenza e compagnia</li>
            <li *ngIf="taskCheck(2, 'care')">Cura personale e igiene</li>
            <li *ngIf="taskCheck(2, 'drugs')">Somministrazione di medicinali</li>
            <li *ngIf="taskCheck(2, 'cooking')">Preparazione pasti</li>
            <li *ngIf="taskCheck(2, 'housing')">Aiuto faccende domestiche</li>
            <li *ngIf="taskCheck(2, 'other')">Utilizzo degli ausili: ({{_nurse.exp.cluster2.help2}})</li>
          </ul>
        </div>
      </div>
      <div id="availabilities">
        <div class="title-with-icon">
          <p>Disponibilità</p>
        </div>
        <div class="text d-grid mb-3">
          <ul class="m-0">
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_nurse.avail.walking">Accompagnare la persona alle
              visite a Piedi</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_nurse.avail.taxiNoCar">Accompagnare la persona alle
              visite con Auto Fornita dalla
              Famiglia</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_nurse.avail.taxi">Accompagnare la persona alle
              visite con Auto Propria</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_nurse.avail.cleaning">Provvedere all'igiene completo
              della persona</li>
          </ul>
          <ul class="m-0">
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_nurse.avail.nightShift">Badante notturna</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_nurse.avail.dayShift">Badante diurna</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_nurse.avail.changing">Cambio pannolone</li>
            <li style="font-size: 10pt; line-height: 14px;" *ngIf="_nurse.avail.hospital">Assistenza in ospedale</li>
          </ul>
        </div>
      </div>

      <!-- <div *ngIf="generationDone" [innerHTML]="sanitizer.bypassSecurityTrustHtml(pages[currentPage])"></div> -->
    </div>
  </div>
  <div id="footer">
    <p id="footer-title">Risultato Test psicoattitudinale</p>
    <!-- <p id="footer-text">{{attitude}}</p> -->
    <p id="footer-text">
      Questo profilo risulta essere accogliente e comprensivo. Caratterizzato da grande empatia e profondo rispetto per
      le esigenze e le emozioni delle persone con cui si rapporta, creando così un ambiente di cura basato sulla fiducia
      reciproca. La sua presenza rassicurante e il suo atteggiamento positivo contribuiscono a creare un clima di
      tranquillità e conforto. Ha un grande senso di responsabilità e si impegna costantemente per garantire il
      benessere e la sicurezza.
    </p>
    <div>
      <p id="footer-subtext">Test Sviluppato con la Facoltà di Psicologia dell’Università di Torino</p>
      <span id="id-babysitter">{{_nurse.docRef}}</span>
    </div>
  </div>
</div>

<div *ngIf="previewMode" class="page-buttons">
  <img src="../../../../assets/icon/previous-icon.svg" (click)="changePage(false)"
    [ngStyle]="{ 'opacity': currentPage > 0 ? '100%' : '50%', 'cursor': currentPage > 0 ? 'pointer' : 'default' }" />
  <button (click)="sendCV()">Invia</button>
  <img src="../../../../assets/icon/next-icon.svg" (click)="changePage(true)"
    [ngStyle]="{ 'opacity': currentPage < pages.length - 1 ? '100%' : '50%', 'cursor': currentPage < pages.length - 1 ? 'pointer' : 'default' }" />
</div>
