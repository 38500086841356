import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from '../../../services/toast-service/toast.service';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-toasts',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  standalone:true,
  imports:[CommonModule, NgbToastModule, NgTemplateOutlet]
})
export class ToastsContainerComponent implements OnInit, OnDestroy {

  toasts!:any
  private unsubscribe = new Subject<void>();

  constructor( private toastService:ToastService) {
   }

  ngOnInit(): void {
    this.toastService.getToast().pipe(takeUntil(this.unsubscribe)).subscribe((toasts) => {
      this.toasts = toasts
    })
  }

  hiddenToast(target:any){
    this.toastService.remove(target)
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
