import { RequestPaymentBody } from './../../../shared/models/request-payment-body.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page, Payment, PossiblePayer } from 'npx-family-happy-common';
import { BehaviorSubject } from 'rxjs';
import { Sell } from 'src/app/shared/models/sell.model';
import { environment } from 'src/environments/environment';

export const DEFAULT_PAYMENT_FILTERS: PaymentFilters = {
  email: null,
  fiscalCode: null,
  province: null,
  amount: null,
  invoiced: null,
  dateFrom: null,
  dateTo: null,
  product: null,
  seller: null,
  serviceID: null
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private baseURL = environment.paymentsAPI;

  filters = new BehaviorSubject<PaymentFilters>(DEFAULT_PAYMENT_FILTERS)

  constructor(private http: HttpClient) {
  }

  getAllPayments(page: number, pageSize = 20, filters = DEFAULT_PAYMENT_FILTERS) {
    const params = [
      `pageNumber=${page}`,
      `pageSize=${pageSize}`,
      `${this.convertFilterObjectToQueryParam(filters)}`
    ].join('&');
    return this.http.get<Page<Payment>>(`${this.baseURL}/list?${params}`);
  }

  getAllSells(page: number, pageSize = 20, filters = DEFAULT_PAYMENT_FILTERS) {
    const params = [
      `pageNumber=${page}`,
      `pageSize=${pageSize}`,
      `${this.convertFilterObjectToQueryParam(filters)}`
    ].join('&');

    return this.http.get<Page<Sell>>(`${this.baseURL}/sells?${params}`);
  }

  getPaymentByID(id: number) {
    return this.http.get<Payment>(`${this.baseURL}/${id}`);
  }

  deletePaymentById(id: number) {
    return this.http.delete<Payment>(`${this.baseURL}/${id}`);
  }

  emitInvoice(payment: Payment) {
    return this.http.patch<Page<Payment>>(`${this.baseURL}/${payment.id}/status?invoiced=true`, {});
  }

  savePayment(payment: Payment) {
    return this.http.post<Payment>(`${this.baseURL}/`, payment);
  }

  claimPaymentById(id: number, operator: string) {
    return this.http.patch<Payment>(`${this.baseURL}/${id}/seller?seller=${operator}`, {});
  }

  getPossiblePayers(email: string) {
    return this.http.get<PossiblePayer[]>(`${this.baseURL}/possible-payers?email=${email}`)
  }

  exportExcel(filters = DEFAULT_PAYMENT_FILTERS, pageSize = 50) {
    const params = [
      `pageSize=${pageSize}`,
      `${this.convertFilterObjectToQueryParam(filters)}`
    ].join('&');

    return this.http.get(`${this.baseURL}/export-excel?${params}`, { responseType: 'blob' });
  }

  createPaymentLink(body: RequestPaymentBody) {
    return this.http.post<{ paymentURL: string }>(`${this.baseURL}/request-payment`, body);
  }

  private convertFilterObjectToQueryParam(filters: PaymentFilters) {
    let queryString = '';
    Object.entries(filters).forEach((entry: [string, any | null]) => {
      if (entry[1] !== null &&
        ((typeof entry[1] === 'string' && entry[1] !== '') ||
          (typeof entry[1] === 'boolean' && entry[1] !== false) ||
          (typeof entry[1] === 'number' && entry[1] >= 0))) {
        if (entry[0] === 'dateFrom' || entry[0] === 'dateTo') {
          const dateValue = `${(entry[1] as string).replace('T', ' ')}:00`;
          queryString += `${queryString === '' ? '' : '&'}${entry[0]}=${dateValue}`
        } else if (entry[0] === 'seller' && entry[1] === 'default') {

        } else if (entry[0] === 'amount') {
          queryString += `${queryString === '' ? '' : '&'}${entry[0]}=${+entry[1]*100}`
        } else {
          queryString += `${queryString === '' ? '' : '&'}${entry[0]}=${encodeURIComponent(entry[1])}`
        }
      }
    })
    return queryString;
  }

  setFilters(filters:any){
    this.filters.next(filters)
  }

  getFilters(){
    return this.filters.asObservable()
  }
}

export interface PaymentFilters {
  email: string | null,
  fiscalCode: string | null,
  province: string | null,
  amount: string | null,
  invoiced: string | null,
  dateFrom: string | null,
  dateTo: string | null,
  product: string | null,
  seller: string | null,
  serviceID: number | null
}
