<div class="row">
  <div class="smartPhone">
    <div class="topBar"></div>
    <div class="leftButton firstBtn"></div>
    <div class="leftButton secondBtn"></div>
    <div class="leftButton thirdBtn"></div>
    <div class="rightButton"></div>
    <div class="boundries">
      <div class="content">
        <div class="row">
          <div class="mobileHeader gap-2">
            <div class="meta-icon">
              <img class="profile-image-preview ng-star-inserted" src="/assets/squaredlogo.png">
            </div>
            <div class="meta-title">
              <div class="meta-title-heading ng-star-inserted">Family+Happy</div>
              <div class="meta-title-sub-heading">Online</div>
            </div>
          </div>
          <div class="iframeSection">
            <div class="overflow">
              <div class="content-container" (click)="copyTextToClipboard(type)">
                <div class="chat-container includesInteractiveButtons ng-star-inserted">
                  <div class="triangle-down"></div>
                  <div class="wa-template-header">
                    <div class="meta-title ng-star-inserted">
                      <h4 style="word-wrap: break-word;font-size: 14px;">Richiesta n. <span class="mergeTag">{{data.requestID}}</span></h4>
                    </div>
                  </div>
                  <div dir="auto" class="wa-template-body ng-star-inserted">
                    <p *ngIf="type === 'PETSITTER'">Siamo alla ricerca di un Petsitter nella città di
                      <span class="mergeTag">{{data.city}}</span> per <span class="mergeTag">{{data.description}}</span>
                    </p>

                    <p *ngIf="type === 'NURSE'">Siamo alla ricerca di un* Badante nella città di
                      <span class="mergeTag">{{data.city}}</span> per <span class="mergeTag">{{data.description}}</span>
                    </p>

                    <p *ngIf="type === 'COLF'">Siamo alla ricerca di un* Colf nella città di
                      <span class="mergeTag">{{data.city}}</span> per <span class="mergeTag">{{data.description}}</span>
                    </p>

                    <p *ngIf="type === 'BABYSITTER'">Siamo alla ricerca di
                      <span class="mergeTag">{{data.role}}</span> per <span class="mergeTag">{{data.childInfo}}</span>
                      nella città di
                      <span class="mergeTag">{{data.city}}</span> per <span class="mergeTag">{{data.description}}</span>
                    </p>

                    <p *ngIf="type === 'BABYSITTER'">Tempistiche: <span class="mergeTag">{{data.starting}}</span></p>
                    <p *ngIf="type === 'PETSITTER' || type === 'NURSE' || type === 'COLF'">Inizio stimato: <span class="mergeTag">{{data.starting}}</span></p>

                    <p>Remunerazione netta: <span class="mergeTag">{{data.wage}}</span></p>
                  </div>
                  <div dir="auto" class="wa-template-footer ng-star-inserted">Family+Happy</div>
                </div>
                <div class="interactive-settings-buttons-wrapper row ng-star-inserted">
                  <div class="interactive-settings-buttons-container col-12 cta ng-star-inserted">
                    <div class="interactive-settings-button ng-star-inserted">
                      <hr class="col-12 ng-star-inserted">
                      <a [href]="'https://babysitter-familyhappy.web.app/richiesta/'+data.requestID" target="_blank"
                        class="interactive-settings-button-content gap-2 text-decoration-none">
                        <i class="bi bi-box-arrow-up-right"></i>
                        <span class="cl-selected-color truncate">Candidati ora!</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
