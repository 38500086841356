<div class="bigger-container">
  <div class="left-section">
    <p class="filter-title">Filtra</p>
    <form class="filters" [formGroup]="filters">
      <div>
        <label>ID</label>
        <input type="number" formControlName="id" placeholder="Cerca per ID">
      </div>
      <div>
        <label>Richiedente</label>
        <input type="text" formControlName="parent" placeholder="Cerca per nome/cognome">
      </div>
      <div>
        <label>Email</label>
        <input type="text" formControlName="email" placeholder="Cerca per email">
      </div>
      <div>
        <label>Telefono</label>
        <input type="text" formControlName="phone" placeholder="Cerca per telefono">
      </div>
      <div>
        <label>Categoria</label>
        <select formControlName="category">
          <option default>TUTTI</option>
          <option value="BABYSITTER">BABYSITTER</option>
          <option value="PETSITTER">PETSITTER</option>
          <option value="NURSE">BADANTI</option>
          <option value="COLF">COLF</option>
        </select>
      </div>
      <div>
        <label>Città</label>
        <input type="text" formControlName="city" placeholder="Cerca per città">
      </div>
      <div>
        <label>Zona</label>
        <input type="text" formControlName="zone" placeholder="Cerca per zona">
      </div>
      <div>
        <label for="status">Stato</label>
        <select style="max-width: 200px" id="status" formControlName="status">
          <option default>TUTTI</option>
          <option value="NUOVA">NUOVA</option>
          <option value="PRESA IN CARICO">PRESA IN CARICO</option>
          <option value="INVIATO PREVENTIVO">INVIATO PREVENTIVO</option>
          <option value="PREVENTIVO ACCETTATO">PREVENTIVO ACCETTATO</option>
          <option value="SELEZIONE ATTIVA">SELEZIONE ATTIVA</option>
          <option value="INVIATO CV">INVIATO CV</option>
          <option value="PAGATO">PAGATO</option>
          <option value="SELEZIONE COMPLETATA">SELEZIONE COMPLETATA</option>
          <option value="STAND-BY">STAND-BY</option>
          <option value="NON RISPONDE">NON RISPONDE</option>
          <option value="DA RICHIAMARE">DA RICHIAMARE</option>
          <option value="NON PIU’ INTERESSATO">NON PIU’ INTERESSATO</option>
        </select>
      </div>
      <div>
        <label for="operator">Presa in carico da</label>
        <app-operator-filter [includeAll]="true" [includeNone]="true" [operator]="filters.get('operator')?.value"
          (valueChange)="updateOperator($event)"></app-operator-filter>
      </div>
      <div>
        <label for="trash">Visualizza da</label>
        <select id="trash" formControlName="trash">
          <option [value]="false">Richieste attive</option>
          <option [value]="true">Cestino</option>
        </select>
      </div>
    </form>
    <button class="reset-button" (click)="resetFilters()">RESET</button>
  </div>
  <div class="right-section">
    <div class="smaller-container">
      <p class="smaller-text">Richieste Family Happy</p>
      <div class="smaller-bar"></div>
    </div>
    <button class="my-button" (click)="openDetail('new')">NUOVA</button>
    <div style="max-height: 70vh; overflow-y: auto;">
      <table>
        <th>ID</th>
        <th>Tipo</th>
        <th>Operatore</th>
        <th>Richiedente</th>
        <th>Città</th>
        <!-- <th>Zona</th> -->
        <th (click)="orderBy(null)" class="clickable">Data
          <img *ngIf="sortBy === null && sortDesc" src="../../../assets/icon/down-arrow-icon.svg">
          <img *ngIf="sortBy === null && !sortDesc" src="../../../assets/icon/up-arrow-icon.svg">
        </th>
        <!-- <th (click)="orderBy('datePreventivo')" class="clickable">Data Preventivo Accettato
          <img *ngIf="sortBy === 'datePreventivo' && sortDesc" src="../../../assets/icon/down-arrow-icon.svg">
          <img *ngIf="sortBy === 'datePreventivo' && !sortDesc" src="../../../assets/icon/up-arrow-icon.svg">
        </th> -->
        <th>Stato</th>
        <th *ngIf="trash?.value === 'true'">Ripristina</th>
        <th>Duplica</th>
        <th>Elimina</th>
        <!-- <th>Stato Richiesta</th> -->
        <tbody style="overflow-y: auto;">
          <a style="display: table-row;text-decoration: unset;color: unset"
            [href]="'request-detail/'+request.id" target="_blank"
            *ngFor="let request of richieste" (click)="$event.stopImmediatePropagation();$event.preventDefault();openDetail(request.id)">
            <!-- <a href="" class="link-no-style" style="display: contents;" [routerLink]=""> -->
            <td [ngStyle]="{'cursor': trash?.value === 'true' ? 'unset' : 'pointer'}">{{
              request.id }}</td>
            <td [ngStyle]="{'cursor': trash?.value === 'true' ? 'unset' : 'pointer'}">{{
              request.category }}</td>
            <td [ngStyle]="{'cursor': trash?.value === 'true' ? 'unset' : 'pointer'}">
              {{
              request.operator || '-' }}</td>
            <td [ngStyle]="{'cursor': trash?.value === 'true' ? 'unset' : 'pointer'}">
              {{
              request.name }}</td>
            <td [ngStyle]="{'cursor': trash?.value === 'true' ? 'unset' : 'pointer'}">
              {{
              request.city }}</td>
            <!-- <td [ngStyle]="{'cursor': trash?.value === 'true' ? 'unset' : 'pointer'}">
              {{
              request.zone }}</td> -->
            <td [ngStyle]="{'cursor': trash?.value === 'true' ? 'unset' : 'pointer'}">
              {{
              request.date | date: 'dd/MM/yyyy'}}</td>
            <!-- <td [ngStyle]="{'cursor': trash?.value === 'true' ? 'unset' : 'pointer'}">
              {{
              request.datePreventivo | date: 'dd/MM/yyyy'}}</td> -->
            <td [ngStyle]="{'cursor': trash?.value === 'true' ? 'unset' : 'pointer'}">
              {{
              request.status || '-' }}</td>
            <td *ngIf="trash?.value === 'true'" (click)="$event.stopImmediatePropagation();$event.preventDefault();restoreRequest(+request.id)"
              style="text-align: center;color: green; cursor: pointer;">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24"
                preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                <path
                  d="M13 3a9 9 0 0 0-9 9H1l3.89 3.89l.07.14L9 12H6a7 7 0 0 1 7-7a7 7 0 0 1 7 7a7 7 0 0 1-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.896 8.896 0 0 0 13 21a9 9 0 0 0 9-9a9 9 0 0 0-9-9z"
                  fill="currentColor" />
              </svg>
            </td>
            <td style="text-align: center;cursor: pointer;"
              (click)="$event.stopImmediatePropagation();$event.preventDefault();duplicateRequest(+request.id)">
              <i class="bi bi-copy"></i>
            </td>
            <td (click)="$event.stopImmediatePropagation();$event.preventDefault();deleteRequest(+request.id)"
              style="text-align: center;cursor: pointer;">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="red">
                <path
                  d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
              </svg>
            </td>
            <!-- <td>{{ request.stato }}</td> -->
            <!-- </a> -->
          </a>
          <tr *ngIf="richieste.length === 0">
            <td colspan="7">Non sono state trovate richieste che soddisfano i criteri di ricerca.</td>
          </tr>
        </tbody>

      </table>
    </div>

    <div class="d-flex justify-content-between align-items-center mt-4">
      <div class="d-flex gap-2">
        <!-- <button class="m-auto" id="button" *ngIf="showPageButton" (click)="loadMore()">Carica altri risultati</button> -->
        <p>Sono presenti <b>{{collectionSize}} richieste</b> nella lista</p>
      </div>
      <div class="elements-limit-container">
        <label class="mt-1" for="elements-number">N. elementi per pagina</label>
        <select id="elements-number" [formControl]="elementsPerPage">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
        <!-- <p class="col">
            Sono presenti <b>{{ collectionSize }}</b> servizi in totale.
          </p> -->
        <ngb-pagination class="col d-flex justify-content-end" *ngIf="richieste.length > 0" [maxSize]="5"
          [(page)]="pageView" [pageSize]="pageSize" [collectionSize]="collectionSize"
          (pageChange)="onPageChange()"></ngb-pagination>
      </div>
    </div>
    <div class="d-flex flex-column gap-2 mt-3" *ngIf="isAdmin">
      <button class="btn m-0" id="button" (click)="exportRequests()">Esporta richieste</button>
      <button class="btn m-0" id="button" (click)="exportRequestUpdates()">Esporta aggiornamenti richieste</button>
    </div>
  </div>
</div>

<!--Alert section-->
<app-alert *ngIf="showAlert" (save)="onAlertConfimation($event)" [data]="{ type: undefined }" [title]="'Attenzione!'"
  [message]="'Vuoi davvero eliminare questa richiesta?'">
</app-alert>
