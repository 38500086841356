<div class="container p-3">
  <div class="d-flex justify-content-between">
    <div class="d-flex gap-2">
      <h2 class="bi bi-arrow-left pointer" (click)="goBackToEmails()"></h2>
      <h2 class="mb-3" *ngIf="!isNew">Template: {{selectedTemplate?.id}}</h2>
      <h2 class="mb-3" *ngIf="isNew">Registra Template</h2>
    </div>
  </div>
  <form [formGroup]="form" class="p-3 mb-3 rounded">
    <div class="row">
      <div class="col-6 mb-3">
        <label for="lang" class="form-label">Lingua</label>
        <select class="form-select" id="lang" formControlName="lang">
          <option value="IT">Italiano</option>
        </select>
        <div *ngIf="form.get('lang')?.touched && form.get('lang')?.errors || formInvalid">
          <span class="form-error">{{validatorErrorMessage('lang')}}</span>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="type" class="form-label">Tipo di Template</label>
        <select class="form-select" id="type" formControlName="type">
          <option value="QUOTATION">Preventivo</option>
          <option value="PROFILES">Profilo</option>
          <option value="CONTACTS">Contatto</option>
        </select>
        <div *ngIf="form.get('type')?.touched && form.get('type')?.errors || formInvalid">
          <span class="form-error">{{validatorErrorMessage('type')}}</span>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="subject" class="form-label">Soggetto</label>
        <input
          type="text"
          id="subject"
          class="form-control"
          formControlName="subject"
        />
        <div *ngIf="form.get('subject')?.touched && form.get('subject')?.errors || formInvalid">
          <span class="form-error">{{validatorErrorMessage('subject')}}</span>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="customerType" class="form-label">Seleziona Servizio</label>
        <app-fh-service-select [value]="form.get('service')?.value"
          (serviceSelected)="updateFHService($event)"></app-fh-service-select>
      </div>

      <div class="col-6 mb-3">
        <label for="templateServiceCustomerType" class="form-label">Tipo di Cliente</label>
        <input
          type="text"
          id="templateServiceCustomerType"
          class="form-control"
          formControlName="templateServiceCustomerType"
          [readonly]="readOnly"
        />
        <div *ngIf="form.get('templateServiceCustomerType')?.touched && form.get('templateServiceCustomerType')?.errors || formInvalid">
          <span class="form-error">{{validatorErrorMessage('templateServiceCustomerType')}}</span>
        </div>
      </div>

      <div class="col-6 mb-3">
        <label for="templateServiceBusinessLine" class="form-label">Business Line</label>
        <input
          type="text"
          id="templateServiceBusinessLine"
          class="form-control"
          formControlName="templateServiceBusinessLine"
          [readonly]="readOnly"
        />
        <div *ngIf="form.get('templateServiceBusinessLine')?.touched && form.get('templateServiceBusinessLine')?.errors || formInvalid">
          <span class="form-error">{{validatorErrorMessage('templateServiceBusinessLine')}}</span>
        </div>
      </div>
      <app-text-editor [variable]="localVariable" [parent]="editorTitle" [text]="eMailText" (save)="saveText($event)"></app-text-editor>
      <div *ngIf="isDirty && !haveText ">
        <span class="form-error">{{validatorErrorMessage('text')}}</span>
      </div>
    </div>
  </form>
  <div class="d-flex flex-row-reverse gap-2">
    <button class="btn btn-primary text-light" (click)="saveTemplate()"><i class="bi bi-floppy-fill text-light"></i>
      Salva</button>
    <button *ngIf="!isNew" class="btn btn-danger text-light" (click)="deleteTemplate()"><i class="bi bi-x-lg text-light pointer"></i>
      Elimina</button>
  </div>
</div>

<app-alert *ngIf="showDeleteConfirm" (save)="confirmDelete($event)" [data]="{ type: undefined }"
  [title]="'Conferma eliminazione'"
  [message]="'Sei sicuro di voler eliminare il Template ' + selectedTemplate?.id + '?'">
</app-alert>

