<div class="p-3">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title class="fw-bold">
        Storico Valutazioni
      </mat-panel-title>
    </mat-expansion-panel-header>
    <table class="w-100 table table-striped">
      <thead>
        <tr>
          <th>Data</th>
          <th>Generale</th>
          <th>Puntualità</th>
          <th>Empatia</th>
          <th>Educazione</th>
          <th>Comunicazione</th>
          <th>Profilo</th>
          <th>Pericolosa</th>
          <th>Operatore</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let record of history">
          <td class="fw-normal">{{ record.timestamp | date: 'yyyy-MM-dd HH:mm:ss':'UTC+2'}}</td>
          <td>{{ record.overall }}</td>
          <td>{{ record.punctuality }}</td>
          <td>{{ record.empathy }}</td>
          <td>{{ record.behave }}</td>
          <td>{{ record.communication }}</td>
          <td>{{ record.attitude }}</td>
          <td>{{ record.dangerous ? 'Si' : 'No' }}</td>
          <td>{{ record.operator }}</td>
        </tr>
        <tr>
          <td colspan="9" class="text-center" *ngIf="history.length === 0">La caregiver non è ancora stata valutata.</td>
        </tr>
      </tbody>
    </table>

  </mat-expansion-panel>
</div>
