<div class="modal" id="modal">
  <div class="modal-content">
    <img style="background: white; border-radius: 10px;" src="/assets/icon/logo.png">
    <div>
      <div class="google-login" (click)="loginWithGoogle()">
        <img src="/assets/icon/google-icon.png">
        <span>Accedi con Google</span>
      </div>
    </div>
    <div class="oppure-text">
      <div class="bar"></div>
      <span>Oppure</span>
      <div class="bar"></div>
    </div>
    <div *ngIf="section === 'login'">
      <form [formGroup]="signInForm" (click)="errorMessage = ''">
        <div class="item">
          <input type="email" id="email-login" required placeholder="Email" formControlName="email">
        </div>
        <div class="item">
          <input type="password" id="password-login" placeholder="Password" required formControlName="password">
          <!-- <ion-icon name="eye-outline" class="eye" (click)="showPassword()"></ion-icon> -->
        </div>
      </form>
      <div class="error-box" *ngIf="errorMessage">
        <!-- <ion-icon name="warning"></ion-icon> -->
        <span>{{errorMessage}}</span>
      </div>
      <p class="my-button" id="login-btn" (click)="signIn()">Accedi</p>
      <div class="signUpBlock">
        <p class="forgotText" id="forgot-pwd-btn" (click)="forgotPassword()">Password dimenticata?</p><br><br>
        <p>Prima volta qui? </p>
        <p class="signUpText" id="go-to-register-btn" (click)="section = 'register'">Registrati</p>
      </div>
    </div>
    <div *ngIf="section === 'register'">
      <form [formGroup]="signUpForm" (click)="errorMessage = ''">
        <div class="item">
          <input type=text placeholder="Nome" formControlName="name">
          <div *ngIf="getFormControl('name', 'required')" class="error-class">
            <!-- <ion-icon name="warning"></ion-icon> -->
            Nome obbligatorio.
          </div>
        </div>
        <div class="item">
          <input type=text placeholder="Cognome" formControlName="surname">
          <div *ngIf="getFormControl('surname', 'required')" class="error-class">
            <!-- <ion-icon name="warning"></ion-icon> -->
            Cognome obbligatorio.
          </div>
        </div>
        <div class="item">
          <input type=email placeholder="Email" formControlName="email">
          <div *ngIf="getFormControl('email', 'required')" class="error-class">
            <!-- <ion-icon name="warning"></ion-icon> -->
            Indirizzo email obbligatorio.
          </div>
          <div *ngIf="getFormControl('email', 'email')" class="error-class">
            <!-- <ion-icon name="warning"></ion-icon> -->
            Inserire un indirizzo email valido.
          </div>
        </div>
        <div class="item">
          <input type=tel placeholder="Telefono" formControlName="phone">
          <div *ngIf="getFormControl('phone', 'required')" class="error-class">
            <!-- <ion-icon name="warning"></ion-icon> -->
            Numero di telefono obbligatorio.
          </div>
          <div *ngIf="getFormControl('phone', 'pattern')" class="error-class">
            <!-- <ion-icon name="warning"></ion-icon> -->
            Inserire un numero di telefono valido.
          </div>
        </div>
        <div class="item">
          <input formControlName="password" placeholder="Password" type=password id="signinPassword">
          <!-- <ion-icon name="eye-outline" class="eye" (click)="showPassword()"></ion-icon> -->
          <div *ngIf="getFormControl('password', 'required')" class="error-class">
            <!-- <ion-icon name="warning"></ion-icon> -->
            Password obbligatoria.
          </div>
          <div *ngIf="getFormControl('password', 'minlength')" class="error-class">
            <!-- <ion-icon name="warning"></ion-icon> -->
            La password dev'essere lunga almeno 8 caratteri.
          </div>
        </div>
        <div class="terms-label">
          <input type="checkbox" style="width: min-content;" formControlName="privacy">
          <p>Accetta <a href="https://babysitter.familyhappy.it/informativa-privacy" target="_blank">Privacy</a> e <a
              href="https://babysitter.familyhappy.it/termini-e-condizioni" target="_blank">Termini
              di
              utilizzo</a></p>
        </div>
      </form>
      <div class="error-class" *ngIf="errorMessage">
        <!-- <ion-icon name="warning"></ion-icon> -->
        <span>{{errorMessage}}</span>
      </div>
      <p class="my-button" (click)="signUp()">Registrati</p>
      <div class="signUpBlock">
        <p>Hai già un account? </p>
        <p class="signUpText" (click)="section = 'login'">Accedi</p>
      </div>
    </div>
  </div>
</div>
