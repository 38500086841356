import { GoogleMapsService } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/google-maps.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CaregiverSelectionRecordSortable } from 'src/app/shared/models/caregiverSelectionRecordSortable.models';
import { CommonModule } from '@angular/common';
import { GoogleMap, MapAdvancedMarker, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { CaregiverCardComponent } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-card/caregiver-card.component';
import { AppModule } from 'src/app/app.module';

@Component({
  selector: 'app-map',
  standalone: true,
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, GoogleMap, MapMarker, MapInfoWindow, MapAdvancedMarker, CaregiverCardComponent]
})
export class MapComponent implements OnInit {

  @Input() modal!: any;
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;

  address:any = null
  addressCoordinates!: google.maps.LatLngLiteral
  caregiversArray!: Array<CaregiverSelectionRecordSortable>
  caregiverMarkers: Array<any> = []

  options!: google.maps.MapOptions
  // markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  markerTest!: google.maps.LatLngLiteral

  filters = new UntypedFormGroup({
    distance: new UntypedFormControl(null)
  })

  circleColor = '#3C66A3'
  isCollapsed = true

  constructor(private googleMapsService: GoogleMapsService) {
    this.googleMapsService.getCoordinatesSub().subscribe((value:any) => {
      if(value !== null){
        this.address = {
          addressName: value.addressName,
          range: value.range
        }
        this.addressCoordinates = {
          lat:value.latitude,
          lng:value.longitude
        }

        this.caregiversArray = value.caregiverList
        this.caregiversArray.forEach((caregiver) => {
          this.caregiverMarkers.push(this.initCaregiversMarker(caregiver))
        })
        this.options = {
          center: {lat: this.addressCoordinates.lat, lng: this.addressCoordinates.lng},
          mapId:"fef0a1befdd8ed40",
          zoom: 13
        };
      }
    })
  }

  ngOnInit(): void {

  }

  initCaregiversMarker(caregiver:CaregiverSelectionRecordSortable){
    if(caregiver.record.babysitter !== null){
      let caregiverMarker = {
        caregiver:caregiver,
        latLngLiteral:{
          lat:caregiver.record.babysitter?.info.latitude,
          lng:caregiver.record.babysitter?.info.longitude,
        }
      }
      return caregiverMarker
    }
    else if(caregiver.record.petsitter !== null){
      let caregiverMarker = {
        caregiver:caregiver,
        latLngLiteral:{
          lat:caregiver.record.petsitter?.info.latitude,
          lng:caregiver.record.petsitter?.info.longitude,
        }
      }
      return caregiverMarker
    }
    else if(caregiver.record.nurse !== null){
      let caregiverMarker = {
        caregiver:caregiver,
        latLngLiteral:{
          lat:caregiver.record.nurse?.info.latitude,
          lng:caregiver.record.nurse?.info.longitude,
        }
      }
      return caregiverMarker
    }
    else if(caregiver.record.colf !== null){
      let caregiverMarker = {
        caregiver:caregiver,
        latLngLiteral:{
          lat:caregiver.record.colf?.info.latitude,
          lng:caregiver.record.colf?.info.longitude,
        }
      }
      return caregiverMarker
    } else {
      return null
    }
  }

  // addMarker(event: google.maps.MapMouseEvent) {
  //   this.markerPositions.push(event.latLng!.toJSON());
  // }
  openInfoWindow(marker: MapAdvancedMarker) {
    this.infoWindow.open(marker);
  }
}
