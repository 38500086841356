import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { RequestUpdate } from 'src/app/shared/models/request-update.model';
import { RequestService } from '../request-service/request.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-request-updates',
  templateUrl: './request-updates.component.html',
  styleUrls: ['./request-updates.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule]
})
export class RequestUpdatesComponent implements OnInit, OnDestroy {
  @Input() requestID!: number;
  requestUpdates!: RequestUpdate[];
  updatesPerPage = new UntypedFormControl(10);
  updatesPage = new UntypedFormControl(1);
  showPageButton: boolean = false;
  private currentPage = 1;
  private unsubscribe = new Subject<void>();

  constructor(private requestService: RequestService) { }

  ngOnInit(): void {
    // Listen for changes in page size
    this.updatesPerPage.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(newLimit => {
      if (this.currentPage > 1) {
        this.updatesPage.setValue(1);
      } else {
        this.loadUpdates(this.currentPage, newLimit);
      }
    })
    // Listen for changes in page
    this.updatesPage.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(newPage => {
      this.loadUpdates(newPage, this.updatesPerPage.value, newPage > this.currentPage);
    })
    // Load the first page
    this.updatesPage.setValue(1);
  }

  ngOnDestroy(): void {
    // Remove subscriptions
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  moreUpdates() {
    // Load next page
    this.updatesPage.setValue(this.updatesPage.value + 1);
  }

  loadUpdates(page: number, limit: number, loadMore = false) {
    // Perform HTTP call to server to get a page of updates
    this.requestService.getRequestUpdates(this.requestID, page, limit).subscribe(updates => {
      if (loadMore) {
        this.requestUpdates.push(...updates)
      } else {
        this.requestUpdates = updates;
      }

      if (updates.length < limit) {
        this.showPageButton = false;
      } else {
        this.showPageButton = true;
      }
      this.currentPage = page;
    })
  }

}
