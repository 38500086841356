<div class="position-relative">
  <label for="customer" class="form-label">{{label}}<i class="bi bi-info-circle-fill ms-2 text-primary" placement="top"
      ngbTooltip="Incolla l'indirizzo email della persona censita in piattaforma associata a questo pagamento."></i></label>
  <input type="text" id="payer" class="form-control" [formControl]="payerControl" [ngbTypeahead]="payerSearch"
    [resultTemplate]="resultRow" [inputFormatter]="formatter" (selectItem)="onPayerSelected($event)" autocomplete="hjl">
  <i class="bi bi-x-lg position-absolute pointer" style="right: 12px; top: 39px;" (click)="resetSelection()"></i>
</div>

<ng-template #resultRow let-r="result" let-t="term">
  <div style="max-width: 300px;text-wrap: wrap;">
    <span class="me-2">{{r.type}} - {{r.docRef}}:</span>
    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
  </div>
</ng-template>
