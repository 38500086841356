import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Page, Product } from 'npx-family-happy-common';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private baseURL = environment.productsAPI;

  constructor(private http: HttpClient) { }

  searchProduct(page: number, pageSize = 20, searchKey: string) {
    const params = [
      `pageNumber=${page}`,
      `pageSize=${pageSize}`,
      `searchKey=${searchKey}`,
    ].join('&');
    return this.http.get<Page<Product>>(`${this.baseURL}/list?${params}`);
  }

  getProductByID(id: number) {
    return this.http.get<Product>(`${this.baseURL}/${id}`)
  }
}
