import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private message = new BehaviorSubject<string>('');

  constructor() { }

  /* Set a new message */
  setMessage(message: string) {
    this.message.next(this.localizeMessage(message));
  }

  /* Get the message observer */
  getMessage() {
    return this.message;
  }

  /* Localize an error message */
  private localizeMessage(message: string) {
    switch (message) {
      case 'The password is invalid or the user does not have a password.':
        return 'La password non è corretta o l\'utente non ha una passoword';
      case 'There is no user record corresponding to this identifier. The user may have been deleted.':
        return 'Non esiste alcun utente corrispondente a queste credenziali. L\'utente potrebbe essere stato cancellato';
      default:
        return message;
    }
  }
}
