import { ScheduledAppointment } from 'src/app/shared/models/scheduled-appointment.model';
import { Babysitter, Colf, Nurse, Petsitter, RequestNurseDetail, RequestPetsitterDetail } from 'npx-family-happy-common';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CaregiverSummary, Request } from 'npx-family-happy-common';

const DEFAULT_REQUEST_STATE = {
  route: 'request-list',
  step: 0,
  id: 'null',
  request: undefined,
  babysitterFilters: {},
  listFilters: {},
  babysitterUnwanted: [],
  timestampPreventivo: undefined,
  scheduledAppointment: undefined,
  unsavedChanges: false,
  requestDetail: undefined,
  isNew: true
};

export interface RequestState {
  route: string;
  step: number;
  id: string;
  request: Request | undefined;
  babysitterFilters: any;
  listFilters: any;
  babysitterUnwanted: CaregiverSummary[];
  timestampPreventivo: Date | undefined;
  scheduledAppointment: ScheduledAppointment | undefined;
  unsavedChanges: boolean;
  requestDetail: undefined | RequestPetsitterDetail | RequestNurseDetail;
  isNew: boolean;
}

export interface BabysitterState {
  route: string;
  step: number;
  id: string;
  babysitter: Babysitter | undefined;
  filters: any;
}

export interface PetsitterState {
  route: string;
  step: number;
  id: string;
  petsitter: Petsitter | undefined;
  filters: any;
}

export interface NurseState {
  route: string;
  step: number;
  id: string;
  nurse: Nurse | undefined;
  filters: any;
}

export interface ColfState {
  route: string;
  step: number;
  id: string;
  colf: Colf | undefined;
  filters: any;
}

@Injectable({
  providedIn: 'root'
})
export class RouterStateManagerService {
  private requestState = new BehaviorSubject<RequestState>(DEFAULT_REQUEST_STATE);
  private babysitterState = new BehaviorSubject<BabysitterState>({
    route: 'babysitter-list',
    step: 0,
    id: 'null',
    babysitter: undefined,
    filters: {}
  });
  private petsitterState = new BehaviorSubject<PetsitterState>({
    route: 'petsitter-list',
    step: 0,
    id: 'null',
    petsitter: undefined,
    filters: {}
  });
  private nurseState = new BehaviorSubject<NurseState>({
    route: 'nurse-list',
    step: 0,
    id: 'null',
    nurse: undefined,
    filters: {}
  });
  private colfState = new BehaviorSubject<ColfState>({
    route: 'colf-list',
    step: 0,
    id: 'null',
    colf: undefined,
    filters: {}
  });

  constructor(private router: Router) { }

  getBabysitterState() {
    return this.babysitterState;
  }

  getPetsitterState() {
    return this.petsitterState;
  }

  getNurseState() {
    return this.nurseState;
  }

  getColfState() {
    return this.colfState;
  }

  getRequestState() {
    return this.requestState;
  }

  setPetsitterState(state: PetsitterState, navigate = true) {
    if (navigate) {
      this.router.navigate([state.route]);
    }
    this.petsitterState.next(state);
  }

  setNurseState(state: NurseState, navigate = true) {
    if (navigate) {
      this.router.navigate([state.route]);
    }
    this.nurseState.next(state);
  }

  setColfState(state: ColfState, navigate = true) {
    if (navigate) {
      this.router.navigate([state.route]);
    }
    this.colfState.next(state);
  }

  setBabysitterState(state: BabysitterState, navigate = true) {
    if (navigate) {
      this.router.navigate([state.route]);
    }
    this.babysitterState.next(state);
  }

  setRequestState(state: RequestState, navigate = true) {
    if (navigate) {
      this.router.navigate([state.route]);
    }
    this.requestState.next(state);
  }

  setBabysitter(babysitter: Babysitter) {
    const state = this.babysitterState.getValue();
    state.babysitter = babysitter;
    this.setBabysitterState(state);
  }

  setPetsitter(petsitter: Petsitter) {
    const state = this.petsitterState.getValue();
    state.petsitter = petsitter;
    this.setPetsitterState(state);
  }

  setNurse(nurse: Nurse) {
    const state = this.nurseState.getValue();
    state.nurse = nurse;
    this.setNurseState(state);
  }

  setColf(colf: Colf) {
    const state = this.colfState.getValue();
    state.colf = colf;
    this.setColfState(state);
  }

  setRequest(request: Request) {
    const state = this.requestState.getValue();
    state.request = request;
    this.setRequestState(state);
  }

  setScheduledAppointment(scheduledAppointment: ScheduledAppointment) {
    const state = this.requestState.getValue();
    state.scheduledAppointment = scheduledAppointment;
    this.setRequestState(state);
  }

  setRequestDetail(requestDetail: RequestPetsitterDetail | RequestNurseDetail) {
    const state = this.requestState.getValue();
    state.requestDetail = requestDetail;
    this.setRequestState(state);
  }

  resetRequestState() {
    this.requestState.next(DEFAULT_REQUEST_STATE);
  }
}
