<div class="bigger-container">
  <div class="left-section">
    <p class="filter-title">Filtra</p>
    <form class="filters" [formGroup]="form">
      <div>
        <label for="status">Cerca per UID</label>
        <input type="text" formControlName="uid" placeholder="Cerca per UID">
      </div>
      <div>
        <label for="status">Cerca per nome e cognome</label>
        <input type="text" formControlName="name" placeholder="Cerca per nome/cognome">
      </div>
      <div>
        <label for="status">Cerca per email</label>
        <input type="text" formControlName="email" placeholder="Cerca per email">
      </div>
      <div>
        <label for="status">Cerca per telefono</label>
        <input type="text" formControlName="phone" placeholder="Cerca per telefono">
      </div>
      <div>
        <label>Ruolo</label>
        <select formControlName="role">
          <option default value="">TUTTI</option>
          <option value="ADMIN">ADMIN</option>
          <option value="FAMILYTUTOR">FAMILYTUTOR</option>
          <option value="USER">USER</option>
        </select>
      </div>

      <button class="reset-button" (click)="resetFilters()">RESET</button>
    </form>
  </div>
  <div class="right-section">
    <div class="smaller-container">
      <p class="smaller-text">Utenze Family Happy</p>
      <div class="smaller-bar"></div>
    </div>

    <!-- <button class="my-button" (click)="rowClick(undefined)">NUOVA</button> -->

    <div style="max-height: 70vh; overflow-y: auto;">
      <table>
        <th>Nome</th>
        <th>Cognome</th>
        <th>Email</th>
        <th>Cellulare</th>
        <th>Ruolo</th>
        <th>Elimina</th>
        <!-- <th>Stato Richiesta</th> -->
        <tr *ngFor="let user of usersPage ? usersPage.content : []" (click)="rowClick(user)">
          <td>{{ user.name | nullValuesTablePipe }}</td>
          <td>{{ user.surname | nullValuesTablePipe }}</td>
          <td>{{ user.email | nullValuesTablePipe }}</td>
          <td>{{ user.phone | nullValuesTablePipe }}</td>
          <td>
            <select [ngModel]="user.role" (change)="changeUserRole(user, $event)">
              <option value="USER">USER</option>
              <option value="FAMILYTUTOR">FAMILYTUTOR</option>
              <option value="ADMIN">ADMIN</option>
            </select>
          </td>
          <td (click)="$event.stopPropagation();deleteUser(user.uid)" style="text-align: center;">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="red">
              <path
                d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
            </svg>
          </td>
        </tr>
        <tr *ngIf="usersPage && usersPage.content.length === 0">
          <td colspan="7" style="text-align: center">Non sono state trovate utenze che soddisfano i criteri di
            ricerca.</td>
        </tr>
      </table>
    </div>

    <div class="d-grid mt-4" style="grid-template-columns: 1fr 3fr;">
      <p>Sono presenti <b>{{collectionSize}} richieste</b> nella lista</p>
      <div class="elements-limit-container">
        <label class="mt-1" for="elements-number">N. elementi per pagina</label>
        <select id="elements-number" [formControl]="elementsPerPage">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>

        <ngb-pagination
        class="col d-flex justify-content-end"
        *ngIf="usersPage && usersPage.content.length > 0"
        [maxSize]="5"
        [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="collectionSize"
        (pageChange)="onPageChange()"
      ></ngb-pagination>
      </div>
    </div>

  </div>
</div>

<!--Alert section-->
<app-alert *ngIf="showAlert" (save)="onAlertConfirmation($event)" [data]="{ type: undefined }" [title]="'Attenzione!'"
  [message]="message">
</app-alert>
