import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, of} from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { ModalService } from 'npx-family-happy-common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private authService: AuthService, private router: Router, private modalService: ModalService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.getToken().pipe(switchMap((token) => {
      const authorized = this.authService.isUserAuthorized(token);
      if (!authorized) {
        this.showErrorToast(`Si prega di effettuare l'accesso. Nel caso hai già provato ad accedere, significa che la tua utenza non ha i permessi necessari per accedere a questa piattaforma. Se pensi ci possa essere un errore, contatta l'amministratore di sistema.`, 'Attenzione!')
        this.router.navigate(['/login']);
        return of(false);
      } else {
        return of(true);
      }
    }));
  }

  private showErrorToast(message: string, title: string) {
    this.modalService.showErrorMessage(`${title} - ${message}`);
  }

}
