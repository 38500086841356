<div class="position-relative" [formGroup]="form">
<input type="text" id="product" class="form-control" formControlName="productControl" [ngbTypeahead]="productSearch"
  [resultTemplate]="resultRow" [inputFormatter]="formatter" (selectItem)="onProductSelected($event)" autocomplete="hjl">
  <i class="bi bi-x-lg position-absolute pointer" style="right: 12px; top: 9px;" (click)="resetSelection()"></i>
  <div *ngIf="form.get('productControl')?.touched && form.get('productControl')?.errors || formInvalid">
    <span class="form-error">{{validatorErrorMessage('productControl')}}</span>
  </div>
</div>

  <ng-template #resultRow let-r="result" let-t="term">
    <div style="max-width: 300px;text-wrap: wrap;">
      <span class="me-2">{{r.service.customerType}} - {{r.service.businessLine}} - {{r.service.name}}:</span>
      <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
    </div>
  </ng-template>
