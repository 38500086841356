import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  Price,
  Product,
  FHService,
  ModalService,
  ProvinceFilterComponent,
} from 'npx-family-happy-common';
import { PriceRow } from '../priceRow.model';
import { PriceService } from '../prices-service/price.service';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbCollapseModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductSelectComponent } from 'src/app/shared/components/custom-select/product-select/product-select.component';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';

@Component({
  selector: 'app-prices-dashboard',
  templateUrl: './prices-dashboard.component.html',
  styleUrls: ['./prices-dashboard.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, NgbCollapseModule, ProductSelectComponent, ProvinceFilterComponent, RouterModule, NgbPaginationModule, AlertComponent]
})
export class PricesDashboardComponent implements OnInit {
  filters = new UntypedFormGroup({
    amount: new UntypedFormControl(null),
    productID: new UntypedFormControl(null),
    province: new UntypedFormControl(null),
    stripeID: new UntypedFormControl(' '),
  });

  productIDfilter!: number | null

  isCollapsed = true;
  showDeleteConfirm = false;

  prices: Array<PriceRow> = [];
  collectionSize = 0;
  page = 1;
  pageSize = 10;
  selectedPriceID = -1;

  constructor(
    private priceService: PriceService,
    private router: Router,
    private modalService: ModalService
  ) {

    this.updateFilters()
  }

  private loadPrices() {
    this.priceService
      .getAllPrices(this.page - 1, this.pageSize, this.filters.value)
      .subscribe((pricePage) => {
        this.prices = pricePage.content.map((item) => ({
          ...item,
          selected: false,
        }));
        this.page = pricePage.number + 1;
        this.pageSize = pricePage.size;
        this.collectionSize = pricePage.totalElements;
      });
  }
  openPriceDetail(id: number) {
    this.router.navigate(['/prices/' + id]);
  }
  createPrice() {
    this.router.navigate(['/prices/create']);
  }
  deletePrice(id: number) {
    this.selectedPriceID = id;
    this.showDeleteConfirm = true;
  }
  onPageChange() {
    if (this.page > 0) {
      this.loadPrices();
    }
  }
  confirmDelete(answer: boolean) {
    if (answer && this.selectedPriceID > -1) {
      this.priceService.deletePriceById(this.selectedPriceID).subscribe(() => {
        this.modalService.showSuccessMessage('Prezzo eliminato');
        this.loadPrices();
      });
    }
    this.showDeleteConfirm = false;
  }

  applyFilters() {
    this.priceService.setFilters(this.filters.value)
    this.loadPrices();
  }

  updateFilters() {
    this.priceService.getFilters().subscribe((filters) => {
      this.productIDfilter = filters.productID
      this.filters.setValue({
        province: filters.province,
        amount: filters.amount,
        stripeID: filters.stripeID,
        productID: filters.productID,
      })
    })
  }

  resetFilters() {
    this.filters.setValue({
      province: null,
      amount: null,
      stripeID: null,
      productID: null,
    })
    this.productIDfilter = null
    this.priceService.setFilters(this.filters.value)
    this.loadPrices();
  }

  updateProvincia(province: string | undefined) {
    if (province === undefined) {
    } else {
      this.filters.controls.province.setValue(province);
    }
  }
  updateProducts(event: Product | undefined) {
    if (event === undefined) {
      this.filters.controls.productID.setValue(null);
    } else {
      this.filters.controls.productID.setValue(event.id);
    }
  }

  ngOnInit(): void {
    this.loadPrices();
  }
}
