import { Component, OnInit } from '@angular/core';
import { RouterStateManagerService } from 'src/app/shared/services/router-state-manager/router-state-manager.service';
import { environment } from 'src/environments/environment';
import { Petsitter } from 'npx-family-happy-common';
import { CommonModule } from '@angular/common';
import { CvPetsitterTemplateComponent } from '../cv-petsitter-template/cv-petsitter-template.component';

@Component({
  selector: 'app-cv-petsitter-template-wrapper',
  templateUrl: './cv-petsitter-template-wrapper.component.html',
  styleUrls: ['./cv-petsitter-template-wrapper.component.scss'],
  standalone:true,
  imports:[CommonModule, CvPetsitterTemplateComponent]
})
export class CvPetsitterTemplateWrapperComponent implements OnInit {
  isProduction = environment.production;
  mailServiceURL = environment.mailServiceURL;
  petsitter!: Petsitter;

  constructor(private stateManager: RouterStateManagerService) { }

  ngOnInit(): void {
    this.stateManager.getPetsitterState().subscribe((state) => (state.petsitter) ? this.petsitter = state.petsitter : undefined )
  }

}
