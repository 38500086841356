import { ColfRequestDetail } from 'npx-family-happy-common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestColfService {
  private baseURL = environment.requestColfServiceURL;

  constructor(private http: HttpClient) { }

  createColfDetail(detail: ColfRequestDetail) {
    return this.http.post<ColfRequestDetail>(`${this.baseURL}`, detail);
  }

  getColfDetail(requestID: number) {
    return this.http.get<ColfRequestDetail>(`${this.baseURL}/${requestID}`);
  }

  updateColfDetail(requestID: number, detail: ColfRequestDetail) {
    return this.http.put<ColfRequestDetail>(`${this.baseURL}/${requestID}`, detail);
  }

  deleteColfDetail(requestID: number) {
    return this.http.delete<ColfRequestDetail>(`${this.baseURL}/${requestID}`);
  }

}
