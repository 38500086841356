<div class="alert alertColor">
  <div class="alert-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="alert-content">
        <p id="alert-content-message">{{ message }}</p>

        <div class="input-group mb-3">
          <input type="text" [formControl]="caregiverSearchText" (focus)="showSuitableSearchBar = true"
            (clickOutside)="showSuitableSearchBar = false" type="text" class="form-control" placeholder="Caregiver name"
            aria-label="Caregiver" aria-describedby="basic-addon2" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="closeSearchBar()" type="button">
              x
            </button>
          </div>
        </div>
        <div>
          <div class="content m-auto d-flex flex-wrap gap-3 mt-2">
            <div data-cy="card" *ngFor="let record of records">
              <app-caregiver-card [isAddingCaregiver]="isAddingCaregiver" [caregiverSelectionRecord]="record"
                (selectedCard)="pushSelectedRecord(record)" (fittingIndex)="sortOriginalRecord()"></app-caregiver-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="saveSelectedRecord()" class="btn modalButtonAdd">
        Aggiungi Candidate
      </button>
      <button type="button" (click)="modal.close('Save click')" class="btn modalButtonClose">
        Chiudi
      </button>
    </div>
  </div>
</div>
