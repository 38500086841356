import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Message } from '../../models/message.model';

@Injectable({
  providedIn: 'root'
})
export class MessagesHistoryService {

  private baseURL = environment.requestServiceURL + '/mail';
  constructor(private http: HttpClient) { }

  getMessages(requestID: number) {
    return this.http.get(this.baseURL + '/history', { params: {requestId: requestID}});
  }

  setMessage(message: Message[]) {
    return this.http.post(this.baseURL + '/history', message);
  }
}
