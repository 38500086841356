import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BabysitterState, ColfState, NurseState, PetsitterState, RequestState, RouterStateManagerService } from '../../services/router-state-manager/router-state-manager.service';
import { AuthService } from 'src/app/authentication/auth/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  standalone:true,
  imports:[CommonModule, NgbCollapseModule ]
})
export class SideMenuComponent implements OnInit, AfterViewInit {
  @Input() visible = false;
  @Output() dismissMenu = new EventEmitter();

  requestRoute: boolean | undefined = false;
  caregiverRoute: boolean | undefined = false;
  babysitterRoute: boolean | undefined = false;
  petsitterRoute: boolean | undefined = false;
  nurseRoute: boolean | undefined = false;
  colfRoute: boolean | undefined = false;
  sellRoute: boolean | undefined = false;
  sellersRoute: boolean | undefined = false;
  paymentGenerationRoute: boolean | undefined = false;
  backofficeRoute: boolean | undefined = false;
  paymentsRoute: boolean | undefined = false;
  pricesRoute: boolean | undefined = false;
  usersRoute: boolean | undefined = false;
  servicesRoute: boolean | undefined = false;
  productsRoute: boolean | undefined = false;
  emailsRoute: boolean | undefined = false;
  caregiversSubscriptionRoute: boolean | undefined = false;
  loadBalanceRoute: boolean | undefined = false;
  selectionHelperRoute: boolean | undefined = false;

  backofficeCollapsed: boolean = true;
  sellCollapsed: boolean = true;
  caregiverCollapsed: boolean = true;

  isAdmin = false;
  firstTime = true;
  private requestState!: RequestState;
  private babysitterState!: BabysitterState;
  private petsitterState!: PetsitterState;
  private nurseState!: NurseState;
  private colfState!: ColfState;
  private unsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    private auth: AuthService,
    private stateManager: RouterStateManagerService
  ) {
    this.auth.getToken().pipe(takeUntil(this.unsubscribe)).subscribe((token) => this.isAdmin = token?.admin ?? false);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.firstTime = false;
    }, 2000);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((navigation) => {
      if (navigation instanceof NavigationEnd) {
        this.requestRoute = navigation.url.startsWith('/request') || navigation.url === '/';
        this.babysitterRoute = navigation.url.startsWith('/babysitter') || navigation.url.startsWith('/fill-certificate');
        this.petsitterRoute = navigation.url.startsWith('/petsitter');
        this.nurseRoute = navigation.url.startsWith('/nurse');
        this.colfRoute = navigation.url.startsWith('/colf');
        this.caregiverRoute = this.babysitterRoute || this.petsitterRoute || this.nurseRoute || this.colfRoute;
        this.usersRoute = navigation.url.startsWith('/users');
        this.paymentsRoute = navigation.url.startsWith('/payments');
        this.servicesRoute = navigation.url.startsWith('/services'); //obs service route - FA
        this.productsRoute = navigation.url.startsWith('/products'); //obs service route - FA
        this.emailsRoute = navigation.url.startsWith('/emails'); //obs service route - FA
        this.pricesRoute = navigation.url.startsWith('/prices');
        this.backofficeRoute = this.usersRoute || this.paymentsRoute || this.servicesRoute || this.productsRoute || this.pricesRoute || this.emailsRoute || this.loadBalanceRoute;
        this.sellersRoute = navigation.url.startsWith('/sellers');
        this.paymentGenerationRoute = navigation.url.startsWith('/payment-link-generator');
        this.caregiversSubscriptionRoute = navigation.url.startsWith('/caregivers-subscription-dashboard')
        this. selectionHelperRoute = navigation.url.startsWith('/selection-helper')
        this.sellRoute = this.sellersRoute || this.paymentGenerationRoute || this.caregiversSubscriptionRoute || this. selectionHelperRoute;
        this.loadBalanceRoute = navigation.url.startsWith('/load-balance')

      }
    })

    this.stateManager.getBabysitterState().subscribe((state) => {
      this.babysitterState = state;
    });
    this.stateManager.getRequestState().subscribe((state) => {
      this.requestState = state;
    });
    this.stateManager.getPetsitterState().subscribe((state) => {
      this.petsitterState = state;
    });
    this.stateManager.getNurseState().subscribe((state) => {
      this.nurseState = state;
    });
    this.stateManager.getColfState().subscribe((state) => {
      this.colfState = state;
    });
  }

  get isCaregiverCollapsed() {
    return this.caregiverCollapsed && !this.caregiverRoute;
  }

  get isSellCollapsed() {
    return this.sellCollapsed && !this.sellRoute;
  }

  get isBackofficeCollapsed() {
    return this.backofficeCollapsed && !this.backofficeRoute;
  }

  openBabysitter() {
    if (this.babysitterState) {
      this.router.navigate([this.babysitterState.route]);
    } else {
      this.router.navigate(["/babysitter-list"]);
    }
    this.changeRoute();
  }

  openPetsitter() {
    if (this.petsitterState) {
      this.router.navigate([this.petsitterState.route]);
    } else {
      this.router.navigate(["/petsitter-list"]);
    }
    this.changeRoute();
  }

  openNurses() {
    if (this.nurseState) {
      this.router.navigate([this.nurseState.route]);
    } else {
      this.router.navigate(["/nurse-list"]);
    }
    this.changeRoute();
  }

  openColfs() {
    if (this.colfState) {
      this.router.navigate([this.colfState.route]);
    } else {
      this.router.navigate(["/colf-list"]);
    }
    this.changeRoute();
  }

  openRequest() {
    if (this.requestState) {
      this.router.navigate([this.requestState.route]);
    } else {
      this.router.navigate(["/request-list"]);
    }
    this.changeRoute();
  }

  openUsers() {
    this.router.navigate(['/users']);
    this.changeRoute();
  }

  openPayments() {
    this.router.navigate(['/payments']);
    this.changeRoute();
  }

  //services routes - FA
  openServices() {
    this.router.navigate(['/services']);
    this.changeRoute();
  }
//products route - FA
  openProducts() {
    this.router.navigate(['/products']);
    this.changeRoute();
  }
  openMails(){
    this.router.navigate(['/emails']);
    this.changeRoute();
  }
  openSellers() {
    this.router.navigate(['/sellers']);
    this.changeRoute();
  }
  openPrices() {
    this.router.navigate(['/prices']);
    this.changeRoute();
  }

  openPaymentGeneration() {
    this.router.navigate(['/payment-link-generator']);
    this.changeRoute();
  }
  openCaregiversSubscription(){
    this.router.navigate(['/caregivers-subscription-dashboard']);
    this.changeRoute();
  }
  openSelectionHelper(){
    this.router.navigate(['/selection-helper']);
    this.changeRoute();
  }
  openLoadBalance(){
    this.router.navigate(['/load-balance']);
    this.changeRoute();
  }

  private changeRoute() {
    this.backofficeCollapsed = true;
    this.sellCollapsed = true;
    this.caregiverCollapsed = true;
    this.dismissMenu.emit();
  }
}
