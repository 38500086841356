import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed') as any;

class HorizontalRuleBlot extends BlockEmbed {

  static blotName = 'hr';
  static tagName = 'hr';

  static create(value:any) {
    const node = super.create(value);
    node.setAttribute('class', 'fh-separator');
    return node;
  }
}

export default HorizontalRuleBlot;
