import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

export enum Mode {
  Classic = 'Classic',
  Advanced = 'Advanced'
}

@Component({
  selector: 'app-mode-switch',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="mode-switch d-flex align-items-center">
      <button
        class="btn btn-outline-primary me-2"
        [ngClass]="currentMode === Mode.Classic ? 'bg-primary text-white' : 'btn-outline-primary text-primary'"
        (click)="switchMode(Mode.Classic)">
        Classica
      </button>
      <button
        class="btn btn-outline-secondary"
        [ngClass]="currentMode === Mode.Advanced ? 'bg-secondary text-white' : 'btn-outline-secondary text-secondary'"
        (click)="switchMode(Mode.Advanced)">
        Avanzata
      </button>
    </div>
    <div class="mode-display mt-3">
    <p *ngIf="currentMode === Mode.Advanced">Con la ricerca avanzata è possibile trovare tutti i target che rispettano i filtri utilizzati nello "Stato Richiesta". <br>
    <span class="fw-semibold">Ricordati di impostarli e di impostare una distanza target qui in basso.</span>
  </p>
    <p *ngIf="currentMode === Mode.Classic">Con la ricerca classica è possibile trovare tutti i target che sono abbonati e non hanno richiesto lo "Stop WhatsApp". <br>
    <span class="fw-semibold">Ricordati di impostare una distanza target qui in basso.</span>
  </p>
  </div>
  `,
  styles: [`
    .mode-switch .btn {
      min-width: 120px;
    }
  `]
})
export class ModeSwitchComponent {
  @Input() currentMode: Mode = Mode.Classic;
  Mode = Mode; // expose enum to the template
  @Output() modeChanged = new EventEmitter<Mode>();

  switchMode(mode: Mode) {
    if (this.currentMode !== mode) {
      this.modeChanged.emit(mode);
    }
  }
}
