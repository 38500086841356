import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Babysitter, CvTemplateComponent } from 'npx-family-happy-common';
import { RouterStateManagerService } from 'src/app/shared/services/router-state-manager/router-state-manager.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cv-template-wrapper',
  templateUrl: './cv-template-wrapper.component.html',
  styleUrls: ['./cv-template-wrapper.component.scss'],
  standalone:true,
  imports:[CommonModule, CvTemplateComponent]
})
export class CvTemplateWrapperComponent implements OnInit {
  isProduction = environment.production;
  mailServiceURL = environment.mailServiceURL;
  babysitter: Babysitter | undefined;

  constructor(private stateManager: RouterStateManagerService) { }

  ngOnInit(): void {
    this.stateManager.getBabysitterState().subscribe((state) => this.babysitter = state.babysitter)
  }

}
