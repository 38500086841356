import { RequestPetsitterDetail } from 'npx-family-happy-common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestPetsitterService {
  private baseURL = environment.requestPetsitterServiceURL;

  constructor(private http: HttpClient) { }

  createPetsitterDetail(detail: RequestPetsitterDetail) {
    return this.http.post<RequestPetsitterDetail>(`${this.baseURL}`, detail);
  }

  getPetsitterDetail(requestID: number) {
    return this.http.get<RequestPetsitterDetail>(`${this.baseURL}/${requestID}`);
  }

  updatePetsitterDetail(requestID: number, detail: RequestPetsitterDetail) {
    return this.http.put<RequestPetsitterDetail>(`${this.baseURL}/${requestID}`, detail);
  }

  deletePetsitterDetail(requestID: number) {
    return this.http.delete<RequestPetsitterDetail>(`${this.baseURL}/${requestID}`);
  }

}
