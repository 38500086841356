import { Component, OnInit } from '@angular/core';
import { RouterStateManagerService } from 'src/app/shared/services/router-state-manager/router-state-manager.service';
import { environment } from 'src/environments/environment';
import { Nurse } from 'npx-family-happy-common';
import { CommonModule } from '@angular/common';
import { CvNurseTemplateComponent } from '../cv-nurse-template/cv-nurse-template.component';

@Component({
  selector: 'app-cv-nurse-template-wrapper',
  templateUrl: './cv-nurse-template-wrapper.component.html',
  styleUrls: ['./cv-nurse-template-wrapper.component.scss'],
  standalone:true,
  imports:[CommonModule, CvNurseTemplateComponent]
})
export class CvNurseTemplateWrapperComponent implements OnInit {
  isProduction = environment.production;
  mailServiceURL = environment.mailServiceURL;
  nurse!: Nurse;

  constructor(private stateManager: RouterStateManagerService) { }

  ngOnInit(): void {
    this.stateManager.getNurseState().subscribe((state) => (state.nurse) ? this.nurse = state.nurse : undefined )
  }

}
