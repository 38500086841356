import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EvaluationService } from '../../services/evaluation-service/evaluation.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-evaluation-history',
  templateUrl: './evaluation-history.component.html',
  styleUrls: ['./evaluation-history.component.scss'],
  standalone:true,
  imports:[CommonModule, MatExpansionModule]
})
export class EvaluationHistoryComponent implements OnInit, OnDestroy {
  @Input() docRef!: string;
  history: any[] = [{
    timestamp: new Date(),
    overall: 6,
    punctuality: 6,
    empathy: 6,
    behave: 6,
    communication: 6,
    attitude: '',
    dangerous: false,
    operator: 'Giulia Drago'
  }] // Test data
  page = 0;
  pageSize = 10;

  private unsubscribe = new Subject<void>();

  constructor(private evaluationService: EvaluationService) { }

  ngOnInit(): void {
    this.evaluationService.getEvaluationHistory(this.docRef, this.page, this.pageSize)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => this.history = data.content)
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
