import { QuillEditorComponent, QuillModule } from 'ngx-quill';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ReactiveFormsModule } from '@angular/forms';
import { VariableSharingService } from '../text-editor-service/text-editor-service';
import Quill from 'quill';

@Component({
  selector: 'app-text-editor-wrapper',
  standalone: true,
  imports: [CommonModule, QuillModule, ReactiveFormsModule],
  templateUrl: './text-editor-wrapper.component.html',
  styleUrl: './text-editor-wrapper.component.scss'
})
export class TextEditorWrapperComponent implements OnInit {

  editorConfig!: any;
  @Input() controlText!: FormControl<any> ;


  @ViewChild('editor', { static: true }) editor: QuillEditorComponent | undefined

  constructor(private editorService: VariableSharingService) {
    //font register for editor
    const Font: any = Quill.import('formats/font');
    Font.whitelist = ['josefin-sans', 'sans-serif', 'serif', 'monospace'];
    Quill.register(Font, true);
  }

  ngOnInit(): void {
    //init editor config
    this.editorConfig = this.editorService.getEditorConfig(() => this.insertHorizontalRule());
  }

  //method to insert hr on text template
  insertHorizontalRule() {
    const quill = this.editor!.quillEditor;
    const range = quill.getSelection(true);
    quill.insertEmbed(range.index, 'hr', 'null', Quill.sources.USER);
    quill.setSelection(range.index + 1, Quill.sources.SILENT);
  }
}
