<div class="container p-3">
  <div>
    <h2 class="text-center text-primary">Pagamenti</h2>
    <h4 class="text-center pointer" (click)="collapse.toggle()">Filtri<i class="bi ms-2" style="font-size: 16px;"
        [ngClass]="isCollapsed ? 'bi-caret-down-fill' : 'bi-caret-up-fill'"></i></h4>
    <form class="row m-3 p-3 bg-info rounded" [formGroup]="filters" #collapse="ngbCollapse"
      [(ngbCollapse)]="isCollapsed">
      <div class="col-6 mb-3">
        <label for="dateFrom" class="form-label">Da</label>
        <input type="datetime-local" id="dateFrom" class="form-control" formControlName="dateFrom">
      </div>
      <div class="col-6 mb-3">
        <label for="dateTo" class="form-label">A</label>
        <input type="datetime-local" id="dateTo" class="form-control" formControlName="dateTo">
      </div>
      <div class="col-6 mb-3">
        <label for="product" class="form-label">Servizio</label>
        <app-fh-service-select [reset]="resetServiceValue" [serviceID]="serviceID" [value]="filters.get('service')?.value" (serviceSelected)="updateFHService($event)"></app-fh-service-select>
      </div>
      <div class="col-6 mb-3">
        <label for="email" class="form-label">Cliente</label>
        <input type="text" id="email" class="form-control" formControlName="email">
      </div>
      <div class="col-6 mb-3">
        <label for="province" class="form-label">Provincia</label>
        <app-province-filter [provincia]="filters.get('province')?.value" [includeAll]="true"
          (valueChange)="updateProvincia($event)"></app-province-filter>
      </div>
      <div class="col-6 mb-3">
        <label for="invoiced" class="form-label">Fatturato</label>
        <input type="checkbox" id="invoiced" class="form-check" formControlName="invoiced">
      </div>
      <div class="col-6 mb-3">
        <label for="amount" class="form-label">Importo</label>
        <input type="number" step="1" id="amount" class="form-control" formControlName="amount">
      </div>
      <div class="col-6 mb-3">
        <label for="status" class="form-label">Stato</label>
        <select id="status" class="form-select" formControlName="status">
          <option [value]="null" default hidden>Seleziona uno stato</option>
          <option value="Completed">Completato</option>
          <option value="Failed">Fallito</option>
          <option value="Incomplete">Incompleto</option>
        </select>
      </div>
      <div class="col-6 mb-3">
        <label for="seller" class="form-label">Venditore</label>
        <app-operator-filter [operator]="filters.get('seller')?.value" [includeAll]="true" [includeRecruiter]="true" (valueChange)="updateSeller($event)"></app-operator-filter>
      </div>
      <div class="d-flex col-6 mb-3 align-items-end">
      <button class="col-3 btn btn-primary me-3 text-light"
        (click)="collapse.toggle(); applyFilters()">Applica</button>
        <button class="col-3 btn text-light btn-danger"
        (click)="resetFilters()">Reset</button>
      </div>
    </form>
  </div>
  <div class="d-flex flex-row-reverse gap-2">
    <button class="btn btn-info" (click)="createPayment()"><i class="bi bi-plus-square"></i></button>
    <button class="btn btn-info" (click)="exportRecords(content)"><i class="bi bi-box-arrow-up-right"></i></button>
  </div>
  <table class="table table-hover table-striped">
    <thead>
      <tr>
        <!-- <th></th> -->
        <th scope="col">ID</th>
        <th scope="col">Importo</th>
        <th scope="col">Pagante</th>
        <th scope="col">Email</th>
        <th scope="col">Servizio</th>
        <th scope="col">Stato</th>
        <th scope="col">Fattura emessa</th>
        <th scope="col">Data</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let payment of payments">
        <!-- <td>
          <input type="checkbox" [(ngModel)]="payment.selected">
        </td> -->
        <th scope="row">{{payment.id}}</th>
        <td>{{payment.amount / 100 | currency : 'EUR' }}</td>
        <td>{{payment.name}} {{payment.surname}}</td>
        <td><a target="_blank" [href]="origin+payment.urlRelated">{{payment.email}}</a></td>
        <!-- Aggiungere link a elemento collegato in piattaforma -->
        <td>{{payment.service.name}}</td>
        <td class="text-center"><app-payment-status-badge [status]="payment.status"></app-payment-status-badge></td>
        <td class="text-center" style="font-size: 24px;"><i class="bi bi-file-earmark-richtext-fill"
            [ngClass]="{'text-danger': !payment.invoiced, 'text-success': payment.invoiced}">
          </i></td>
        <td>{{payment.timestamp|date: 'dd MMM yyyy'}} {{payment.timestamp|date: 'HH:mm:ss'}}</td>
        <td><a href="" [routerLink]="['/payments/' + payment.id]"><button class="btn btn-primary px-2 py-1"><i
          class="bi bi-search text-light pointer"></i></button></a></td>
        <td><button class="btn btn-danger px-2 py-1" (click)="deletePayment(payment.id)"><i
              class="bi bi-x-lg text-light pointer"></i></button></td>
      </tr>
      <tr *ngIf="payments.length === 0">
        <td colspan="10" class="text-center">Non sono stati trovati pagamenti.</td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <p class="col">Sono presenti <b>{{collectionSize}}</b> pagamenti in totale.</p>
    <ngb-pagination class="col d-flex justify-content-end" *ngIf="payments.length > 0" [maxSize]="5" [(page)]="page"
      [pageSize]="pageSize" [collectionSize]="collectionSize" (pageChange)="onPageChange()"></ngb-pagination>
  </div>
</div>

<app-alert *ngIf="showDeleteConfirm" (save)="confirmDelete($event)" [data]="{ type: undefined }"
  [title]="'Conferma eliminazione'" [message]="'Sei sicuro di voler eliminare il pagamento ' + selectedPaymentID + '?'">
</app-alert>

<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title"><i class="bi bi-box-arrow-up-right me-2"></i>Esporta Pagamenti</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<p>Seleziona una delle seguenti opzioni:</p>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" [value]="selectedOption === 1" (change)="selectedOption = 1">
      <label class="form-check-label" for="flexRadioDefault1">
        Esporta solo i pagamenti filtrati
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" [value]="selectedOption === 2" (change)="selectedOption = 2">
      <label class="form-check-label" for="flexRadioDefault2">
        Esporta tutti i pagamenti della giornata di ieri ({{getYesterdayDate()}})
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" [value]="selectedOption === 3" (change)="selectedOption = 3">
      <label class="form-check-label" for="flexRadioDefault3">
        Esporta tutti i pagamenti esistenti
      </label>
    </div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-danger text-light" (click)="modal.close(false)">Annulla</button>
		<button type="button" class="btn btn-primary text-light" (click)="modal.close(true)">Conferma</button>
	</div>
</ng-template>
