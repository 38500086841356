<div class="col-6 mb-3 mt-3">
  <label for="text">Editor per {{parent}}</label>
  <hr>
  <button type="button" class="btn btn-outline-secondary buttonOpenVar me-2" (click)="open(content)" placement="end" ngbTooltip="Inserisci le variabili all'interno del testo nella posizione del cursore">
    Variabili
  </button>
</div>
<div [formGroup]="form">
<quill-editor [modules]="editorConfig" id="textEditor" #editor formControlName="editor" format="html"></quill-editor>

</div>

<ng-template #content let-modal>
  <app-variable-mail-template (save)="confirmVariables($event)" (savePlural)="confirmVariablesPlural($event)"  [modal]="modal" [title]="'Aggiungi variabile'" [message]="'Seleziona la variabile che vuoi inserire nel template editor:'"></app-variable-mail-template>
</ng-template>
