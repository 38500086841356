import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-operator-filter',
  templateUrl: './operator-filter.component.html',
  styleUrls: ['./operator-filter.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule]
})
export class OperatorFilterComponent implements OnInit, OnDestroy {
  @Input() set operator(operator: string | null) {
    if (operator) {
      this.formControl = new UntypedFormControl(operator);
    } else {
      this.formControl = new UntypedFormControl('');
    }
    this.formControl.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value) => this.valueChange.emit(value));
  }
  @Input() includeAll = false;
  @Input() includeNone = false;
  @Input() includeRecruiter = false;
  @Output() valueChange = new EventEmitter<string>();

  formControl = new UntypedFormControl('');
  private unsubscribe = new Subject<void>();

  constructor() { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
