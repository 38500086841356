import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { FHService, ModalService, PossiblePayer } from 'npx-family-happy-common';
import { PaymentService } from 'src/app/backoffice/payments/payment-service/payment.service';
import { FHServiceSelectComponent } from 'src/app/shared/components/custom-select/fh-service-select/fh-service-select.component';
import { OperatorFilterComponent } from 'src/app/shared/components/custom-select/operator-filter/operator-filter.component';
import { PossiblePayerSelectComponent } from 'src/app/shared/components/custom-select/possible-payer-select/possible-payer-select.component';
import { RequestPaymentBody } from 'src/app/shared/models/request-payment-body.model';
import { getURLRelated } from 'src/app/shared/utils.functions';

@Component({
  selector: 'app-create-payment-link',
  templateUrl: './create-payment-link.component.html',
  styleUrls: ['./create-payment-link.component.scss'],
  standalone:true,
  imports:[CommonModule, FHServiceSelectComponent, OperatorFilterComponent , PossiblePayerSelectComponent, ReactiveFormsModule]
})
export class CreatePaymentLinkComponent implements OnInit {
  amount = new UntypedFormControl(0, [Validators.required, Validators.pattern(/^[0-9]+[\.,]?[0-9]{0,2}$/)]);
  service!: FHService;
  customer!: PossiblePayer;
  seller!: string;
  paymentURL!: string;

  constructor(private paymentService: PaymentService, private modalService: ModalService) { }

  ngOnInit(): void {
  }

  updateService(service: FHService) {
    this.service = service;
  }

  updatePayer(payer: PossiblePayer) {
    this.customer = payer;
  }

  updateSeller(seller: string) {
    this.seller = seller;
  }

  generatePaymentLink() {
    if (this.formValid) {
      const amount = Math.round(Number.parseFloat(this.amount.value) * 100.00);
      const body: RequestPaymentBody = {
        amount,
        email: this.customer.email,
        name: this.customer.name,
        phone: this.customer.phone,
        provinceCode: this.customer.provincia,
        seller: this.seller,
        service: this.service.id,
        surname: this.customer.surname,
        urlRelated: getURLRelated(this.customer.type, this.customer.docRef)
      }
      this.paymentService.createPaymentLink(body).subscribe((response) => {
        this.paymentURL = response.paymentURL;
        this.copyToClipboard();
        this.modalService.showSuccessMessage('Link di pagamento generato con successo e copiato! Ora puoi incollarlo dove vuoi.');
      })
    }
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.paymentURL);
    this.modalService.showSuccessMessage('Link di pagamento copiato con successo! Ora puoi incollarlo dove vuoi.');
  }

  get formValid() {
    return this.customer && this.service && this.seller && this.amount.valid;
  }
}
