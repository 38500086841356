import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caregiverCardDistance',
  standalone:true
})
export class CaregiverCardDistancePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    if(value == -1){
      return "N.A."
    }
    return value.toFixed(2) + " km";
  }

}
