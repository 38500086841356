import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'npx-family-happy-common';

@Component({
  selector: 'app-outcome-modal',
  templateUrl: './outcome-modal.component.html',
  styleUrls: ['./outcome-modal.component.scss'],
  standalone:true,
  imports:[CommonModule]
})
export class OutcomeModalComponent implements OnInit {
  @Input() message!: string;
  @Input() messageType!: string;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
  }

  closeModal() {
    this.modalService.showOutcome.next(false);
  }

}
