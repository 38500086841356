<div class="side-menu-bar position-absolute d-flex flex-column gap-3 bg-info p-3 animate__animated"
  [ngClass]="{'animate__slideInLeft': visible, 'animate__slideOutLeft': !visible}"
  [ngStyle]="{'visibility': firstTime ? 'hidden' : 'visible'}">
  <img class="p-2 mb-5 bg-white rounded" src="/assets/logo_banner.png" alt="">
  <p class="pointer px-2 py-1" [ngClass]="{selected: requestRoute}" (click)="openRequest()">Richieste</p>
  <p class="pointer px-2 py-1" [ngClass]="{selected: caregiverRoute}"
    (click)="caregiverCollapsed = !caregiverCollapsed">Caregivers<i class="bi ms-2" style="font-size: 16px;"
      [ngClass]="isCaregiverCollapsed ? 'bi-caret-down-fill' : 'bi-caret-up-fill'"></i></p>
  <div class="flex-column ms-3 gap-3" [ngClass]="{'d-flex': !isCaregiverCollapsed}"
    [ngbCollapse]="isCaregiverCollapsed">
    <p class="pointer px-2 py-1" [ngClass]="{selected: babysitterRoute}" (click)="openBabysitter()">Babysitter</p>
    <p class="pointer px-2 py-1" [ngClass]="{selected: petsitterRoute}" (click)="openPetsitter()">Petsitter</p>
    <p class="pointer px-2 py-1" [ngClass]="{selected: nurseRoute}" (click)="openNurses()">Badanti</p>
    <p class="pointer px-2 py-1" [ngClass]="{selected: colfRoute}" (click)="openColfs()">Colf</p>
  </div>
  <p class="pointer px-2 py-1" [ngClass]="{selected: sellersRoute}" (click)="sellCollapsed = !sellCollapsed">Venditori<i
      class="bi ms-2" style="font-size: 16px;"
      [ngClass]="isSellCollapsed ? 'bi-caret-down-fill' : 'bi-caret-up-fill'"></i></p>
  <div class="flex-column ms-3 gap-3" [ngClass]="{'d-flex': !isSellCollapsed}" [ngbCollapse]="isSellCollapsed">
    <p class="pointer px-2 py-1" [ngClass]="{selected: sellersRoute}" (click)="openSellers()">
      Vendite</p>
    <p class="pointer px-2 py-1" [ngClass]="{selected: paymentGenerationRoute}" (click)="openPaymentGeneration()">
      Genera link pagamento</p>
    <p class="pointer px-2 py-1" [ngClass]="{selected: caregiversSubscriptionRoute}"
      (click)="openCaregiversSubscription()">
      Caregivers non abbonate</p>
    <p class="pointer px-2 py-1" [ngClass]="{selected: selectionHelperRoute}"
      (click)="openSelectionHelper()">
      Helper selection</p>
  </div>
  <p class="pointer px-2 py-1" [ngClass]="{selected: backofficeRoute}" *ngIf="isAdmin"
    (click)="backofficeCollapsed = !backofficeCollapsed">Backoffice<i class="bi ms-2" style="font-size: 16px;"
      [ngClass]="isBackofficeCollapsed ? 'bi-caret-down-fill' : 'bi-caret-up-fill'"></i></p>
  <div class="flex-column ms-3 gap-3" [ngClass]="{'d-flex': !isBackofficeCollapsed}"
    [ngbCollapse]="isBackofficeCollapsed">
    <p class="pointer px-2 py-1" [ngClass]="{selected: usersRoute}" (click)="openUsers()">Utenti</p>
    <p class="pointer px-2 py-1" [ngClass]="{selected: paymentsRoute}" (click)="openPayments()">
      Pagamenti</p>
    <p class="pointer px-2 py-1" [ngClass]="{selected: servicesRoute}" (click)="openServices()">
      Servizi</p>
    <p class="pointer px-2 py-1" [ngClass]="{selected: productsRoute}" (click)="openProducts()">
      Prodotti</p>
    <p class="pointer px-2 py-1" [ngClass]="{selected: emailsRoute}" (click)="openMails()">
      E-mail</p>
    <p class="pointer px-2 py-1" [ngClass]="{selected: pricesRoute}" (click)="openPrices()">
      Prezzi</p>
    <p class="pointer px-2 py-1" [ngClass]="{selected: loadBalanceRoute}" (click)="openLoadBalance()">
      Bilanciamento Carico FamilyTutor</p>
  </div>
</div>
<div class="position-absolute bg-black side-menu-backdrop" *ngIf="visible" (click)="dismissMenu.emit()"></div>
