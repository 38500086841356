import { CaregiverSummary } from 'npx-family-happy-common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@Component({
  selector: 'app-caregiver-chip',
  templateUrl: './caregiver-chip.component.html',
  styleUrls: ['./caregiver-chip.component.scss'],
  standalone:true,
  imports:[CommonModule]
})
export class CaregiverChipComponent implements OnInit {
  @Input() caregiver!: CaregiverSummary;
  @Input() matchFilter = false;
  @Input() profileRelativePath = 'babysitter-detail';
  @Output() close = new EventEmitter<boolean>();
  @Input() showClose = false;
  @Input() undraggable = false;

  constructor() { }

  ngOnInit() {
  }

  //Emit the click event
  onClick() {
    this.close.emit(true);
  }

  openProfile() {
    let url = `${this.profileRelativePath}/${this.caregiver.docRef}`;
    window.open(url, '_blank');
  }
}
