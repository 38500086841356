<div class="filter-container p-3">
  <ng-container *ngIf="address.addressName === undefined; else AddressInfo">
    <div class="">
    <h4>Attenzione!</h4>
    <p>inserire filtro per <b>indirizzo</b> e una <b>distanza massima</b>!</p>
  </div>
  </ng-container>
  <ng-template #AddressInfo>
    <h4>Indirizzo del filtro</h4>
    <p>{{address.addressName}}</p>
  </ng-template>
</div>
<google-map width="800px" *ngIf="address.addressName !== undefined;" [options]="options">
<map-marker [position]="addressCoordinates" [label]="'R'"></map-marker>
@for (marker of caregiverMarkers; track marker) {
  <map-advanced-marker
  #markerAdv="mapAdvancedMarker"
 [position]="marker.latLngLiteral"
 (mapClick)="window.open(markerAdv)"
 >
 <map-info-window #window="mapInfoWindow">
  <div>
    <app-caregiver-card cdkDrag [caregiverSelectionRecord]="marker.caregiver"></app-caregiver-card>
  </div>
 </map-info-window>
</map-advanced-marker>
}
</google-map>

