import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from '../../services/toast-service/toast.service';
import { CommonModule } from '@angular/common';
import { ToastsContainerComponent } from './toast-container/toast-container.component';

@Component({
  selector: 'ngbd-toast-global',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone:true,
  imports:[CommonModule, ToastsContainerComponent]
})
export class ToastComponent implements OnInit, OnDestroy {

  @ViewChild('standardTpl', { static: true }) standardTpl!: TemplateRef<any>;
  @ViewChild('successTpl', { static: true }) successTpl!: TemplateRef<any>;

  messageToast!:String
  private unsubscribe = new Subject<void>();

  constructor(private toastService:ToastService) {

  }

  ngOnInit(): void {
    this.toastService.getAction().pipe(takeUntil(this.unsubscribe)).subscribe((text) => {
      this.messageToast = text
    })
  }

  showStandard(template: TemplateRef<any>) {
		this.toastService.show({ template, classname: 'test', delay: 5000 });
	}

  showSuccess(template: TemplateRef<any>) {
		this.toastService.show({ template, classname: 'bg-success text-light', delay: 5000 });
	}

  showDanger(template: TemplateRef<any>) {
		this.toastService.show({ template, classname: 'bg-danger text-light', delay: 5000 });
	}

  ngOnDestroy(): void {
		this.toastService.clear();
	}
}
