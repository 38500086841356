import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { RequestNurseDetail, RequestUtilitiesService } from 'npx-family-happy-common';
import { Subject, takeUntil } from 'rxjs';
import { NurseService } from 'src/app/caregiver/nurse/nurse-service/nurse-service.service';

@Component({
  selector: 'app-request-nurse-detail-form',
  templateUrl: './request-nurse-detail-form.component.html',
  styleUrls: ['./request-nurse-detail-form.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule]
})
export class RequestNurseDetailFormComponent implements OnInit, OnDestroy {
  @Input() inputData!: RequestNurseDetail;
  @Input() requestID!: number;
  @Input() provincia!: string;
  @Output() dataUpdated = new EventEmitter<RequestNurseDetail>();


  form = new UntypedFormGroup({
    requestedExp: new UntypedFormGroup({
      requestedExpCluster1: new UntypedFormControl(false),
      requestedExpCluster2: new UntypedFormControl(false),
    }),
    requiredLanguage: new UntypedFormGroup({
      italian: new UntypedFormControl(false),
      english: new UntypedFormControl(false),
      /* french: new FormControl(false),
      german: new FormControl(false), */
      spanish: new UntypedFormControl(false)
    }),
    requestedAge: new UntypedFormGroup({
      requestedAgeCluster1: new UntypedFormControl(false),
      requestedAgeCluster2: new UntypedFormControl(false),
      requestedAgeCluster3: new UntypedFormControl(false)
    }),
    starting: new UntypedFormControl('', Validators.required),
    estimatedLasting: new UntypedFormControl('', Validators.required),
    serviceStart: new UntypedFormControl('', Validators.required),
    serviceDuration: new UntypedFormControl('', Validators.required),
    continuousJob: new UntypedFormControl(false, Validators.required),
    moreThanOne: new UntypedFormControl(false, Validators.required),
    badWorkHours: new UntypedFormControl(false, Validators.required),
    taxisitter: new UntypedFormControl(false),
    hoursAmount: new UntypedFormControl(0),
    netHourCost: new UntypedFormControl(0),
    flatCost: new UntypedFormControl(0),
    flatCostTiming: new UntypedFormControl(0),
    packageFlatCost: new UntypedFormControl(0),
    coliving: new UntypedFormControl(false),
    relocation: new UntypedFormControl(false),
    withholdingTax: new UntypedFormControl(false),
    days: new UntypedFormGroup({
      sun: new UntypedFormControl(false),
      mon: new UntypedFormControl(false),
      tue: new UntypedFormControl(false),
      wed: new UntypedFormControl(false),
      thu: new UntypedFormControl(false),
      fri: new UntypedFormControl(false),
      sat: new UntypedFormControl(false),
    }),
    hours: new UntypedFormGroup({
      mor: new UntypedFormControl(false),
      aft: new UntypedFormControl(false),
      eve: new UntypedFormControl(false),
    }),
    requestDescription: new UntypedFormControl('', Validators.required),
    dayShift: new UntypedFormControl(false),
    nightShift: new UntypedFormControl(false),
    changing: new UntypedFormControl(false),
    cleaning: new UntypedFormControl(false),
    hospital: new UntypedFormControl(false),
    taxi: new UntypedFormControl(false),
    taxiNoCar: new UntypedFormControl(false),
    walking: new UntypedFormControl(false),
    license: new UntypedFormControl(false),
    car: new UntypedFormControl(false),
    injections: new UntypedFormControl(false),
    dressings: new UntypedFormControl(false),
    glycemia: new UntypedFormControl(false),
    technology: new UntypedFormControl(false),
  })

  private unsubscribe = new Subject<void>();


  constructor(private utilities: RequestUtilitiesService, private nurseService: NurseService) { }

  ngOnInit(): void {
    this.initForm();
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((_) => {
        this.pushDataChanges();
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  updateNetHourPrice() {
    const formValue = this.form.value;
    this.form.get('netHourCost')?.setValue(
      this.utilities.computeNetPrice(this.provincia, formValue.moreThanOne, formValue.handicap, formValue.badWorkHours));
    this.pushDataChanges();
  }

  decreaseNetHourCostByOne() {
    const formControl = this.form.get('netHourCost');
    if (formControl) {
      const value = formControl.value;
      if (value > 1) {
        formControl.setValue(value - 1);
      } else {
        formControl.setValue(0);
      }
    }
  }

  increaseNetHourCostByOne() {
    const formControl = this.form.get('netHourCost');
    if (formControl) {
      const value = formControl.value;
      if (value >= 0) {
        formControl.setValue(value + 1);
      } else {
        formControl.setValue(1);
      }
    }
  }

  decreaseFlatCostByOne() {
    const formControl = this.form.get('flatCost');
    if (formControl) {
      const value = formControl.value;
      if (value > 1) {
        formControl.setValue(value - 1);
      } else {
        formControl.setValue(0);
      }
    }
  }

  increaseFlatCostByOne() {
    const formControl = this.form.get('flatCost');
    if (formControl) {
      const value = formControl.value;
      if (value >= 0) {
        formControl.setValue(value + 1);
      } else {
        formControl.setValue(1);
      }
    }
  }

  private initForm() {
    this.form.setValue({
      starting: this.inputData.starting,
      estimatedLasting: this.inputData.estimatedLasting,
      serviceStart: this.inputData.serviceStart,
      serviceDuration: this.inputData.serviceDuration,
      continuousJob: this.inputData.continuousJob,
      moreThanOne: this.inputData.moreThanOne,
      badWorkHours: this.inputData.badWorkHours,
      taxisitter: this.inputData.taxisitter,
      hoursAmount: this.inputData.hoursAmount,
      netHourCost: this.inputData.netHourCost,
      flatCost: this.inputData.flatCost,
      flatCostTiming: this.inputData.flatCostTiming,
      packageFlatCost: this.inputData.packageFlatCost,
      coliving: this.inputData.coliving,
      relocation: this.inputData.relocation,
      withholdingTax: this.inputData.withholdingTax,
      requestDescription: this.inputData.requestDescription,
      license: this.inputData.license,
      car: this.inputData.car,
      dayShift: this.inputData.dayShift,
      nightShift: this.inputData.nightShift,
      changing: this.inputData.changing,
      cleaning: this.inputData.cleaning,
      hospital: this.inputData.hospital,
      taxi: this.inputData.taxi,
      taxiNoCar: this.inputData.taxiNoCar,
      walking: this.inputData.walking,
      injections: this.inputData.injections,
      dressings: this.inputData.dressings,
      glycemia: this.inputData.glycemia,
      technology: this.inputData.technology,
      requestedExp: {
        requestedExpCluster1: this.inputData.requestedExp ? this.inputData.requestedExp.includes('Cuccioli') : false,
        requestedExpCluster2: this.inputData.requestedExp ? this.inputData.requestedExp.includes('Adulti') : false,
      },
      requestedAge: {
        requestedAgeCluster1: this.inputData.requestedAge ? this.inputData.requestedAge.includes('18-35') : false,
        requestedAgeCluster2: this.inputData.requestedAge ? this.inputData.requestedAge.includes('36-50') : false,
        requestedAgeCluster3: this.inputData.requestedAge ? this.inputData.requestedAge.includes('51+') : false
      },
      requiredLanguage: {
        italian: this.inputData.requiredLanguage ? this.inputData.requiredLanguage.includes('italian') : false,
        english: this.inputData.requiredLanguage ? this.inputData.requiredLanguage.includes('english') : false,
        /* french: this.inputData.requiredLanguage.includes('french'),
        german: this.inputData.requiredLanguage.includes('german'), */
        spanish: this.inputData.requiredLanguage ? this.inputData.requiredLanguage.includes('spanish') : false,
      },
      days: this.nurseService.fromStringToAvailObject(this.inputData.workDays, 'days'),
      hours: this.nurseService.fromStringToAvailObject(this.inputData.workHours, 'hours')
    });
  }

  private pushDataChanges() {
    const data = this.form.value;

    let requestedAge: string[] = [];
    Object.entries<boolean>(data.requestedAge).forEach((value) => {
      if (value[1]) {
        switch (value[0]) {
          case 'requestedAgeCluster1':
            requestedAge.push('18-35');
            break;

          case 'requestedAgeCluster2':
            requestedAge.push('36-50');
            break;

          case 'requestedAgeCluster3':
            requestedAge.push('51+');
            break;

          default:
            break;
        }
      }
    })

    let requestedExp: string[] = [];
    Object.entries<boolean>(data.requestedExp).forEach((value) => {
      if (value[1]) {
        switch (value[0]) {
          case 'requestedExpCluster1':
            requestedExp.push('Cuccioli');
            break;

          case 'requestedExpCluster2':
            requestedExp.push('Adulti');
            break;

          default:
            break;
        }
      }
    })

    let requiredLanguage: string[] = [];
    Object.entries<boolean>(data.requiredLanguage).forEach((value) => {
      if (value[1]) {
        requiredLanguage.push(value[0]);
      }
    })

    const newNurseInfo: RequestNurseDetail = {
      starting: data.starting,
      estimatedLasting: data.estimatedLasting,
      serviceStart: data.serviceStart,
      serviceDuration: data.serviceDuration,
      continuousJob: data.continuousJob,
      moreThanOne: data.moreThanOne,
      badWorkHours: data.badWorkHours,
      taxisitter: data.taxisitter,
      hoursAmount: data.hoursAmount,
      netHourCost: data.netHourCost,
      flatCost: data.flatCost,
      flatCostTiming: +data.flatCostTiming,
      packageFlatCost: data.packageFlatCost,
      coliving: data.coliving,
      relocation: data.relocation,
      withholdingTax: data.withholdingTax,
      requestDescription: data.requestDescription,
      requestedAge,
      requestedExp,
      workHours: this.nurseService.fromAvailObjectToString(data.hours, 'hours'),
      workDays: this.nurseService.fromAvailObjectToString(data.days, 'days'),
      car: data.car,
      license: data.license,
      walking: data.walking,
      cleaning: data.cleaning,
      nightShift: data.nightShift,
      dayShift: data.dayShift,
      changing: data.changing,
      hospital: data.hospital,
      taxi: data.taxi,
      taxiNoCar: data.taxiNoCar,
      injections: data.injections,
      dressings: data.dressings,
      glycemia: data.glycemia,
      technology: data.technology,
      requiredLanguage
    }
    this.dataUpdated.emit(newNurseInfo);
  }
}
