import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from 'npx-family-happy-common';
import { User } from 'src/app/shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  private baseURL = `${environment.requestServiceURL.split('/v1')[0]}/api/v1/user-controller`;

  constructor(private http: HttpClient) {
  }

  getUsersList(page: number, pageSize: number, filters?: { uid?: string, email?: string, phone?: string, name?: string }) {
    return this.http.get<Page<User>>(`${this.baseURL}/`, { params: { ...filters, page, pageSize } });
  }

  createUser(user: User) {
    const body = {
      email: user.email,
      name: user.name,
      phone: user.phone,
      surname: user.surname,
      uid: user.uid
    }
    return this.http.post<User>(`${this.baseURL}/`, body, {});
  }

  getSingleUser(uid: string) {
    return this.http.get<User>(`${this.baseURL}/${uid}`, {});
  }

  deleteUser(uid: string) {
    return this.http.delete<string>(`${this.baseURL}/${uid}`, {});
  }

  changeUserRole(uid: string, role: string) {
    return this.http.patch<string>(`${this.baseURL}/${uid}?newRole=${role}`, {});
  }

  changeUserName(uid: string, name: string, surname: string) {
    return this.http.patch<string>(`${this.baseURL}/${uid}/name`, {}, { params: { newName: name, newSurname: surname } });
  }
}
