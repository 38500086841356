import { CaregiverEvaluationRecord } from 'src/app/shared/models/caregiver-evaluation-record.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page } from 'npx-family-happy-common';

@Injectable({
  providedIn: 'root'
})
export class EvaluationService {
  private evaluationHistorySubject = new Subject<Page<CaregiverEvaluationRecord>>();
  private baseURL = environment.caregiverEvaluationURL;

  constructor(private http: HttpClient) { }

  addRecord(data: CaregiverEvaluationRecord) {
    return this.http.post<CaregiverEvaluationRecord>(`${this.baseURL}/${data.docRef}`, data).pipe(tap((result) => {
      this.getEvaluationHistory(data.docRef, 0, 10).subscribe();
    }));
  }

  getEvaluationHistory(docRef: string, page: number, pageSize: number): Observable<Page<CaregiverEvaluationRecord>> {
    this.http.get<Page<CaregiverEvaluationRecord>>(`${this.baseURL}/${docRef}?page=${page}&size=${pageSize}`).subscribe((data) => this.evaluationHistorySubject.next(data));
    return this.evaluationHistorySubject.asObservable();
  }
}
