<div class="filters">
  <p class="filter-title">Filtra per:</p>
  <form [formGroup]="form">
    <div class="mb-2 ms-2 me-2">
      <label class="me-2" for="status">Indirizzo:</label>
      <input type="text" formControlName="address" placeholder="inserisci indirizzo" id="address">
    </div>
    <div class="mb-2 ms-2 me-2">
      <label class="me-2" for="status">Distanza:</label>
      <input type="number" formControlName="range" placeholder="distanza in KM">
    </div>
    <div class="d-flex gap-2 mt-2">
      <button id="button" (click)="sendCoordinates()" class="my-button m-auto">seleziona</button>
      <button id="button" (click)="resetCoordinates()"class="my-button m-auto">RESET</button>
    </div>
  </form>
</div>
