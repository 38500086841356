import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ModalService } from 'npx-family-happy-common';
import { map, Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {

  constructor(private authService: AuthService, private router: Router, private modalService: ModalService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.getToken().pipe(switchMap((token) => {
      const authorized = this.authService.isUserAdmin(token);
      if (!authorized) {
        this.showErrorToast(`Questa sezione è accessibile solo agli utenti autorizzati. Se pensi ci possa essere un errore, contatta l'amministratore di sistema.`, 'Attenzione!')
        this.router.navigate(['/request-list']);
        return of(false);
      } else {
        return of(true);
      }
    }));
  }

  private showErrorToast(message: string, title: string) {
    this.modalService.showErrorMessage(`${title} - ${message}`);
  }
}
