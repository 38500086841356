import { ScheduledAppointment, ScheduledAppointmentType } from './../../models/scheduled-appointment.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ModalService } from 'npx-family-happy-common';
import { environment } from 'src/environments/environment';
import { CaregiverEvaluation } from '../../models/caregiver-evaluation.model';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { EvaluationHistoryComponent } from '../evaluation-history/evaluation-history.component';
import { CaregiverScoreComponent } from '../caregiver-score/caregiver-score.component';
import { ScheduledAppointmentService } from '../../services/scheduled-appointment-service/scheduled-appointment.service';

@Component({
  selector: 'app-caregiver-evaluation',
  templateUrl: './caregiver-evaluation.component.html',
  styleUrls: ['./caregiver-evaluation.component.scss'],
  standalone:true,
  imports:[CommonModule,ReactiveFormsModule, EvaluationHistoryComponent, CaregiverScoreComponent, FormsModule]
})
export class CaregiverEvaluationComponent implements OnInit, OnDestroy {
  @Input() docRef!: string;
  @Input() rating!: CaregiverEvaluation;
  @Output() dataUpdated = new EventEmitter<CaregiverEvaluation>();

  form = new UntypedFormGroup({
    overall: new UntypedFormControl(0, [Validators.min(0), Validators.max(10)]),
    punctuality: new UntypedFormControl(0, [Validators.min(0), Validators.max(10)]),
    empathy: new UntypedFormControl(0, [Validators.min(0), Validators.max(10)]),
    behave: new UntypedFormControl(0, [Validators.min(0), Validators.max(10)]),
    communication: new UntypedFormControl(0, [Validators.min(0), Validators.max(10)]),
    attitude: new UntypedFormControl(''),
    dangerous: new UntypedFormControl(false),
    notRated: new UntypedFormControl(false)
  })

  scheduledAppointment?: ScheduledAppointment;

  appointmentDate = '';

  private unsubscribe = new Subject<void>();

  constructor(private modalService: ModalService, private scheduledAppointmentService: ScheduledAppointmentService) { }

  ngOnInit(): void {
    this.form.setValue({
      ...this.rating
    });
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((_) => {
        this.dataUpdated.emit(this.form.value);
      });
    this.scheduledAppointmentService.getScheduledAppointmentsByEntityLinked(this.docRef).subscribe((appointments) => {
      if (appointments.length === 1) {
        this.scheduledAppointment = appointments[0];
        this.appointmentDate = this.scheduledAppointment.timestamp;
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  generateFormLink() {
    let formLink = environment.formBaseURL + '/test-psico-attitudinale?id=' + this.docRef;
    this.modalService.showSuccessMessage(`Link copiato con successo!<br> ${formLink}`);
    navigator.clipboard.writeText(formLink);
  }

  saveAppointment() {
    if (!this.scheduledAppointment) {
      this.scheduledAppointment = {
        entityLinked: this.docRef,
        timestamp: this.appointmentDate,
        type: ScheduledAppointmentType.CAREGIVER_INTERVIEW
      };
    }
    let obs: Observable<ScheduledAppointment>;
    if (this.scheduledAppointment.id) {
      this.scheduledAppointment.timestamp = this.appointmentDate;
      // Update appointment in database
      obs = this.scheduledAppointmentService.updateScheduledAppointment(this.scheduledAppointment.id, this.scheduledAppointment!)
    } else {
      // Create appointment in database
      obs = this.scheduledAppointmentService.createScheduledAppointment(this.scheduledAppointment!);
    }
    obs.subscribe((appointment) => {
      const reminderDate = new Date(new Date(appointment.timestamp).getTime() - 2 * 3600 * 1000);
      // Create task to send reminder
      this.scheduledAppointmentService.createAppointmentTask(appointment, reminderDate).subscribe(() => {
        this.modalService.showSuccessMessage('Promemoria impostato con successo!');
      }, () => {
        this.modalService.showErrorMessage('Non è stato possibile impostare il promemoria. Si è verificato un errore imprevisto.');
      });
    })
  }
}
