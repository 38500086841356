<div class="container p-3">
  <div>
    <h2 class="text-center text-primary">E-Mail Templates</h2>
    <h4 class="text-center pointer" (click)="collapse.toggle()">
      Filtri<i
        class="bi ms-2"
        style="font-size: 16px"
        [ngClass]="isCollapsed ? 'bi-caret-down-fill' : 'bi-caret-up-fill'"
      ></i>
    </h4>
    <form
      class="row m-3 p-3 bg-info rounded"
      [formGroup]="filters"
      #collapse="ngbCollapse"
      [(ngbCollapse)]="isCollapsed"
    >
      <div class="col-6 mb-3">
        <label for="service" class="form-label">Seleziona Servizio</label>
        <app-fh-service-select
          [serviceID]="serviceIDfilter"
          [value]="filters.get('service')?.value"
          (serviceSelected)="updateFHService($event)"
        ></app-fh-service-select>
      </div>
      <div class="col-6 mb-3">
        <label for="type" class="form-label">Seleziona Tipo Template</label>
        <select class="form-select" id="type" formControlName="type">
          <option value="">Seleziona tutti</option>
          <option value="QUOTATION">Preventivo</option>
          <option value="PROFILES">Profilo</option>
          <option value="CONTACTS">Contatto</option>
        </select>
      </div>
      <button
        class="col-3 btn btn-primary m-auto text-light"
        (click)="collapse.toggle(); applyFilters()"
      >
        Applica
      </button>
      <button class="col-3 btn btn-secondary m-auto text-light" (click)="collapse.toggle(); resetFilters()">reset</button>
    </form>

    <div class="d-flex flex-row-reverse gap-2">
      <button class="btn btn-info" (click)="createMailTemplate()"><i class="bi bi-plus-square"></i></button>
    </div>

    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Soggetto</th>
          <th scope="col">Lingua</th>
          <th scope="col">Nome Servizio</th>
          <th scope="col">Business Line</th>
          <th scope="col">Tipo di cliente</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let mail of mails">
          <th scope="row">{{ mail.id }}</th>
          <td>{{ mail.subject }}</td>
          <td>{{ mail.lang }}</td>
          <td>{{ mail.service.name }}</td>
          <td>{{ mail.service.businessLine }}</td>
          <td>{{ mail.service.customerType }}</td>
          <td>
            <a [routerLink]="['/emails', mail.id]" class="link-no-style">
            <button
              class="btn btn-primary px-2 py-1"
            >
              <i class="bi bi-search text-light pointer"></i>
            </button>
          </a>
          </td>
          <td>
            <button
              class="btn btn-danger px-2 py-1"
              (click)="deleteProduct(mail.id)"
            >
              <i class="bi bi-x-lg text-light pointer"></i>
            </button>
          </td>
        </tr>
        <tr *ngIf="mails.length === 0">
          <td colspan="10" class="text-center">
            Non sono stati trovati Mail Template.
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <p class="col">
        Sono presenti <b>{{ collectionSize }}</b> Email templates in totale.
      </p>
      <ngb-pagination
        class="col d-flex justify-content-end"
        *ngIf="mails.length > 0"
        [maxSize]="5"
        [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="collectionSize"
        (pageChange)="onPageChange()"
      ></ngb-pagination>
    </div>
  </div>
</div>

<app-alert *ngIf="showDeleteConfirm" (save)="confirmDelete($event)" [data]="{ type: undefined }"
  [title]="'Conferma eliminazione'" [message]="'Sei sicuro di voler eliminare il template ' + selectedMailID + '?'">
</app-alert>

