<div class="header-bar-container">
  <img src="/assets/logo_banner.png" alt="" class="pointer p-2" (click)="showMenu.emit()">
  <div style="align-items: center;">
    <label class="label" for="provincia">Provincia</label>
    <app-province-filter [provincia]="provincia" [includeAll]="true" (valueChange)="updateProvincia($event)"></app-province-filter>
  </div>
  <div class="dropdown" ngbDropdown>
    <button class="btn text-light" style="font-size: 20px;" ngbDropdownToggle id="dropdownBasic1">
      <i class="bi bi-person-fill"></i>
    </button>
    <div class="p-2" ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <div class="d-flex flex-column gap-1 mb-2">
        <p class="m-0 fw-bold">{{user!.name}} {{user!.surname}}</p>
        <p style="font-size: 11px;">{{user!.email}}</p>
      </div>
      <div class="sign-out d-flex gap-2" (click)="editNameSurname()">
        <i class="bi bi-pencil-fill"></i>
        <p>Modifica Nome</p>
      </div>
      <hr class="m-1">
      <div class="sign-out d-flex gap-2" (click)="signOut()">
        <i class="bi bi-box-arrow-right"></i>
        <p>Esci</p>
      </div>
    </div>
  </div>
</div>

<app-alert *ngIf="showAlert" (save)="onAlertAction($event)" [data]="editForm.value"></app-alert>
