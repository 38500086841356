import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { ProductService } from '../../../services/product-service/product.service';
import { Product } from 'npx-family-happy-common';
import { CommonModule } from '@angular/common';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, NgbTypeaheadModule]
})
export class ProductSelectComponent implements OnInit {

  @Input() set value(value: string) {
    this.form.controls.productControl.setValue(value);
  };

  @Input() set productID(productID:number | null) {
    if(productID !== undefined && productID !== null){
      this.productService.getProductByID(productID).subscribe((product) => {
        this.form.get('productControl')?.setValue(product);
      })
    } else {
      this.form.get('productControl')?.setValue(null);
    }
  }
  @Output() productSelected = new EventEmitter<Product>();

  form = new UntypedFormGroup({
    productControl: new UntypedFormControl(null, [Validators.required])
  })

  formInvalid = false

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
  }

  productSearch = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((searchKey) => {
        return this.productService.searchProduct(0, 10, searchKey).pipe(map((page) => page.content));
      }),
    );
  }

  formatter = (product: Product) => {
    return product.name;
  }

  onProductSelected(event: any) {
    const product = event.item;
    this.productSelected.emit(product);
  }

  resetSelection(){
    this.form.controls.productControl.reset();
    this.productSelected.emit(undefined);
  }

  validatorErrorMessage(fieldName:string){
    const field = this.form.get(fieldName);
    let message = ''
    if(field?.errors){
      if(field?.errors['required']){
        message = `Questo campo è obbligatorio`
      }
    }
    return message
  }
}
