import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FHService, ModalService } from 'npx-family-happy-common';
import { productRow } from 'src/app/shared/models/products.models';
import { Router, RouterModule } from '@angular/router';
import { Subject } from 'rxjs';
import { ProductServiceDashboard } from '../../product-services/product-services';
import { CommonModule } from '@angular/common';
import { NgbCollapseModule, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
import { FHServiceSelectComponent } from 'src/app/shared/components/custom-select/fh-service-select/fh-service-select.component';

@Component({
  selector: 'app-product-dashboard',
  templateUrl: './product-dashboard.component.html',
  styleUrls: ['./product-dashboard.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, NgbCollapseModule, RouterModule, NgbPagination, AlertComponent, FHServiceSelectComponent]
})
export class ProductDashboardComponent implements OnInit, OnDestroy {

  filters = new UntypedFormGroup({
    searchKey: new UntypedFormControl(null),
    serviceID: new UntypedFormControl(null)
  })

  isCollapsed: boolean = true;
  products: Array<productRow> = []
  collectionSize: number = 0
  showDeleteConfirm: boolean = false
  selectedProductID: number = -1
  serviceIDfilter!: number | null

  page: number = 1
  pageSize: number = 10;
  private unsubscribe = new Subject<void>();


  constructor(private productService: ProductServiceDashboard, private router: Router, private modalService: ModalService) {
    this.updateFilters()
   }

  ngOnInit(): void {
    this.loadProducts()
  }

  openProductDetail(id: number) {
    this.router.navigate(['/products/' + id]);
  }

  deleteProduct(id: number) {
    this.selectedProductID = id;
    this.showDeleteConfirm = true;
  }

  confirmDelete(answer: boolean) {
    if (answer && this.selectedProductID > -1) {
      this.productService.deleteProductById(this.selectedProductID).subscribe(() => {
        this.modalService.showSuccessMessage('Prodotto eliminato');
        this.loadProducts();
      });
    }
    this.showDeleteConfirm = false;
  }
  createProduct() {
    this.router.navigate(['/products/create']);
  }
  applyFilters() {
    this.productService.setFilters(this.filters.value)
    this.loadProducts();
  }

  onPageChange() {
    if (this.page > 0) {
      this.loadProducts();
    }
  }

  updateFHService(service: FHService) {
    if (service) {
      this.filters.get('serviceID')?.setValue(service.id)
    } else {
      this.filters.get('serviceID')?.setValue(null);
    }
  }

  updateFilters(){
    this.productService.getFilters().subscribe((filters) => {
      this.serviceIDfilter = filters.serviceID
      this.filters.setValue({
        searchKey: filters.searchKey,
        serviceID: filters.serviceID
      })
    })
  }

  resetFilters(){
    this.filters.setValue({
      searchKey: null,
      serviceID: null
    })
    this.serviceIDfilter = null
    this.productService.setFilters(this.filters.value)
    this.loadProducts();
  }

  private loadProducts() {
    this.productService.getAllProducts(this.page - 1, this.pageSize, this.filters.value).subscribe((productsPage) => {
      this.products = productsPage.content.map((item) => ({ ...item, selected: false }));
      this.page = productsPage.number + 1;
      this.pageSize = productsPage.size;
      this.collectionSize = productsPage.totalElements;
    })
  }


  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
