import { LoadBalanceDashboardComponent } from './backoffice/load-balance/load-balance-dashboard/load-balance-dashboard.component';
import { CvTemplateWrapperComponent } from './caregiver/babysitter/cv-template-wrapper/cv-template-wrapper.component';
import { LOCALE_ID, NgModule } from '@angular/core';
// import { BrowserModule, platformBrowser } from '@angular/platform-browser';
import { LoginComponent } from './authentication/login/login.component';
import { RequestListComponent } from './requests-handling/request-list/request-list.component';
import { RequestDetailComponent } from './requests-handling/request-detail/request-detail.component';
import { RequestMessagesComponent } from './requests-handling/request-messages/request-messages.component';
import { AlertComponent } from './shared/components/modals/alert/alert.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BabySitterListComponent } from './caregiver/babysitter/baby-sitter-list/baby-sitter-list.component';
import { BabySitterDetailComponent } from './caregiver/babysitter/baby-sitter-detail/baby-sitter-detail.component';
import { CaregiverChipComponent } from './shared/components/caregiver-chip/caregiver-chip.component';
import { BabysitterFilterModalComponent } from './shared/components/modals/babysitter-filter-modal/babysitter-filter-modal.component';
import { HeaderBarComponent } from './shared/components/header-bar/header-bar.component';
import { ImageCropperComponent } from './shared/components/image-cropper/image-cropper.component';
import { LoadingModalComponent } from './shared/components/modals/loading-modal/loading-modal.component';
import { OutcomeModalComponent } from './shared/components/modals/outcome-modal/outcome-modal.component';
import {  registerLocaleData } from '@angular/common';
import { UserManagementComponent } from './backoffice/user-management/user-management.component';
import { SmartphoneContainerComponent } from './requests-handling/smartphone-container/smartphone-container.component';
import { WhatsappMessageComposerComponent } from './requests-handling/whatsapp-message-composer/whatsapp-message-composer.component';
import { PetsitterListComponent } from './caregiver/petsitter/petsitter-list/petsitter-list.component';
import { PetsitterDetailComponent } from './caregiver/petsitter/petsitter-detail/petsitter-detail.component';
import { PetsitterFilterModalComponent } from './caregiver/petsitter/petsitter-filter-modal/petsitter-filter-modal.component';
import { CvPetsitterTemplateWrapperComponent } from './caregiver/petsitter/cv-petsitter-template-wrapper/cv-petsitter-template-wrapper.component';
import { CvPetsitterTemplateComponent } from './caregiver/petsitter/cv-petsitter-template/cv-petsitter-template.component';
import { CaregiverScoreComponent } from './shared/components/caregiver-score/caregiver-score.component';
import { RequestPersonalInfoComponent } from './requests-handling/request-personal-info/request-personal-info.component';
import { RequestUpdatesComponent } from './requests-handling/request-updates/request-updates.component';
import { RequestOperationalInfoComponent } from './requests-handling/request-operational-info/request-operational-info.component';
import { RequestBabysitterDetailFormComponent } from './requests-handling/request-babysitter-detail-form/request-babysitter-detail-form.component';
import { RequestPetsitterDetailFormComponent } from './requests-handling/request-petsitter-detail-form/request-petsitter-detail-form.component';
import { CvNurseTemplateComponent } from './caregiver/nurse/cv-nurse-template/cv-nurse-template.component';
import { NurseListComponent } from './caregiver/nurse/nurse-list/nurse-list.component';
import { NurseDetailComponent } from './caregiver/nurse/nurse-detail/nurse-detail.component';
import { CvNurseTemplateWrapperComponent } from './caregiver/nurse/cv-nurse-template-wrapper/cv-nurse-template-wrapper.component';
import { NurseFilterModalComponent } from './caregiver/nurse/nurse-filter-modal/nurse-filter-modal.component';
import { RequestNurseDetailFormComponent } from './requests-handling/request-nurse-detail-form/request-nurse-detail-form.component';
import { EvaluationHistoryComponent } from './shared/components/evaluation-history/evaluation-history.component';
import { CaregiverEvaluationComponent } from './shared/components/caregiver-evaluation/caregiver-evaluation.component';
import { CaregiverEngagementComponent } from './shared/components/caregiver-engagement/caregiver-engagement.component';
import { SideMenuComponent } from './shared/components/side-menu/side-menu.component';
import { PaymentDashboardComponent } from './backoffice/payments/payment-dashboard/payment-dashboard.component';
import { SellerDashboardComponent } from './sellers/seller-dashboard/seller-dashboard.component';
import { OperatorFilterComponent } from './shared/components/custom-select/operator-filter/operator-filter.component';
import { ProductSelectComponent } from './shared/components/custom-select/product-select/product-select.component';
import { FHServiceSelectComponent } from './shared/components/custom-select/fh-service-select/fh-service-select.component';
import { CreatePaymentLinkComponent } from './sellers/create-payment-link/create-payment-link.component';
import { PossiblePayerSelectComponent } from './shared/components/custom-select/possible-payer-select/possible-payer-select.component';
import { PaymentStatusBadgeComponent } from './shared/components/payment-status-badge/payment-status-badge.component';
import { ServicesDashboardComponent } from './backoffice/services/services-dashboard/services-dashboard.component';
import { ProductDashboardComponent } from './backoffice/products/product-dashboard/product-dashboard/product-dashboard.component';
import { ProductDetailComponent } from './backoffice/products/product-detail/product-detail/product-detail.component';
import { ServiceDetailComponent } from './backoffice/services/service-detail/service-detail/service-detail.component';
import { EmailDashboardComponent } from './backoffice/email/email-dashboard/email-dashboard.component';
import { EmailDetailComponent } from './backoffice/email/email-detail/email-detail.component';
import { VariableMailTemplateComponent } from './shared/components/modals/variable-mail-template/variable-mail-template.component';
import { MailVariableTooltipComponent } from './shared/components/mail-variables-tooltip/mail-variables-tooltip.component';
import { TextEditorComponent } from './shared/components/text-editor/text-editor.component';
import { PricesDashboardComponent } from './backoffice/prices/prices-dashboard/prices-dashboard.component';
import { PriceDetailComponent } from './backoffice/prices/price-detail/price-detail.component';
import { CompatibilityIndexComponent } from './requests-handling/caregiver-scoreboard/compatibility-index/compatibility-index.component';
import { CaregiverCardComponent } from './requests-handling/caregiver-scoreboard/caregiver-card/caregiver-card.component';
import { CaregiverSelectionComponent } from './requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection.component';
import { ProvinceSelectComponent } from './shared/components/province-select/province-select.component';
import { SelectCaregiverModalComponent } from './shared/components/modals/select-caregiver-modal/select-caregiver-modal.component';
import { DiscardedAlertComponent } from './shared/components/modals/discarded-alert/discarded-alert.component';
import { CaregiverSubscriptionDashboardComponent } from './sellers/caregiver-subscription/caregiver-subscription-dashboard/caregiver-subscription-dashboard.component';
import { ColfListComponent } from './caregiver/colf/colf-list/colf-list.component';
import { ColfDetailComponent } from './caregiver/colf/colf-detail/colf-detail.component';
import { ColfFilterModalComponent } from './caregiver/colf/colf-filter-modal/colf-filter-modal.component';
import { RequestColfDetailFormComponent } from './requests-handling/request-colf-detail-form/request-colf-detail-form.component';
import { CvColfTemplateComponent } from './caregiver/colf/cv-colf-template/cv-colf-template.component';
import { CvColfTemplateWrapperComponent } from './caregiver/colf/cv-colf-template-wrapper/cv-colf-template-wrapper.component';
import { MapComponent } from './shared/components/map/map/map.component';
import { DistanceSelectionFilterComponent } from './shared/components/distance-selection-filter/distance-selection-filter.component';
import { InputMapComponent } from './shared/components/input-map/input-map.component';

import localeIt from '@angular/common/locales/it';
import { ToastComponent } from './shared/components/toast/toast.component';

registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    // ClickOutsideDirective,
    // AppComponent
  ],
  imports: [
    // BrowserModule,
    // AppRoutingModule,
    // ReactiveFormsModule,
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    // QuillModule.forRoot(),
    // BrowserAnimationsModule,
    // MaterialModule,
    // HttpClientModule,
    // AngularEditorModule,
    // FormsModule,
    // DragDropModule,

    // AngularFirestoreModule,
    // AngularFireStorageModule,
    // NpxFamilyHappyCommonModule,

    // NgbTooltipModule,
    // NgbDropdownModule,
    // NgbPaginationModule,
    // NgbCollapseModule,
    // NgbTypeaheadModule,

    //STANDALONE COMPONENTS
    // AppComponent,
    LoginComponent,
    HeaderBarComponent,
    SideMenuComponent,
    MapComponent,
    CaregiverCardComponent,
    CompatibilityIndexComponent,
    ServicesDashboardComponent,
    AlertComponent,
    ServiceDetailComponent,
    ProductDashboardComponent,
    ProductDetailComponent,
    EmailDashboardComponent,
    EmailDetailComponent,
    PaymentDashboardComponent,
    PricesDashboardComponent,
    PriceDetailComponent,
    UserManagementComponent,
    BabySitterListComponent,
    BabySitterDetailComponent,
    PetsitterListComponent,
    PetsitterDetailComponent,
    NurseListComponent,
    NurseDetailComponent,
    ColfListComponent,
    ColfDetailComponent,
    RequestListComponent,
    RequestDetailComponent,
    RequestPersonalInfoComponent,
    RequestBabysitterDetailFormComponent,
    RequestPetsitterDetailFormComponent,
    RequestNurseDetailFormComponent,
    RequestColfDetailFormComponent,
    RequestOperationalInfoComponent,
    RequestMessagesComponent,
    RequestUpdatesComponent,
    CaregiverSelectionComponent,
    SelectCaregiverModalComponent,
    TextEditorComponent,
    VariableMailTemplateComponent,
    MailVariableTooltipComponent,
    FHServiceSelectComponent,
    PaymentStatusBadgeComponent,
    OperatorFilterComponent,
    ProvinceSelectComponent,
    ProductSelectComponent,
    InputMapComponent,
    BabysitterFilterModalComponent,
    PetsitterFilterModalComponent,
    NurseFilterModalComponent,
    ColfFilterModalComponent,
    CaregiverEvaluationComponent,
    EvaluationHistoryComponent,
    CaregiverScoreComponent,
    CaregiverEngagementComponent,
    ImageCropperComponent,
    CvPetsitterTemplateComponent,
    CvNurseTemplateComponent,
    CvColfTemplateComponent,
    DiscardedAlertComponent,
    WhatsappMessageComposerComponent,
    SmartphoneContainerComponent,
    CaregiverSubscriptionDashboardComponent,
    PossiblePayerSelectComponent,
    SellerDashboardComponent,
    DistanceSelectionFilterComponent,
    CreatePaymentLinkComponent,
    CaregiverChipComponent, //UNUSED
    ToastComponent,
    OutcomeModalComponent,
    LoadingModalComponent,
    CvTemplateWrapperComponent,
    CvPetsitterTemplateWrapperComponent,
    CvColfTemplateWrapperComponent,
    CvNurseTemplateWrapperComponent,
    LoadBalanceDashboardComponent
  ],
  providers: [
    // BabySitterService,
    // NgbActiveModal,
    // AngularEditorService,
    // DatePipe,
    // TitleCasePipe,
    // CaregiverCardDatePipe,
    // CaregiverCardDistancePipe,
    // UpperCasePipe,
    // FhDatePipe,
    // GoogleMap,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CoreHttpInterceptor,
    //   multi: true
    // },
    // { provide: LOCALE_ID, useValue: 'it' },
    // [NgbTypeaheadConfig]
  ],
})

export class AppModule { }
