<div class="container p-3">
  <h2 class="text-center">Genera link di pagamento</h2>
  <p>Seleziona il servizio per il quale vuoi generare un link di pagamento, seleziona il venditore, inserisci l'email del pagante, inserisci
    l'importo e genera il link.</p>
  <p>A questo punto, sei pronto per inviare il link di pagamento al pagante.</p>
  <form class="row bg-light p-3 my-5 rounded">
    <div class="col-6 mb-3">
      <label class="form-label">Servizio</label>
      <app-fh-service-select (serviceSelected)="updateService($event)"></app-fh-service-select>
    </div>
    <div class="col-6 mb-3">
      <label class="form-label">Venditore</label>
      <app-operator-filter [includeRecruiter]="true" [operator]="null"
        (valueChange)="updateSeller($event)"></app-operator-filter>
    </div>
    <div class="col-6 mb-3">
      <app-possible-payer-select (payerSelected)="updatePayer($event)"></app-possible-payer-select>
    </div>
    <div class="col-6 mb-3">
      <label for="amount" class="form-label">Importo</label>
      <input class="form-control" type="number" name="" id="" [formControl]="amount">
    </div>
    <button class="btn btn-primary text-light m-auto mt-2" style="max-width: 300px;" [disabled]="!this.formValid"
      (click)="generatePaymentLink()">Genera</button>
  </form>
  <div class="mt-3" *ngIf="paymentURL">
    <p>Ecco il tuo <b>link di pagamento</b>:</p>
    <div class="d-flex">
      <input type="text" class="form-control" [value]="paymentURL" readonly>
      <button class="btn btn-info input-end-button"><i class="bi bi-clipboard" (click)="copyToClipboard()"></i></button>
    </div>
  </div>
</div>
