<div class="container p-3">
  <div>
    <h2 class="text-center text-primary">Lista Caregivers</h2>
    <h4 class="text-center pointer" (click)="collapse.toggle()">
      Filtri<i
        class="bi ms-2"
        style="font-size: 16px"
        [ngClass]="isCollapsed ? 'bi-caret-down-fill' : 'bi-caret-up-fill'"
      ></i>
    </h4>
    <form
      class="row m-3 p-3 bg-info rounded"
      [formGroup]="filters"
      #collapse="ngbCollapse"
      [(ngbCollapse)]="isCollapsed"
    >
      <div class="col-6 mb-3">
        <label for="businessLine" class="form-label">Business Line</label>
        <select
          class="form-select"
          aria-label="Default select example"
          id="businessLine"
          formControlName="businessLine"
        >
          <option value="NURSE">NURSE</option>
          <option value="BABYSITTER" selected>BABYSITTER</option>
          <option value="PETSITTER">PETSITTER</option>
        </select>
      </div>
      <button
        class="col-3 btn btn-primary m-auto text-light"
        (click)="collapse.toggle(); applyFilters()"
      >
        Applica
      </button>
    </form>

    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <!-- <th></th> -->
          <th scope="col">Nome</th>
          <th scope="col">Cognome</th>
          <th scope="col">Email</th>
          <th scope="col">Telefono</th>
          <th scope="col">Data Candidatura</th>
          <th scope="col">DocRef</th>
          <th scope="col">ID Richiesta</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let caregiver of caregiverPage">
          <td>{{ caregiver.name }}</td>
          <td>{{ caregiver.surname }}</td>
          <td>{{ caregiver.email }}</td>
          <td><strong>{{ caregiver.phone }}</strong></td>
          <td>{{ caregiver.applicationDate | date: 'dd/MM/yyyy'}}</td>
          <td><a [href]="caregiver.docRefLink" target="_blank">{{ caregiver.docRef }}</a></td>
          <td><a [href]="caregiver.requestIDLink" target="_blank">{{ caregiver.requestID }}</a></td>
        </tr>
        <tr>
          <td *ngIf="caregiverPage.length === 0" colspan="10" class="text-center">
            Non sono state trovate Caregiver.
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <p class="col">
        Sono presenti <b>{{ collectionSize }}</b> Caregivers in totale.
      </p>
      <ngb-pagination
        class="col d-flex justify-content-end"
        *ngIf="caregiverPage.length > 0"
        [maxSize]="5"
        [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="collectionSize"
        (pageChange)="onPageChange()"
      ></ngb-pagination>
    </div>
  </div>
</div>

