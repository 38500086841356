import { RequestNurseDetail } from 'npx-family-happy-common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestNurseService {
  private baseURL = environment.requestNurseServiceURL;

  constructor(private http: HttpClient) { }

  createNurseDetail(detail: RequestNurseDetail) {
    return this.http.post<RequestNurseDetail>(`${this.baseURL}`, detail);
  }

  getNurseDetail(requestID: number) {
    return this.http.get<RequestNurseDetail>(`${this.baseURL}/${requestID}`);
  }

  updateNurseDetail(requestID: number, detail: RequestNurseDetail) {
    return this.http.put<RequestNurseDetail>(`${this.baseURL}/${requestID}`, detail);
  }

  deleteNurseDetail(requestID: number) {
    return this.http.delete<RequestNurseDetail>(`${this.baseURL}/${requestID}`);
  }

}
