<div class="container p-3">
  <div class="d-flex justify-content-between">
    <div class="d-flex gap-2">
      <h2 class="bi bi-arrow-left pointer" (click)="goBackToPayments()"></h2>
      <h2 class="mb-3" *ngIf="!isNew">Pagamento: {{selectedPayment?.id}}</h2>
      <h2 class="mb-3" *ngIf="isNew">Registra Pagamento</h2>
    </div>
    <div class="d-flex gap-1">
      <h2 *ngIf="selectedPayment?.status === 'Completed'"><span class="badge bg-success">Completato</span></h2>
      <h2 *ngIf="selectedPayment?.status === 'Failed'"><span class="badge bg-danger">Fallito</span></h2>
      <h2 *ngIf="selectedPayment?.status === 'Incomplete'"><span class="badge bg-warning">Incompleto</span></h2>
      <h2 class="pe-3" *ngIf="selectedPayment?.invoiced"><span class="badge bg-success">Fatturato</span></h2>
      <h2 class="pe-3" *ngIf="!selectedPayment?.invoiced"><span class="badge bg-danger">Non Fatturato</span></h2>
    </div>
  </div>
  <form [formGroup]="form" class="p-3 mb-3 rounded">
    <div class="row">
      <div class="col-6 mb-3">
        <label for="emailPayer" class="form-label">Email Pagante</label>
        <input type="text" id="emailPayer" class="form-control" formControlName="emailPayer" [readonly]="!isNew">
      </div>
      <div class="col-6 mb-3">
        <label for="email" class="form-label">Email Piattaforma<span class="text-danger">*</span><i
            class="bi bi-info-circle-fill ms-2 text-primary" placement="top"
            ngbTooltip="Incolla l'indirizzo email della persona censita in piattaforma associata a questo pagamento."></i></label>
        <div class="d-flex position-relative">
          <input type="email" id="email" class="form-control" formControlName="email" [ngbTypeahead]="emailSearch"
            [resultTemplate]="resultRow" [inputFormatter]="formatter" (selectItem)="onEmailSelected($event)"
            autocomplete="hjl" [readonly]="form.value.urlRelated">
          <button class="btn btn-info input-end-button position-absolute" style="right: 0px;"
            (click)="openNewPageWithUrl()" *ngIf="form.value.urlRelated"><i
              class="bi bi-box-arrow-up-right"></i></button>
        </div>
        <p class="form-error" *ngIf="form.get('email')?.errors?.['required'] && form.get('email')?.touched">Il campo è
          obbligatorio.</p>
        <p class="form-error" *ngIf="form.get('email')?.errors?.['email'] && form.get('email')?.touched">L'indirizzo
          email
          fornito non è valido.</p>
      </div>
    </div>
    <fieldset class="row mb-3">
      <legend class="col-12 mb-3">Dati Fatturazione Cliente</legend>
      <div class="col-6 mb-3">
        <label for="name" class="form-label">Nome<span class="text-danger">*</span></label>
        <input type="text" id="name" class="form-control" formControlName="name">
        <p class="form-error" *ngIf="form.get('name')?.errors?.['required'] && form.get('name')?.touched">Il campo è
          obbligatorio.</p>
      </div>
      <div class="col-6 mb-3">
        <label for="surname" class="form-label">Cognome<span class="text-danger">*</span></label>
        <input type="text" id="surname" class="form-control" formControlName="surname">
        <p class="form-error" *ngIf="form.get('surname')?.errors?.['required'] && form.get('surname')?.touched">Il campo
          è
          obbligatorio.</p>
      </div>
      <div class="col-6 mb-3">
        <label for="fiscalCode" class="form-label">Codice Fiscale<span class="text-danger">*</span></label>
        <input type="text" id="fiscalCode" class="form-control" formControlName="fiscalCode">
        <p class="form-error" *ngIf="form.get('fiscalCode')?.errors?.['required'] && form.get('fiscalCode')?.touched">Il
          campo è
          obbligatorio.</p>
        <p class="form-error" *ngIf="form.get('fiscalCode')?.errors?.['pattern'] && form.get('fiscalCode')?.touched">Il
          codice fiscale inserito non è valido.</p>
      </div>
      <div class="col-6 mb-3">
        <label for="street" class="form-label">Via<span class="text-danger">*</span></label>
        <input type="text" id="street" class="form-control" formControlName="street">
        <p class="form-error" *ngIf="form.get('street')?.errors?.['required'] && form.get('street')?.touched">Il campo è
          obbligatorio.</p>
      </div>
      <div class="col-6 mb-3">
        <label for="city" class="form-label">Città<span class="text-danger">*</span></label>
        <input type="text" id="city" class="form-control" formControlName="city">
        <p class="form-error" *ngIf="form.get('city')?.errors?.['required'] && form.get('city')?.touched">Il campo è
          obbligatorio.</p>
      </div>
      <div class="col-6 mb-3">
        <label for="province" class="form-label">Provincia<span class="text-danger">*</span></label>
        <input type="text" id="province" class="form-control" formControlName="province">
        <p class="form-error" *ngIf="form.get('province')?.errors?.['required'] && form.get('province')?.touched">Il
          campo è
          obbligatorio.</p>
        <p class="form-error" *ngIf="form.get('province')?.errors?.['maxlength'] && form.get('province')?.touched">
          La provincia va inserita come sigla e non per esteso. (es. MI per Milano)</p>
      </div>
      <div class="col-6 mb-3">
        <label for="country" class="form-label">Stato<span class="text-danger">*</span></label>
        <input type="text" id="country" class="form-control" formControlName="country">
        <p class="form-error" *ngIf="form.get('country')?.errors?.['required'] && form.get('country')?.touched">Il campo
          è
          obbligatorio.</p>
      </div>
      <div class="col-6 mb-3">
        <label for="postalCode" class="form-label">CAP<span class="text-danger">*</span></label>
        <input type="text" id="postalCode" class="form-control" formControlName="postalCode">
        <p class="form-error" *ngIf="form.get('postalCode')?.errors?.['required'] && form.get('postalCode')?.touched">Il
          campo è obbligatorio.</p>
        <p class="form-error" *ngIf="form.get('postalCode')?.errors?.['pattern'] && form.get('postalCode')?.touched">Il
          CAP inserito non è valido.</p>
      </div>
      <div class="col-6 mb-3">
        <label for="phone" class="form-label">Cellulare</label>
        <input type="text" id="phone" class="form-control" formControlName="phone">
        <!-- <p class="form-error" *ngIf="form.get('phone')?.errors?.['required'] && form.get('phone')?.touched">Il campo
          è obbligatorio.</p> -->
      </div>
    </fieldset>
    <fieldset class="row mb-3">
      <legend class="col-12 mb-3">Dati pagamento</legend>
      <div class="col-6 mb-3">
        <label for="amount" class="form-label">Importo</label>
        <div class="d-flex">
          <button class="btn btn-info currency-label" [disabled]="true">€</button>
          <input type="number" step=".01" id="amount" class="form-control" formControlName="amount" [readonly]="!isNew">
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="timestamp" class="form-label">Data</label>
        <input type="datetime-local" id="timestamp" class="form-control" formControlName="timestamp"
          [readonly]="!isNew">
      </div>
      <div class="col-6 mb-3">
        <label for="stripeID" class="form-label">StripeID</label>
        <div class="d-flex">
          <input type="text" id="stripeID" class="form-control" formControlName="stripeID" [readonly]="!isNew">
          <button class="btn btn-info input-end-button" (click)="copyStripeID()"><i
              class="bi bi-clipboard"></i></button>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="subscriptionID" class="form-label">SubscripionID</label>
        <div class="d-flex">
          <input type="text" id="subscriptionID" class="form-control" formControlName="subscriptionID"
            [readonly]="!isNew">
          <button class="btn btn-info input-end-button" (click)="copySubscriptionID()"><i
              class="bi bi-clipboard"></i></button>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="seller" class="form-label">Venditore 1</label>
        <app-operator-filter [operator]="this.form.get('seller')?.value" [includeRecruiter]="true"
          (valueChange)="updateSeller($event, 'seller')"></app-operator-filter>
      </div>
      <div class="col-6 mb-3">
        <label for="seller2" class="form-label">Venditore 2</label>
        <app-operator-filter [operator]="this.form.get('seller2')?.value" [includeRecruiter]="true"
          (valueChange)="updateSeller($event, 'seller2')"></app-operator-filter>
      </div>
      <div class="col-6 mb-3">
        <label for="product" class="form-label">Servizio</label>
        <app-fh-service-select [value]="form.get('service')?.value"
          (serviceSelected)="updateFHService($event)"></app-fh-service-select>
      </div>
      <div class="col-6 mb-3">
        <label for="type" class="form-label">Tipo Pagamento</label>
        <select class="form-select" id="type" formControlName="type">
          <option>Vendita</option>
          <option>Rinnovo</option>
          <option>Rimborso</option>
        </select>
      </div>
      <div class="col-6 mb-3">
        <label for="couponCode" class="form-label">Codice Coupon</label>
        <input type="text" id="couponCode" class="form-control" formControlName="couponCode">
      </div>
      <div class="col-6 mb-3">
        <label for="promoCode" class="form-label">Codice Promozione</label>
        <input type="text" id="promoCode" class="form-control" formControlName="promoCode">
      </div>
      <div class="col-12 mb-3">
        <label for="extra" class="form-label">Note</label>
        <textarea id="extra" class="form-control" formControlName="extra"></textarea>
      </div>
    </fieldset>
  </form>
  <div class="d-flex flex-row-reverse gap-2">
    <button class="btn btn-primary text-light" (click)="savePayment()"><i class="bi bi-floppy-fill text-light"></i>
      Salva</button>
    <!-- <button class="btn btn-success text-light" (click)="emitInvoice()" *ngIf="!selectedPayment?.invoiced"><i
        class="bi bi-file-earmark-richtext-fill text-light"></i>
      Emetti Fattura</button> -->
    <button class="btn btn-success text-light" (click)="emitInvoice()" *ngIf="!selectedPayment?.invoiced"><i
        class="bi bi-file-earmark-richtext-fill text-light"></i>
      Segna come Fatturato</button>
    <button class="btn btn-danger text-light" (click)="deletePayment()"><i class="bi bi-x-lg text-light pointer"></i>
      Elimina</button>
  </div>
</div>

<app-alert *ngIf="showDeleteConfirm" (save)="confirmDelete($event)" [data]="{ type: undefined }"
  [title]="'Conferma eliminazione'"
  [message]="'Sei sicuro di voler eliminare il pagamento ' + selectedPayment?.id + '?'">
</app-alert>

<ng-template #resultRow let-r="result" let-t="term">
  <span class="me-2">{{r.type}} - {{r.docRef}}:</span>
  <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>
