import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page, Price, Product } from 'npx-family-happy-common';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

export const DEFAULT_PRICE_FILTERS: PriceFilters = {
  province: null,
  amount: null,
  stripeID: '',
  productID: null,
};

@Injectable({
  providedIn: 'root',
})
export class PriceService {

  private baseURL = environment.priceAPI;
  filters = new BehaviorSubject<PriceFilters>(DEFAULT_PRICE_FILTERS)

  constructor(private http: HttpClient) {}

  createPrice(body: PriceCreationBody) {
    return this.http.post<Price>(`${this.baseURL}/`, body);
  }

  updatePrice(price:Price){
    return this.http.put<Price>(`${this.baseURL}/`, price);
  }

  getPriceByID(id:number){
    return this.http.get<Price>(`${this.baseURL}/${id}`);
  }

  getAllPrices(page: number, pageSize = 20, filters = DEFAULT_PRICE_FILTERS) {
    const params = [
      `pageNumber=${page}`,
      `pageSize=${pageSize}`,
      `${this.convertFilterObjectToQueryParam(filters)}`,
    ].join('&');
    return this.http.get<Page<Price>>(`${this.baseURL}/list?${params}`);
  }

  deletePriceById(id:number){
    return this.http.delete<Price>(`${this.baseURL}/${id}`);
  }

  setFilters(filters:any){
    this.filters.next(filters)
  }

  getFilters(){
    return this.filters.asObservable()
  }


  //https://test.request.familyhappy.xyz/v1/prices/list?pageNumber=0&pageSize=20&productID=1
  private convertFilterObjectToQueryParam(filters: PriceFilters) {
    let queryString = '';
    Object.entries(filters).forEach((entry: [string, any | null]) => {
      if (
        entry[1] !== null &&
        ((typeof entry[1] === 'string' && entry[1] !== '') ||
          (typeof entry[1] === 'number' && entry[1] >= 0))
      ) {
        if (entry[0] === 'productID') {
          queryString += `${queryString === '' ? '' : '&'}${entry[0]}=${
            entry[1]
          }`;
        } else if (entry[0] === 'amount') {
          queryString += `${queryString === '' ? '' : '&'}${entry[0]}=${+entry[1]*100
          }`;
        } else if (entry[0] === 'province')
          queryString += `${queryString === '' ? '' : '&'}${entry[0]}=${
            encodeURIComponent(entry[1])
          }`;
      } else {
        queryString += `${queryString === '' ? '' : '&'}`;
      }
    });
    return queryString;
  }
}


export interface PriceFilters {
  province: string | null;
  amount: number | null;
  productID: number | null;
  stripeID: string | null;
}

export interface PriceCreationBody {
  stripeID: string;
  provinces: string[];
  amount: number;
  product: Product;
}
