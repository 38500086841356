import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from 'npx-family-happy-common';
import { LoadBalanceRecordInterface } from 'src/app/shared/models/request-loadBalance-record.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoadBalanceService {

  private baseUrl = environment.loadBalanceAPI;

  constructor(private http:HttpClient) { }

  getLoadBalance(){
    return this.http.get<Page<LoadBalanceRecordInterface>>(this.baseUrl)
  }

  updateVacation(record:LoadBalanceRecordInterface){
    let body = {
      id: record.id,
      vacation: record.onVacation
    }
    return this.http.patch<LoadBalanceRecordInterface>(`${this.baseUrl}${body.id}/vacation?vacation=${body.vacation}`, body)
  }

  updateWeight(record:LoadBalanceRecordInterface){
    let body = {
      id: record.id,
      weight: record.weight
    }
    return this.http.patch<LoadBalanceRecordInterface>(`${this.baseUrl}${body.id}/weight?weight=${body.weight}`, body)
  }
}
