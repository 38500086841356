import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CaregiverEngagement } from '../../models/caregiver-engagement.model';
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { CaregiverSelectionEngagementRecord } from '../../models/caregiver-selection-engagement-record.model';
import { CaregiverSelectionService } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/caregiver-selection.service';
import { Subject, takeUntil } from 'rxjs';
import { getURLRelated } from '../../utils.functions';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-caregiver-engagement',
  templateUrl: './caregiver-engagement.component.html',
  styleUrls: ['./caregiver-engagement.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, NgbPaginationModule]
})
export class CaregiverEngagementComponent implements OnInit, OnDestroy {
  @Input() engagement!: CaregiverEngagement;
  @Input() docRef!: string;

  filters = new UntypedFormGroup({
    phase: new UntypedFormControl('')
  })

  form = new UntypedFormGroup({
    selected: new UntypedFormControl(0),
    chosen: new UntypedFormControl(0),
    discarded: new UntypedFormControl(0),
    ignored: new UntypedFormControl(0),
    substituted: new UntypedFormControl(0),
    applications: new UntypedFormControl(0),
    chosenDate: new UntypedFormControl(''),
    latestApplication: new UntypedFormControl('')
  })

  engagedRequests: CaregiverSelectionEngagementRecord[] = [];

  page = 1;
  pageSize = 10;
  collectionSize = 0;
  isCollapsed = true
  private unsubscribe = new Subject<void>()

  constructor(private datePipe: DatePipe, private caregiverSelectionService: CaregiverSelectionService) { }

  ngOnInit(): void {
    this.loadEngagedRequests();
    this.caregiverSelectionService.getCaregiverStats(this.docRef).pipe(takeUntil(this.unsubscribe)).subscribe((stats) => {
      if (stats) {
        this.form.setValue({
          selected: stats.selected,
          chosen: stats.chosen,
          discarded: stats.discarded,
          ignored: stats.ignored,
          substituted: stats.replaced,
          applications: stats.applications,
          chosenDate: stats.latestChosen ? stats.latestChosen.toISOString().split('T')[0] : null,
          latestApplication: stats.latestApplication ? stats.latestApplication.toISOString().split('T')[0] : null
        })
      }
    });
  }

  onPageChange() {
    if (this.page > 0) {
      this.loadEngagedRequests();
    }
  }

  loadEngagedRequests() {
    if (this.docRef) {
      this.caregiverSelectionService.getEngagedRequestsByDocRef(this.page -1, this.pageSize, this.docRef, this.filters.value).pipe(takeUntil(this.unsubscribe))
        .subscribe((recordsPage) => {
          this.engagedRequests = recordsPage.content;
          this.page = recordsPage.number + 1;
          this.pageSize = recordsPage.size;
          this.collectionSize = recordsPage.totalElements;
        })
    }
  }

  applyFilters(){
    this.page = 1
    this.loadEngagedRequests();
  }
  openRequestPage(requestID: number) {
    return getURLRelated('REQUEST', `${requestID}`);
  }
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
