<div class="alert" *ngIf="messageType === 'Error'">
  <div class="alert-content">
    <img src="../../assets/icon/error-icon.svg" style="margin-bottom: 20px;"/>
    <p id="alert-content-message" [innerHTML]="message"></p>

    <div class="inline-flex-container">
      <span (click)="closeModal()">OK</span>
    </div>
  </div>
</div>

<div class="alert" *ngIf="messageType === 'Success'">
  <div class="alert-content">
    <img src="../../assets/icon/success-icon.svg" style="margin-bottom: 20px;"/>
    <p id="alert-content-message" [innerHTML]="message"></p>

    <div class="inline-flex-container">
      <span (click)="closeModal()">OK</span>
    </div>
  </div>
</div>
