import { CaregiverScoreRecord } from './../../models/caregiver-score-record.model';
import { Component, Input, OnInit } from '@angular/core';
import { CaregiverScore, CaregiverScoreType } from '../../models/caregiver-score.model';
import { CaregiverScoreService } from './caregiver-score.service';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-caregiver-score',
  templateUrl: './caregiver-score.component.html',
  styleUrls: ['./caregiver-score.component.scss'],
  standalone:true,
  imports:[CommonModule, MatExpansionModule, FormsModule]
})
export class CaregiverScoreComponent implements OnInit {
  @Input() docRef!: string;
  score!: CaregiverScore;
  scoreHistory!: CaregiverScoreRecord[];
  private page = 0;
  private pageSize = 10;
  private totalScoreHistoryRecords = 0;

  constructor(private service: CaregiverScoreService) { }

  ngOnInit(): void {
    this.getScoreInfo();
  }

  saveScore(index: number, pointsToAdd: number) {
    let newScore = 0;
    let type: CaregiverScoreType;
    switch (index) {
      case 0:
        type = CaregiverScoreType.SUBRENEWAL;
        newScore = this.score.subRenewal + pointsToAdd;
        break;
      case 1:
        type = CaregiverScoreType.CHOSEN;
        newScore = this.score.chosen + pointsToAdd;
        break;
      case 2:
        type = CaregiverScoreType.GOOD_BEHAVIOR;
        newScore = this.score.goodBehavior + pointsToAdd;
        break;
      case 3:
        type = CaregiverScoreType.PARENT_PAYMENT;
        newScore = this.score.parentPayment + pointsToAdd;
        break;
      case 4:
        type = CaregiverScoreType.DISOSTRUZIONE_CERTIFIED;
        newScore = this.score.disostuzioneCertified + pointsToAdd;
        break;
      case 5:
        type = CaregiverScoreType.REFERRAL;
        newScore = this.score.referral + pointsToAdd;
        break;
      case 6:
        type = CaregiverScoreType.REFERENCES;
        newScore = this.score.referenceScore + pointsToAdd;
        break;
      case 7:
        type = CaregiverScoreType.POST_SHARING;
        newScore = this.score.postSharing + pointsToAdd;
        break;
      case 8:
        type = CaregiverScoreType.GOOGLE_REVIEWS;
        newScore = this.score.googleReviews + pointsToAdd;
        break;
      default:
        return;
    }
    this.service.updateScore(this.docRef, newScore, type).subscribe((res) => this.getScoreInfo());
  }

  saveOffset() {
    this.service.updateScore(this.docRef, this.score.scoreOffset, CaregiverScoreType.OFFSET).subscribe(() => this.getScoreInfo());
  }

  updateTotal() {
    this.score.overall = this.score.chosen + this.score.disostuzioneCertified +
      this.score.googleReviews + this.score.parentPayment + this.score.postSharing + this.score.referenceScore +
      this.score.referral + this.score.scoreOffset;
  }

  getLabel(type: CaregiverScoreType) {
    let label = '';
    switch (type) {
      case CaregiverScoreType.SUBRENEWAL:
        label = 'Fedeltà F+H (3Pt per ogni rinnovo)';
        break;
      case CaregiverScoreType.CHOSEN:
        label = 'Professionale al Colloquio (2Pt ogni volta che la caregiver è stata scelta)';
        break;
      case CaregiverScoreType.GOOD_BEHAVIOR:
        label = `Buona Condotta (3Pt ogni volta che il genitore che l'ha scelta rinnova)`;
        break;
      case CaregiverScoreType.PARENT_PAYMENT:
        label = `Buona Condotta (3Pt ogni volta che il genitore che l'ha scelta rinnova)`;
        break;
      case CaregiverScoreType.DISOSTRUZIONE_CERTIFIED:
        label = 'Certificazione Disostruzione (2Pt)';
        break;
      case CaregiverScoreType.REFERRAL:
        label = `Porta un'amica o un genitore (5Pt a Babysitter/Genitore pagante)`;
        break;
      case CaregiverScoreType.REFERENCES:
        label = 'Referenze con n. di Tel da poter chiamare (2PT a referenza)';
        break;
      case CaregiverScoreType.POST_SHARING:
        label = 'Condivisione Post sui Social (2 Pt a Post)';
        break;
      case CaregiverScoreType.GOOGLE_REVIEWS:
        label = 'Recensioni Google (3Pt una recensione)';
        break;
      case CaregiverScoreType.OFFSET:
        label = 'Offset (Punteggio iniziale e correzioni)';
        break;
      default:
        return;
    }
    return label;
  }

  private getScoreInfo() {
    this.service.getScore(this.docRef).subscribe((score) => this.score = score);
    this.service.getScoreHistory(this.docRef, this.page, this.pageSize).subscribe((scoreHistory) => {
      this.scoreHistory = scoreHistory.content;
      this.totalScoreHistoryRecords = scoreHistory.totalElements;
    });
  }

}
