import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserStorageService {
  user: User | undefined;
  userObserver = new BehaviorSubject<User | undefined>(undefined);

  constructor() {
    /* Check if the user is already logged in */
    this.getUser();
  }

  /* Store user information in localStorage */
  setUser(user: User) {
    this.user = user;
    this.userChangedNotification();
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  /* Get user information from localStorage */
  getUser() {
    const obj = localStorage.getItem('user');
    if (obj) {
      const elem = JSON.parse(obj.toString());
      this.user = elem;
      this.userChangedNotification();
    }
    return this.userObserver;
  }

  /* Delete user information from localStorage */
  removeUserInfo() {
    localStorage.removeItem('user');
    this.user = undefined;
    this.userChangedNotification();
  }

  /* Delete all the data saved in localStorage */
  deleteData() {
    return localStorage.clear();
  }

  /* Notify all observers about user change */
  private userChangedNotification() {
    this.userObserver.next(this.user);
  }
}
