import { CommonModule } from '@angular/common';
import { GoogleMapsService } from './../../../requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/google-maps.service';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-distance-selection-filter',
  templateUrl: './distance-selection-filter.component.html',
  styleUrls: ['./distance-selection-filter.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule]
})
export class DistanceSelectionFilterComponent implements OnInit, AfterViewInit {

  @Output() coordinates = new EventEmitter<any>();
  @Input() modal!:any
  form = new UntypedFormGroup({
    address: new UntypedFormControl(null),
    range: new UntypedFormControl(null)
  })

  constructor(private googleMapsService:GoogleMapsService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    /* Google Maps */
    let inputAddress = document.getElementById('address') as HTMLInputElement;

    if (inputAddress) {
      inputAddress.addEventListener('change', () => {
        inputAddress.value = '';
        this.form.get('info.address')?.setValue('', { emitEvent: false });
      });
      const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {

        const autocomplete = new google.maps.places.Autocomplete(inputAddress);

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          let formAddress = this.form.get('address');

          formAddress?.setValue(place.formatted_address);

          // Compile other address fields according to place object
          place.address_components?.forEach((component: any) => {
            if (component.types.includes('locality')) {
              this.form.get('info.city')?.setValue(component.long_name);
            }
            if (component.types.includes('postal_code')) {
              this.form.get('info.cap')?.setValue(component.long_name);
            }
          });
          this.sendCoordinates()
        })
      });
    }
  }

  async sendCoordinates(){
    let range = this.form.get('range')!.value
    try{
      if(this.form.get('address')!.value !== null && this.form.get('address')!.value !== ''){
        const res = await this.googleMapsService.getCoordinates(this.form.get('address')!.value)
        let coordinates = {
          lat: res[0].geometry.location.lat(),
          lng: res[0].geometry.location.lng(),
          range: range
        }
        this.coordinates.emit(coordinates)
      } else {
        console.log('insert address')
      }
    } catch {
      console.log("error")
    }
  }

  resetCoordinates(){
    this.form.setValue({
      range: null,
      address: null
    })
  }

}
