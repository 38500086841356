import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-province-select',
  templateUrl: './province-select.component.html',
  styleUrls: ['./province-select.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, FormsModule]
})
export class ProvinceSelectComponent implements OnInit {
  provinceForm = new UntypedFormGroup({
    province: new UntypedFormControl(null)
  })

  @Output() valuesChange = new EventEmitter<string[]>();
  selectedValues: string[] = [];

  constructor() { }
  province = [
    { prValue: "TO", prLabel: 'Torino', selected: false },
    { prValue: "MI", prLabel: 'Milano', selected: false },
    { prValue: "RM", prLabel: 'Roma', selected: false },
    { prValue: "BO", prLabel: 'Bologna', selected: false },
    { prValue: "PD", prLabel: 'Padova', selected: false },
    { prValue: "GE", prLabel: 'Genova', selected: false },
    { prValue: "FI", prLabel: 'Firenze', selected: false },
    { prValue: "BS", prLabel: 'Brescia', selected: false },
    { prValue: "BG", prLabel: 'Bergamo', selected: false },
    { prValue: "MB", prLabel: 'Monza', selected: false },
    { prValue: "VR", prLabel: 'Altre Province', selected: false },
    { prValue: "ALL", prLabel: 'Seleziona Tutte', selected: false }
  ]
  ngOnInit(): void {
  }

  onChange(provinciaValue: string, event: Event): void {
    const target = event.target as HTMLInputElement | null;
    if (target !== null) {
      let isChecked = target.checked;
      if (isChecked) {
        if (provinciaValue === "ALL") {
          this.selectedValues = []
          this.selectAll()
        } else {
          this.selectedValues.push(provinciaValue);
        }
      } else {
        if (provinciaValue === "ALL") {
          this.selectedValues = []
          this.province.forEach((provincia) => {
            if (provincia.selected === true) {
              provincia.selected = !provincia.selected
            }
          })
        } else {
          const allCheck = document.getElementById('ALL') as HTMLInputElement;
          allCheck.checked = false;
        }
        const index = this.selectedValues.indexOf(provinciaValue);
        if (index > -1) {
          this.selectedValues.splice(index, 1);
        }
      }
    }
    this.valuesChange.emit(this.selectedValues);
  }


  selectAll(): void {
    this.province.forEach((provincia) => {
      if (provincia.prValue !== "ALL") {
        this.selectedValues.push(provincia.prValue)
      } else {
        this.province.forEach((provincia) => provincia.selected = !provincia.selected)
      }
    },
    )
  }
}
