import { UserStorageService } from './../user-storage/user-storage.service';
import { MessageService } from 'src/app/authentication/message-service/message-service.service';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as firebase from 'firebase/auth';
import { map, take, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { UserManagementService } from 'src/app/backoffice/user-management/user-management-service/user-management.service';
import { GoogleAuthProvider } from 'firebase/auth';

export interface FHToken {
  token: string,
  expires: Date,
  admin?: boolean;
  familyTutor?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private auth: AngularFireAuth, private messageService: MessageService,
    private router: Router, private dataService: UserStorageService, private management: UserManagementService) {
  }

  /* Performs already existing user authentication using email and password */
  async login(email: string, password: string) {
    try {
      await this.auth.setPersistence('local');
      const result = await this.auth.signInWithEmailAndPassword(email, password);
      //check if the email of the user has already been verified
      if (result.user) {
        if (result.user.emailVerified) {
          //read user from Firestore
          this.onLogin(result.user!.uid, true);
        } else {
          // provide error message to the user
          this.messageService.setMessage('Per favore, verifica il tuo indirizzo email prima di accedere.');
          this.sendVerificationEmail();
        }
      }
    } catch (error) {
      // provide error message to the user
      const newLocal = 'La password potrebbe essere sbagliata oppure questa mail non è associata ad alcun account. Riprova.';
      this.messageService.setMessage(newLocal);
    }
  }

  /* Performs user authentication using Google */
  async loginWithGoogle() {

    const provider = new GoogleAuthProvider()
    await this.auth.setPersistence('local');

    this.auth.signInWithPopup(provider).then((result) => {
      if (result.user) {
        this.handleProviderLogin(result.user, true);
      }
    });
  }

  /* Performs user authentication using email and password and creates user entity in DB */
  async signUp(email: string, password: string, user: User) {
    try {
      const value = await this.auth.createUserWithEmailAndPassword(email, password);
      if (value.user) {
        user.uid = value.user.uid;
      }
      this.management.createUser(user).subscribe(() => {
        this.sendVerificationEmail();
      });
    } catch (error) {
      this.messageService.setMessage('Questo indirizzo email è già stato usato per un altro account.');
    };
  }

  /* Send a password reset email to the user's email address provided */
  async forgotPassword(email: string) {
    try {
      const value = await this.auth.sendPasswordResetEmail(email);
      this.messageService.setMessage('Email di reset password inviata.');
    } catch (error) {
      this.messageService.setMessage('Email di reset password non inviata. Controlla il tuo indirizzo email.');
    }
  }

  /* Performs a logout of the current authenticated user */
  async signOut() {
    return this.auth.signOut().then((_) => {
      this.dataService.removeUserInfo();
    });
  }

  /* UNUSED: Allows the user to change her password, using his old credentials (email, password) and the newPassword */
  async changePassword(email: string, currentPassword: string, newPassword: string) {
    this.auth.signInWithEmailAndPassword(email, currentPassword).then((result) => {
      if (result.user)
        result.user.updatePassword(newPassword)
    });
  }

  /* UNUSED: Resets user's password, using the actionCode received in the email and the newPassword provided */
  async resetPassword(actionCode: string, newPassword: string) {
    return this.auth.verifyPasswordResetCode(actionCode).then((email) => this.auth.confirmPasswordReset(actionCode, newPassword)
      .then((_) => 'La nuova password è stata impostata.')
      .catch((error) => 'Impossibile impostare la nuova password.'));
  }

  /* UNUSED: Verifies the email associated to the actionCode provided */
  async verifyEmail(actionCode: string) {
    return this.auth.applyActionCode(actionCode);
  }

  /* UNUSED: Deletes user information both from local Storage and the DB */
  deleteUser() {
    if (this.auth.user) {
      this.auth.user.pipe(take(1), map((user) => {
        if (user) {
          const uid = user.uid;
          this.management.deleteUser(uid);
          user.delete().then((_) => {
            this.dataService.removeUserInfo();
            this.dataService.deleteData();
            this.messageService.setMessage('Account eliminato');
          });
        }
      })).subscribe();
    } else {
      this.messageService.setMessage('Si prega di effettuare l\'accesso prima di rimuovere l\'account.');
    }
  }

  /* Checks whether the user is authorized or not */
  isUserAuthorized(token: FHToken | null) {
    return token && !this.isTokenExpired(token) && (token.admin || token.familyTutor);
  }

  /* Checks whether the user is admin or not */
  isUserAdmin(token: FHToken | null) {
    return token && !this.isTokenExpired(token) && (token.admin);
  }

  /* Gets the token observer */
  getToken(): Observable<FHToken | null> {
    return this.auth.idTokenResult.pipe(map((tokenResult) => tokenResult ? ({
      token: tokenResult.token,
      expires: new Date(tokenResult.expirationTime),
      admin: tokenResult.claims.admin ?? false,
      familyTutor: tokenResult.claims.familyTutor ?? false
    }) : null))
  }

  /* Check whether the token has expired or not */
  isTokenExpired(token: FHToken | null) {
    if (!token) {
      return true;
    } else {
      return token.expires.getTime() < Date.now();
    }
  }

  /* Send a new verification email to the authenticated user */
  private sendVerificationEmail() {
    return this.auth.user.subscribe((user) => {
      if (user)
        user.sendEmailVerification();
    });
  }

  /* Handles user login, performing common operation */
  private onLogin(uid: string, followRouting = false) {
    this.management.getSingleUser(uid).subscribe((user) => {
      // verify if a user or a doctor logged in
      if (user) {
        this.dataService.setUser(user as User);
        if (followRouting)
          this.router.navigate(['']);
      }
    });
  }

  /* Handles Facebook or Google login, performing some checks to verify if the email is
     already associated to another account */
  private handleProviderLogin(user: any, followRouting = false) {
    const uid = user.uid;
    this.management.getSingleUser(uid).subscribe((fireUser) => {
      if (fireUser) {
        //if user exists login
        this.onLogin(uid, followRouting);
      } else {
        // otherwise signup
        const newUser = new User(uid, user.displayName || '', '', '', user.email || '', user.phoneNumber || '');
        this.management.createUser(newUser).subscribe((user) => {
          this.onLogin(uid, followRouting);
        });
      }
    });
  }
}
