import { HttpClient } from "@angular/common/http";
// import { Template } from "@angular/compiler/src/render3/r3_ast";
import { Injectable } from "@angular/core";
import { MailTemplate, Page } from "npx-family-happy-common";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";



export const DEFAULT_MAIL_FILTERS: EmailTemplatesFilters = {
  serviceID: null,
  type: null,
};
@Injectable({
  providedIn: 'root'
})

export class EmailTemplateService {

  private baseURL = environment.emailTemplateAPI;
  filters = new BehaviorSubject<EmailTemplatesFilters>(DEFAULT_MAIL_FILTERS)

  constructor(private http: HttpClient) { }


  saveTemplate(template: MailTemplate) {
    return this.http.post<MailTemplate>(`${this.baseURL}/`, template);
  }

  getAllTemplates(
    page: number,
    pageSize = 20,
    filters = DEFAULT_MAIL_FILTERS
  ) {
    const params = [
      `pageNumber=${page}`,
      `pageSize=${pageSize}`,
      `${this.convertFilterObjectToQueryParam(filters)}`,
    ].join('&');
    return this.http.get<Page<MailTemplate>>(`${this.baseURL}/list?${params}`);
  }

  getTemplateByID(id: number) {
    return this.http.get<MailTemplate>(`${this.baseURL}/?id=${id}`)
  }

  deleteTemplateByID(id: number) {
    return this.http.delete<MailTemplate>(`${this.baseURL}/${id}`)
  }

  setFilters(filters:any){
    this.filters.next(filters)
  }

  getFilters(){
    return this.filters.asObservable()
  }

  convertFilterObjectToQueryParam(filters: EmailTemplatesFilters) {
    let queryString = '';
    Object.entries(filters).forEach((entry: [string, any | null]) => {
      if (
        entry[1] !== null &&
        ((typeof entry[1] === 'string' && entry[1] !== '') ||
          (typeof entry[1] === 'number' && entry[1] >= 0))
      ) {
        queryString += `${queryString === '' ? '' : '&'}${entry[0]}=${encodeURIComponent(entry[1])}`;
      }
    });
    return queryString
  }
}

export interface EmailTemplatesFilters {
  serviceID: number | null,
  type: string | null
}
