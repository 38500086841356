<div class="container p-3">
  <div class="d-flex justify-content-between">
    <div class="d-flex gap-2">
      <h2 class="bi bi-arrow-left pointer" (click)="goBackToProducts()"></h2>
      <h2 class="mb-3" *ngIf="!isNew">Prodotto: {{selectedProduct?.id}}</h2>
      <h2 class="mb-3" *ngIf="isNew">Registra Prodotto</h2>
    </div>
  </div>
  <form [formGroup]="form" class="p-3 mb-3 rounded">
    <div class="row">
      <div class="col-6 mb-3">
        <label for="name" class="form-label">Nome Prodotto</label>
        <input type="text" id="name" class="form-control" formControlName="name" [readonly]="!isNew">
        <div *ngIf="form.get('name')?.touched && form.get('name')?.errors || formInvalid">
          <span class="form-error">{{validatorErrorMessage('name')}}</span>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="customerType" class="form-label">Seleziona Servizio</label>
        <app-fh-service-select [value]="form.get('service')?.value"
          (serviceSelected)="updateFHService($event)"></app-fh-service-select>
      </div>

      <div class="col-6 mb-3">
        <label for="customerType" class="form-label">Tipo di Cliente</label>
        <input
          type="text"
          id="customerType"
          class="form-control"
          formControlName="customerType"
          [readonly]="!isNew"
          [readonly]="isNew"
        />
        <div *ngIf="form.get('customerType')?.touched && form.get('customerType')?.errors || formInvalid">
          <span class="form-error">{{validatorErrorMessage('customerType')}}</span>
        </div>
      </div>

      <div class="col-6 mb-3">
        <label for="businessLine" class="form-label">Business Line</label>
        <input
          type="text"
          id="businessLine"
          class="form-control"
          formControlName="businessLine"
          [readonly]="!isNew"
          [readonly]="isNew"
        />
        <div *ngIf="form.get('businessLine')?.touched && form.get('businessLine')?.errors || formInvalid">
          <span class="form-error">{{validatorErrorMessage('businessLine')}}</span>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="type" class="form-label">Tipo Pagamento</label>
        <select class="form-select" id="type" formControlName="type">
          <option value="SERVICE">Una Tantum</option>
          <option value="SUBSCRIPTION">Abbonamento mensile</option>
          <option value="SUBSCRIPTION3">Abbonamento 3 mesi</option>
          <option value="SUBSCRIPTION12">Abbonamento 12 mesi</option>
        </select>
        <div *ngIf="form.get('type')?.touched && form.get('type')?.errors || formInvalid">
          <span class="form-error">{{validatorErrorMessage('type')}}</span>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="stripeID" class="form-label">Stripe ID</label>
        <input type="text" id="stripeID" class="form-control" formControlName="stripeID">
      </div>
    </div>
  </form>
  <div class="d-flex flex-row-reverse gap-2">
    <button class="btn btn-primary text-light" (click)="saveProduct()"><i class="bi bi-floppy-fill text-light"></i>
      Salva</button>
    <button *ngIf="!isNew" class="btn btn-danger text-light" (click)="deleteProduct()"><i class="bi bi-x-lg text-light pointer"></i>
      Elimina</button>
  </div>
</div>

<app-alert *ngIf="showDeleteConfirm" (save)="confirmDelete($event)" [data]="{ type: undefined }"
  [title]="'Conferma eliminazione'"
  [message]="'Sei sicuro di voler eliminare il prodotto ' + selectedProduct?.id + '?'">
</app-alert>

<ng-template #resultRow let-r="result" let-t="term">
  <span class="me-2">{{r.type}} - {{r.docRef}}:</span>
  <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>

