import { Injectable, OnInit } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService implements OnInit {
  private loader: Loader;
  private google: any;
  private geocoderService!: google.maps.Geocoder;
  private autocompleteService!: google.maps.places.AutocompleteService;

  coordinatesSubject = new BehaviorSubject(null)

  constructor() {
    //LOADER FOR MAPS API-----
    this.loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' });
    this.getGoogleMaps().then(async (google) => {
      this.geocoderService = new google.maps.Geocoder();
      this.autocompleteService = new google.maps.places.AutocompleteService();
      // console.log(this.autocompleteService);
      // this.autocompleteService.getPlacePredictions({input: 'Via Processionale 16'}).then((response) => console.log(response, "on service"))
    });
  }
  ngOnInit(): void {
  }

  getGoogleMaps() {
    if (this.google) {
      return Promise.resolve(this.google);
    } else {
      return this.loader.load().then((google) => {
        this.google = google;
        return google;
      })
    }
  }

  getCoordinates(address: string): Promise<google.maps.GeocoderResult[]> {
    return new Promise((resolve, reject) => {
      this.geocoderService.geocode({ 'address': address }, (result, status) => {
        if (status === "OK" && result) {
          resolve(result);
        } else {
          reject(status)
        }
      })
    })
  }

  setCoordinates(coordinates:any){
    this.coordinatesSubject.next(coordinates)
  }

  getCoordinatesSub(){
    return this.coordinatesSubject.asObservable()
  }
}
