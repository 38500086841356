import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { FHService } from 'npx-family-happy-common';
import { ServiceServices } from 'src/app/backoffice/services/service-services/service.services';
import { CommonModule } from '@angular/common';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fh-service-select',
  templateUrl: './fh-service-select.component.html',
  styleUrls: ['./fh-service-select.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, NgbTypeaheadModule]
})
export class FHServiceSelectComponent implements OnInit, OnChanges {
  @Input() set value(value: string) {
    this.form.controls.serviceControl.setValue(value);
  };
  @Input() reset!: boolean
  @Input() set serviceID(serviceID:number | null) {
    if(serviceID !== undefined && serviceID !== null){
      this.serviceService.getServiceByID(serviceID).subscribe((service) => {
        this.form.get('serviceControl')?.setValue(service);
      })
    } else {
      this.form.get('serviceControl')?.setValue(null);
    }
  }

  @Output() serviceSelected = new EventEmitter<FHService>();
  form = new UntypedFormGroup({
    serviceControl: new UntypedFormControl(null, [Validators.required])
  })
  formInvalid = false

  constructor(private serviceService: ServiceServices) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.reset === true){
      this.resetValue(this.reset)
      this.reset = false
    }
  }

  ngOnInit(): void {
  }

  serviceSearch = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((searchKey: string) => {
        return this.serviceService.getAllServices(0, 5, { searchKey, businessLine: null, customerType: null, disabled: null }).pipe(map((page) => page.content));
      }),
    );
  }

  formatter = (service: FHService) => {
    return service.name;
  }

  onFHServiceSelected(event: any) {
    const service = event.item;
    this.serviceSelected.emit(service);
  }

  resetSelection() {
    this.form.get('serviceControl')?.reset()
    this.serviceSelected.emit(undefined);
  }

  resetValue(event:Boolean){
    if(event === true){
      this.form.get('serviceControl')?.reset()
      this.serviceSelected.emit(undefined);
    }
  }

  validatorErrorMessage(fieldName:string){
    const field = this.form.get(fieldName);
    let message = ''
    if(field?.errors){
      if(field?.errors['required']){
        message = `Questo campo è obbligatorio`
      }
    }
    return message
  }
}
