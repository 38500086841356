import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
  standalone:true,
  imports:[CommonModule]
})
export class LoadingModalComponent implements OnInit {
  @Input() message!: string;

  constructor() { }

  ngOnInit(): void {
  }

}
