import { WhatsappBody, SmartphoneContainerComponent } from './../smartphone-container/smartphone-container.component';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from 'npx-family-happy-common';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-whatsapp-message-composer',
  templateUrl: './whatsapp-message-composer.component.html',
  styleUrls: ['./whatsapp-message-composer.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, SmartphoneContainerComponent]
})
export class WhatsappMessageComposerComponent implements OnInit {
  @Input() data: WhatsappBody = {
    recipients: [],
    role: 'babysitter',
    childInfo: '1 bambino di 5 anni',
    city: 'Milano - Duomo',
    description: 'badare al bambino tra le 14:00 e le 18:00 dal lunedì al venerdì e aiutarlo nel fare i compiti',
    starting: 'dal 10 settembre 2023 - Lavoro continuativo',
    wage: '11,00€/ora',
    requestID: '4033',
    type: 'BABYSITTER_AD'
  }
  @Input() type: string = 'BABYSITTER';
  @Output() sendWhatsappMessage = new EventEmitter<WhatsappBody>();

  form: UntypedFormGroup = new UntypedFormGroup({
    role: new UntypedFormControl('', [Validators.required]),
    childInfo: new UntypedFormControl('', [Validators.required]),
    city: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', [Validators.required]),
    starting: new UntypedFormControl('', [Validators.required]),
    wage: new UntypedFormControl('', [Validators.required]),
    requestID: new UntypedFormControl('', [Validators.required])
  });

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    this.form.setValue({
      role: this.data.role,
      childInfo: this.data.childInfo,
      city: this.data.city,
      description: this.data.description ? this.data.description.replaceAll('\n', ' ') : '',
      starting: this.data.starting,
      wage: this.data.wage,
      requestID: this.data.requestID
    });
    if (this.type === 'PETSITTER' || this.type === 'NURSE' || this.type === 'COLF') {
      this.form.get('role')?.disable();
      this.form.get('childInfo')?.disable();
    }
  }

  submit() {
    if (this.form.valid) {
      const data = {
        ...this.form.value,
        description: (this.form.value.description as string)
      }
      console.log('Send Whatsapp message:', data);
      this.sendWhatsappMessage.emit(data);
    } else {
      const errors: any[] = [];
      Object.keys(this.form.controls).forEach((key) => {
        if (this.form.get(key)?.errors)
          errors.push({ key, errors: this.form.get(key)?.errors });
      })
      if (errors.length > 0) {
        this.modalService.showErrorMessage(`Attenzione - Compilare tutti i campi prima di inviare il messaggio whatsapp`);
      }
    }
  }

}
