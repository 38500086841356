import { debounceTime, distinctUntilChanged, Observable, OperatorFunction, switchMap } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, UntypedFormControl } from '@angular/forms';
import { GoogleMapsService } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/google-maps.service';
import { CommonModule } from '@angular/common';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-input-map',
  templateUrl: './input-map.component.html',
  styleUrls: ['./input-map.component.scss'],
  standalone:true,
  imports:[CommonModule, FormsModule, NgbTypeaheadModule, ]
})
export class InputMapComponent implements OnInit {
  @Input() value!: UntypedFormControl;
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();
  @Input() set reset(value: boolean) {
    this.resetFilter(value);
  }
  model: any;

  private autocompleteService!: google.maps.places.AutocompleteService;

  constructor(private googleMapService: GoogleMapsService) {
    this.googleMapService.getGoogleMaps().then(async (google) => {
      this.autocompleteService = new google.maps.places.AutocompleteService();
    });
  }

  ngOnInit(): void {
    this.model = this.value.value
  }

  search: OperatorFunction<string, readonly { description: any }[]> = (text$: Observable<string>) => {
    return text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			switchMap(async (term) => {
        const response = await this.autocompleteService.getPlacePredictions({ input: term });
        return response.predictions.map(item => ({description: item.description}));
      }),
		);
  };

  formatter = () => this.model;
  formatterSuggest = (x: { description: string }) => x.description;

  onSelectAddress(event: any) {
    this.itemSelected.emit(event.item.description)
    this.model = event.item.description
  }

  resetFilter(event: boolean) {
    if (event === true) {
      this.model = '';
    }
  }
}
