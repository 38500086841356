import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CertificateService, Colf } from 'npx-family-happy-common';

@Component({
  selector: 'app-cv-colf-template',
  templateUrl: './cv-colf-template.component.html',
  styleUrls: ['./cv-colf-template.component.scss'],
  standalone:true,
  imports:[CommonModule]
})
export class CvColfTemplateComponent implements OnInit {

  @Input() set colf(colf: Colf) {
    this._colf = colf;
    if (this._colf !== undefined) {
      this.showReference = this._colf.reference && ((this._colf.reference.f_surname !== null && this._colf.reference.f_surname !== '') ||
        (this._colf.reference.f_second_surname !== null && this._colf.reference.f_second_surname !== ''));
      this.certificates = this._colf.certificates.colfCertificateList ? this._colf.certificates.colfCertificateList.filter(item => item !== '').slice(0, 3) : [];
      this.initAvailabilieties();
      this.initAttitude();
      this.initSubjects();
    }
  };
  @Input() isProduction = false;
  @Input() mailServiceURL = '';
  @Input() showModal = false;

  @Input() set generatePages(value: boolean) {
    if (value) {
      this.calcPages();
    } else {
      this.generationDone = false;
    }
  }

  @Input() currentPage!: number;

  availabilities: string[] = [];
  homeworkSubjects: string[] = [];
  showReference = false;
  attitude: string = '';
  _colf!: Colf;
  pages: string[] = [];
  generationDone: boolean = false;
  previewMode: boolean = true;
  certificates: string[] = [];
  @Output() sendPages = new EventEmitter<number>();

  constructor(public sanitizer: DomSanitizer, private certificateService: CertificateService) { }

  calcPages() {
    this.pages = [];
    let rightColumn = document.getElementById('right-column') as HTMLDivElement;
    let elY = rightColumn.getBoundingClientRect().top;
    let aboutMe = document.getElementById('about-me-section') as HTMLDivElement;
    let experienceTitle = document.getElementById('experience-title') as HTMLDivElement;
    let experienceSection = document.getElementById('experience-section') as HTMLDivElement;
    let reference = document.getElementById('reference');
    let currentPage = 0;
    this.pages[0] += `<div class="experience-range">`;
    this.pages[currentPage] += '</div></div>';
    if (reference && this.showReference) {
      this.pages[currentPage] += reference.outerHTML;
    }
    this.generationDone = true;
    this.sendPages.emit(this.pages.length);

    /* console.log('pages:', this.pages); */
  }

  generatePDF() {
    const template = document.getElementById('cv-page-container') as HTMLElement;
    // this.certificateService.createColfPDFCVFromHTML(template, this._colf, false, false);
  }

  private initAvailabilieties() {
    this.availabilities = [];
  }

  private initAttitude() {
    this.attitude = '';
    if (this._colf.rating.attitude === 'Facilitatore') {
      this.attitude = `Questo profilo è tendenzialmente interessato a comportamenti armoniosi verso le persone e l’ambiente che lo circonda. Risulta essere una persona che ha fiducia in sé stessa, con una buona capacità di riflessione. Fa tesoro delle proprie esperienze per migliorare. Ha buona capacità a stringere legami, anche profondi, con le persone con cui viene in contatto. Ha un approccio tendenzialmente pacato e presenta una spiccata abilità nel trovare il proprio equilibrio.`;
    }
    if (this._colf.rating.attitude === 'Disciplina') {
      this.attitude = `Questo profilo è ottimista e consapevole delle proprie capacità. È una persona comprensiva ed equilibrata. Esprime un forte senso di responsabilità e dovere ed è caratterizzato da una marcata propensione al fare “bene” e alla perseveranza. Predilige ambienti organizzati e strutturati.`;
    }
    if (this._colf.rating.attitude === 'Accondiscendente') {
      this.attitude = `Questo profilo è caratterizzato principalmente dall’ottimismo. Ha fiducia nel futuro, nel prossimo e nel mondo in generale. E’ una persona dinamica e fantasiosa. E’ mossa prevalentemente da nobili sentimenti, non ama il conflitto né i pensieri negativi. Persona flessibile, aperta ai cambiamenti, affronta gli imprevisti con buon entusiasmo.`;
    }
    if (this._colf.rating.attitude === 'Esecutore') {
      this.attitude = `Questo profilo ama aiutare gli altri. E’ saggio ma anche prudente e accetta favorevolmente di essere guidato soprattutto quando si trova ad affrontare una situazione per la prima volta. E’ una persona altruista che prende a cuore la salute degli altri. E’ sensibile ed è attento a non sovrastare gli altri.`;
    }
    if (this.attitude === '') {
      this.attitude = `Questo profilo non ha ancora svolto il test psicoattitudinale.`;
    }
  }

  private initSubjects() {
    this.homeworkSubjects = [];
  }

  // taskCheck(cluster: number, task: string) {
  //   // switch (cluster) {
  //   //   case 1:
  //   //     return this._colf.exp.cluster1.tasks1.split('-').some(task1 => task1 === task);

  //   //   case 2:
  //   //     return this._colf.exp.cluster2.tasks2.split('-').some(task2 => task2 === task);

  //   //   default:
  //   //     return false;
  //   // }
  // }

  changePage(forward: boolean) {
    if (forward && this.currentPage < this.pages.length - 1) {
      this.currentPage++;
    }
    if (!forward && this.currentPage > 0) {
      this.currentPage--;
    }
  }

  sendCV() {
    this.certificateService.sendCVByMailToColf(this._colf, this.isProduction, this.mailServiceURL, this.showModal);
  }

  ngOnInit(): void {
  }

}
