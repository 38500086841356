<div class="compatibility-index {{ size }}">
  <div class="text-container">
    <span [ngStyle]="{ 'font-size': fontSize + 'px' }">
      {{ (compatibility * 100).toFixed(0) }}%
    </span>
  </div>
    <svg
    id="svg"
    [attr.width]="size === 'small' ? 36 : 104"
    [attr.height]="size === 'small' ? 36 : 104"
    [attr.viewPort]="size === 'small' ? '0 0 36 36' : '0 0 104 104'"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
  <!-- raggio 27 pos 30 stroke -->
    <circle
      [attr.r]="size === 'small' ? 15 : 47"
      [attr.cx]="size === 'small' ? 18 : 52"
      [attr.cy]="size === 'small' ? 18 : 52"
      fill="transparent"
      [attr.stroke-dasharray]="size === 'small' ? 94.25 : 295.31"
      stroke-dashoffset="0"
    ></circle>
    <circle
      id="bar"
      [attr.r]="size === 'small' ? 15 : 47"
      [attr.cx]="size === 'small' ? 18 : 52"
      [attr.cy]="size === 'small' ? 18 : 52"
      fill="transparent"
      [attr.stroke-dasharray]="size === 'small' ? 94.25 : 295.31"
      [ngStyle]="{
        'stroke-dashoffset':
          size === 'small'
            ? 94.25 - 0.9425 * compatibility * 100
            : 295.31 - 2.9531 * compatibility * 100
      }"
    ></circle>
  </svg>
</div>
