import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { PossiblePayer } from 'npx-family-happy-common';
import { Observable, debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';
import { PaymentService } from 'src/app/backoffice/payments/payment-service/payment.service';

@Component({
  selector: 'app-possible-payer-select',
  templateUrl: './possible-payer-select.component.html',
  styleUrls: ['./possible-payer-select.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, NgbTypeaheadModule]
})
export class PossiblePayerSelectComponent implements OnInit {
  @Input() label: string = 'Cliente';
  @Output() payerSelected = new EventEmitter<PossiblePayer>();
  payerControl = new UntypedFormControl('', [Validators.required, Validators.email]);

  constructor(private paymentService: PaymentService) { }

  ngOnInit(): void {
  }

  payerSearch = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((email) => {
        if (this.payerControl.valid) {
          return this.paymentService.getPossiblePayers(email);
        } else {
          return of([]);
        }
      }),
    );
  }

  formatter = (payer: PossiblePayer) => {
    return payer.email;
  }

  onPayerSelected(event: any) {
    const payer = event.item;
    this.payerSelected.emit(payer);
  }

  resetSelection() {
    this.payerControl.reset();
    this.payerSelected.emit(undefined);
  }
}
