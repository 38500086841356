import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-compatibility-index',
  templateUrl: './compatibility-index.component.html',
  styleUrls: ['./compatibility-index.component.scss'],
  standalone:true,
  imports: [CommonModule]
})
export class CompatibilityIndexComponent implements OnInit {
  @Input() compatibility!: number;
  @Input() fontSize = 10;
  @Input() size: 'large' | 'small' = 'small';

  constructor() {}

  ngOnInit(): void {}
}
