import { CaregiverSubscriptionDashboardComponent } from './sellers/caregiver-subscription/caregiver-subscription-dashboard/caregiver-subscription-dashboard.component';
import { EmailDetailComponent } from './backoffice/email/email-detail/email-detail.component';
import { EmailDashboardComponent } from './backoffice/email/email-dashboard/email-dashboard.component';
import { ServiceDetailComponent } from './backoffice/services/service-detail/service-detail/service-detail.component';
import { ProductDetailComponent } from './backoffice/products/product-detail/product-detail/product-detail.component';
import { ProductDashboardComponent } from './backoffice/products/product-dashboard/product-dashboard/product-dashboard.component';
import { ServicesDashboardComponent } from './backoffice/services/services-dashboard/services-dashboard.component';
import { CreatePaymentLinkComponent } from './sellers/create-payment-link/create-payment-link.component';
import { SellerDashboardComponent } from './sellers/seller-dashboard/seller-dashboard.component';
import { PriceDetailComponent } from './backoffice/prices/price-detail/price-detail.component';
import { PricesDashboardComponent } from './backoffice/prices/prices-dashboard/prices-dashboard.component';
import { PaymentDetailComponent } from './backoffice/payments/payment-detail/payment-detail.component';
import { PaymentDashboardComponent } from './backoffice/payments/payment-dashboard/payment-dashboard.component';
import { AdminGuard } from './authentication/admin-guard/admin.guard';
import { UserManagementComponent } from './backoffice/user-management/user-management.component';
import { CvColfTemplateWrapperComponent } from './caregiver/colf/cv-colf-template-wrapper/cv-colf-template-wrapper.component';
import { ColfDetailComponent } from './caregiver/colf/colf-detail/colf-detail.component';
import { ColfListComponent } from './caregiver/colf/colf-list/colf-list.component';
import { CvNurseTemplateWrapperComponent } from './caregiver/nurse/cv-nurse-template-wrapper/cv-nurse-template-wrapper.component';
import { NurseDetailComponent } from './caregiver/nurse/nurse-detail/nurse-detail.component';
import { NurseListComponent } from './caregiver/nurse/nurse-list/nurse-list.component';
import { CvPetsitterTemplateWrapperComponent } from './caregiver/petsitter/cv-petsitter-template-wrapper/cv-petsitter-template-wrapper.component';
import { PetsitterDetailComponent } from './caregiver/petsitter/petsitter-detail/petsitter-detail.component';
import { PetsitterListComponent } from './caregiver/petsitter/petsitter-list/petsitter-list.component';
import { CvTemplateWrapperComponent } from './caregiver/babysitter/cv-template-wrapper/cv-template-wrapper.component';
import { BabySitterDetailComponent } from './caregiver/babysitter/baby-sitter-detail/baby-sitter-detail.component';
import { BabySitterListComponent } from './caregiver/babysitter/baby-sitter-list/baby-sitter-list.component';
import { RequestDetailComponent } from './requests-handling/request-detail/request-detail.component';
import { AuthGuard } from './authentication/auth-guard/auth.guard';
import { RequestListComponent } from './requests-handling/request-list/request-list.component';
import { LoginComponent } from './authentication/login/login.component';
import { RequestMessagesComponent } from './requests-handling/request-messages/request-messages.component';
import { FillCertificateComponent } from 'npx-family-happy-common';
import { Routes } from '@angular/router';
import { LoadBalanceDashboardComponent } from './backoffice/load-balance/load-balance-dashboard/load-balance-dashboard.component';
import { CaregiverSelectionComponent } from './requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection.component';



export const routes: Routes = [
  { path: '', redirectTo: 'request-list', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'request-list', component: RequestListComponent, canActivate: [AuthGuard] },
  { path: 'request-detail/:id', component: RequestDetailComponent, canActivate: [AuthGuard] },
  { path: 'request-messages', component: RequestMessagesComponent, canActivate: [AuthGuard] },
  { path: 'babysitter-list', component: BabySitterListComponent, canActivate: [AuthGuard] },
  { path: 'babysitter-detail/:id', component: BabySitterDetailComponent, canActivate: [AuthGuard] },
  { path: 'babysitter-detail/:id/fill-certificate', component: FillCertificateComponent, canActivate: [AuthGuard] },
  { path: 'babysitter-detail/:id/cv', component: CvTemplateWrapperComponent, canActivate: [AuthGuard] },
  { path: 'petsitter-list', component: PetsitterListComponent, canActivate: [AuthGuard] },
  { path: 'petsitter-detail/:id', component: PetsitterDetailComponent, canActivate: [AuthGuard] },
  { path: 'petsitter-detail/:id/cv', component: CvPetsitterTemplateWrapperComponent, canActivate: [AuthGuard] },
  { path: 'nurse-list', component: NurseListComponent, canActivate: [AuthGuard] },
  { path: 'nurse-detail/:id', component: NurseDetailComponent, canActivate: [AuthGuard] },
  { path: 'nurse-detail/:id/cv', component: CvNurseTemplateWrapperComponent, canActivate: [AuthGuard] },
  { path: 'colf-list', component: ColfListComponent, canActivate: [AuthGuard] },
  { path: 'colf-detail/:id', component: ColfDetailComponent, canActivate: [AuthGuard] },
  { path: 'colf-detail/:id/cv', component: CvColfTemplateWrapperComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UserManagementComponent, canActivate: [AdminGuard] },
  { path: 'payments', component: PaymentDashboardComponent, canActivate: [AdminGuard] },
  { path: 'payments/:id', component: PaymentDetailComponent, canActivate: [AdminGuard] },
  { path: 'prices', component: PricesDashboardComponent, canActivate: [AdminGuard] },
  { path: 'prices/:id', component: PriceDetailComponent, canActivate: [AdminGuard] },
  { path: 'sellers', component: SellerDashboardComponent, canActivate: [AuthGuard] },
  { path: 'payment-link-generator', component: CreatePaymentLinkComponent, canActivate: [AuthGuard] },
  { path: 'sellers', component: SellerDashboardComponent, canActivate: [AuthGuard] },
  { path: 'services', component: ServicesDashboardComponent, canActivate: [AdminGuard] },
  { path: 'products', component: ProductDashboardComponent, canActivate: [AdminGuard] },
  { path: 'products/:id', component: ProductDetailComponent, canActivate: [AdminGuard] },
  { path: 'services/:id', component: ServiceDetailComponent, canActivate: [AdminGuard] },
  { path: 'emails', component: EmailDashboardComponent, canActivate: [AdminGuard] },
  { path: 'emails/:id', component: EmailDetailComponent, canActivate: [AdminGuard] },
  { path: 'caregivers-subscription-dashboard', component: CaregiverSubscriptionDashboardComponent, canActivate: [AdminGuard] },
  { path: 'load-balance', component: LoadBalanceDashboardComponent, canActivate: [AdminGuard] }
]
