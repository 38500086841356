import { User } from '../../shared/models/user.model';
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserManagementService } from './user-management-service/user-management.service';
import { ModalService, Page } from 'npx-family-happy-common';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { NullValuesTablePipe } from './null-values-table-pipe/null-values-table.pipe';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';

const DEFAULT_FILTERS = {
  email:'',
  name:'',
  page: 0,
  pageSize:0,
  phone:'',
  role:'',
  uid: '',
};

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, NullValuesTablePipe, FormsModule, NgbPaginationModule, AlertComponent]
})
export class UserManagementComponent implements OnInit, OnDestroy {

  form = new UntypedFormGroup({
    uid: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
    page:  new UntypedFormControl(0),
    role: new UntypedFormControl('')
  })
  filters = new BehaviorSubject<any>(DEFAULT_FILTERS)
  showAlert = false;
  elementsPerPage = new UntypedFormControl('10');
  usersPage!: Page<User>;
  private action!: 'delete' | 'changerole';
  message!: string;
  private userSelected!: string;
  private newRole!: string;
  page = 1
  pageSize = 10
  collectionSize = 0
  private unsubscribe = new Subject<void>();

  constructor(private management: UserManagementService, private modalService: ModalService) { }

  ngOnInit(): void {
    this.filters.pipe(takeUntil(this.unsubscribe)).subscribe((filters) => {
      this.loadMore()
    })
    this.form.valueChanges.pipe(debounceTime(250), distinctUntilChanged()).pipe(takeUntil(this.unsubscribe)).subscribe((filters) => {
      this.page = 1
      this.filters.next(filters)
    });
    this.elementsPerPage.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((size) => {
      this.form.get('page')?.setValue(this.page -1)
      this.pageSize = size
      this.page = 1
    });
  }

  resetFilters() {
    this.form.reset({
      uid: '',
      name: '',
      email: '',
      phone: '',
      page:0,
      role:''
    });
  }

  rowClick(user: User) {
    // console.log(user);
  }

  changeUserRole(user: User, event: any) {
    this.userSelected = user.uid;
    this.newRole = event.target.value;
    event.target.value = user.role; // Ripristina il vecchio valore della select
    this.showAlertModal(`Vuoi davvero cambiare il ruolo di questo profilo utente in ${this.newRole}?`, 'changerole');
  }

  deleteUser(userUID: string) {
    this.showAlertModal('Vuoi davvero eliminare questo profilo utente?', 'delete');
    this.userSelected = userUID;
  }

  loadMore(next = false) {
    const page = this.page -1
    const pageSize = this.elementsPerPage.value
    const filters = { ...this.form.value };
    Object.keys(filters).forEach(key => {
      if (filters[key] === '') {
        delete filters[key];
      }
    });
    this.management.getUsersList(page, pageSize, filters).pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      if (next) {
        this.usersPage = { ...data, content: this.usersPage.content.concat(data.content) }
      } else {
        this.usersPage = data
        this.collectionSize = data.totalElements
      }
    });
  }

  onAlertConfirmation(confirmed: boolean) {
    if (confirmed) {
      switch (this.action) {
        case 'delete':
          this.completeDeleteUser();
          break;
        case 'changerole':
          this.completeChangeRole();
          break;
        default:
          break;
      }
    }
    this.showAlert = false;
  }

  showAlertModal(message: string, action: 'delete' | 'changerole') {
    this.action = action;
    this.message = message;
    this.showAlert = true;
  }

  private completeDeleteUser() {
    this.management.deleteUser(this.userSelected).pipe(takeUntil(this.unsubscribe)).subscribe((result) => {
      // aggiornare la tabella dei dati
      this.loadMore();
      // mostrare un messaggio di successo dell'operazione
      this.showConfirmMessage(`Utente cancellato con successo`);
      console.log(result)
    });
  }

  private completeChangeRole() {
    this.management.changeUserRole(this.userSelected, this.newRole).pipe(takeUntil(this.unsubscribe)).subscribe((result) => {
      // aggiornare la tabella dei dati
      this.loadMore();
      // mostrare un messaggio di successo dell'operazione
      this.showConfirmMessage(`Ruolo dell'utente modificato con successo`);
      console.log(result)
    });
  }

  private showConfirmMessage(message: string) {
    this.modalService.showSuccessMessage(message);
  }

  onPageChange(){
    if(this.page > 0) {
      this.loadMore()
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
