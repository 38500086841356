<div class="container p-3">
  <div>
    <h2 class="text-center text-primary">Prezzi</h2>
    <h4 class="text-center pointer" (click)="collapse.toggle()">Filtri<i class="bi ms-2" style="font-size: 16px;"
        [ngClass]="isCollapsed ? 'bi-caret-down-fill' : 'bi-caret-up-fill'"></i></h4>
    <form class="row m-3 p-3 bg-info rounded" [formGroup]="filters" #collapse="ngbCollapse"
      [(ngbCollapse)]="isCollapsed">
      <div class="col-6 mb-3">
        <label for="amount" class="form-label">Importo</label>
        <input type="number" step="1" id="amount" class="form-control" formControlName="amount">
      </div>
      <div class="col-6 mb-3">
        <label for="product" class="form-label">Prodotto</label>
        <app-product-select [productID]="productIDfilter"
          (productSelected)="updateProducts($event)"></app-product-select>
      </div>

      <div class="col-6 mb-3">
        <label for="province" class="form-label">Provincia</label>
        <app-province-filter [provincia]="filters.get('province')?.value" [includeAll]="true"
          (valueChange)="updateProvincia($event)"></app-province-filter>
      </div>

      <div class="col-6 mb-3">
        <label for="stripeID" class="form-label">Stripe ID</label>
        <input type="text" id="stripeID" class="form-control" formControlName="stripeID">
      </div>

      <button class="col-3 btn btn-primary m-auto text-light"
        (click)="collapse.toggle(); applyFilters()">Applica</button>
      <button class="col-3 btn btn-secondary m-auto text-light"
        (click)="collapse.toggle(); resetFilters()">reset</button>
    </form>
  </div>
  <div class="d-flex flex-row-reverse gap-2">
    <button class="btn btn-info" (click)="createPrice()"><i class="bi bi-plus-square"></i></button>
  </div>
  <table class="table table-hover table-striped">
    <thead>
      <tr>
        <!-- <th></th> -->
        <th scope="col">ID</th>
        <th scope="col">Provincia</th>
        <th scope="col">Importo</th>
        <th scope="col">Nome Prodotto</th>
        <th scope="col">Nome Servizio</th>
        <th scope="col">Business Line</th>
        <th scope="col">Tipo Cliente</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let price of prices">
        <th scope="row">{{price.id}}</th>
        <td scope="row">{{price.province}}</td>
        <td scope="row">{{price.amount / 100 | currency : 'EUR'}}</td>
        <td scope="row">{{price.product.name}}</td>
        <td scope="row">{{price.product.service.name}}</td>
        <td scope="row">{{price.product.service.businessLine}}</td>
        <td scope="row">{{price.product.service.customerType}}</td>

        <td>
          <a [routerLink]="['/prices', price.id]">
            <button class="btn btn-primary px-2 py-1"><i class="bi bi-search text-light pointer"></i></button>
          </a>
        </td>
        <td><button class="btn btn-danger px-2 py-1" (click)="deletePrice(price.id)"><i
              class="bi bi-x-lg text-light pointer"></i></button></td>
      </tr>
      <tr *ngIf="prices.length === 0">
        <td colspan="10" class="text-center">Non sono stati trovati prezzi.</td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <p class="col">Sono presenti <b>{{collectionSize}}</b> prezzi in totale.</p>
    <ngb-pagination class="col d-flex justify-content-end" *ngIf="prices.length > 0" [maxSize]="5" [(page)]="page"
      [pageSize]="pageSize" [collectionSize]="collectionSize" (pageChange)="onPageChange()"></ngb-pagination>
  </div>
</div>

<app-alert *ngIf="showDeleteConfirm" (save)="confirmDelete($event)" [data]="{ type: undefined }"
  [title]="'Conferma eliminazione'" [message]="'Sei sicuro di voler eliminare il pagamento ' + selectedPriceID + '?'">
</app-alert>
