<div class="alert alertColor">
  <div class="alert-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <div class="alert-content">
        <p id="alert-content-message">{{ message }}</p>
        <div class="input-group">
          <div class="d-flex justify-content-center" *ngFor="let variable of variablesList">
            <div *ngIf="variable.label === 'Singolare-Plurale'; else other">
              <app-mail-template-tooltip
                [label]="variable.label"
                (click)="open(content)"
                [description]="variable.descrizione"
                [textValue]="variable.text"
              ></app-mail-template-tooltip>
            </div>
            <ng-template #other>
              <app-mail-template-tooltip
                [label]="variable.label"
                (click)="save.emit(variable.text)"
                [description]="variable.descrizione"
                [textValue]="variable.text"
              ></app-mail-template-tooltip>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        (click)="modal.close('Save click')"
        class="btn buttonClose"
      >
        Chiudi
      </button>
    </div>
  </div>
</div>

<ng-template #content let-modalNested>
  <div class="alert" *ngIf="openInputPlural">
    <div class="alert-content alertEntity">
      <div class="modal-header">
        <h4 class="modal-title titleEntity">Singolare - Plurale</h4>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="modalNested.dismiss('Cross click')"
        ></button>
      </div>
      <div class="modal-body mr-2" [formGroup]="entityForm">
        <p id="alert-content-message" class="text-start" [innerHTML]="messageEntity"></p>
        <h4 class="text-center selectPluralTitle">Seleziona una variabile</h4>
        <p class="alertMessage" *ngIf="anySelected">ATTENZIONE! Selezionare una variabile prima di salvare</p>
        <div class="input-group-plural justify-content-center">
        <div class="d-flex justify-content-center" *ngFor="let variable of pluralList">
          <app-mail-template-tooltip
          [variable]="variable"
          [label]="variable.label"
          (click)="selectVar(variable)"
          [description]="variable.descrizione"
          [textValue]="variable.text"
        ></app-mail-template-tooltip>
        </div>
      </div>
        <div class="borderEntity">
        <div class="mb-3 mt-3">
          <label class="d-block text-start labelEntity" for="singolare">Singolare</label>
          <input class="d-block inputEntity" id="singolare" type="text" formControlName="singolare" />
        </div>
        <div class="mb-3">
          <label class="d-block text-start labelEntity" for="plurale">Plurale</label>
          <input class="d-block inputEntity" id="plurale" type="text" formControlName="plurale" />
        </div>
      </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn buttonSave"
          (click)="chooseEntity()"
        >
          Salva
        </button>
        <button
          type="button"
          class="btn buttonClose"
          (click)="modalNested.close('Close click')"
        >
          Chiudi
        </button>
      </div>
    </div>
  </div>
</ng-template>
