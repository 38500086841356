<app-header-bar *ngIf="showHeader" (showMenu)="showMenu = true;"></app-header-bar>
<app-side-menu *ngIf="showHeader" [visible]="showMenu" (dismissMenu)="showMenu = false"></app-side-menu>
<app-loading-modal *ngIf="loading" [message]="modalMessage"></app-loading-modal>
<app-outcome-modal *ngIf="showOutcome|async" [message]="outcomeMessage" [messageType]="outcomeType"></app-outcome-modal>
<div class="w-100" style="height: calc(100vh - 80px); overflow: auto;">
  <div class="toastContainer">
    <ngbd-toast-global></ngbd-toast-global>
  </div>
  <router-outlet></router-outlet>
</div>
