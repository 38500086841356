<div class="container p-3">
  <div class="d-flex justify-content-between">
    <div class="d-flex gap-2">
      <h2 class="bi bi-arrow-left pointer" (click)="goBackToPrices()"></h2>
      <h2 class="mb-3" *ngIf="!isNew">Prezzo: {{selectedPrice?.id}}</h2>
      <h2 class="mb-3" *ngIf="isNew">Registra Prezzo</h2>
    </div>
  </div>
  <form [formGroup]="form" class="p-3 mb-3 rounded">
    <div class="row">
      <div class="col-6 mb-3">
        <label for="amount" class="form-label">Importo</label>
        <div class="d-flex">
          <button class="btn btn-info currency-label" [disabled]="true">€</button>
          <input type="number" step=".01" id="amount" class="form-control" formControlName="amount">
        </div>
        <div *ngIf="form.get('amount')?.touched && form.get('amount')?.errors || formInvalid">
          <span class="form-error">{{validatorErrorMessage('amount')}}</span>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="stripeID" class="form-label">Stripe ID</label>
        <input type="text" id="stripeID" class="form-control" formControlName="stripeID">
      </div>
      <div class="col-6 mb-3">
        <label for="product" class="form-label">Nome Prodotto</label>
        <app-product-select [value]="form.get('product')?.value"
          (productSelected)="updateProducts($event)"></app-product-select>
      </div>
      <div class="col-6 mb-3">
        <label for="productServiceName" class="form-label">Nome Servizio</label>
        <input type="text" id="productServiceName" class="form-control" formControlName="productServiceName"
          [readonly]="true">
      </div>
      <div class="col-6 mb-3">
        <label for="productServiceBusinessLine" class="form-label">Business Line</label>
        <input type="text" id="productServiceBusinessLine" class="form-control"
          formControlName="productServiceBusinessLine" [readonly]="true">
        <div
          *ngIf="form.get('productServiceBusinessLine')?.touched && form.get('productServiceBusinessLine')?.errors || formInvalid">
          <span class="form-error">{{validatorErrorMessage('productServiceBusinessLine')}}</span>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="productServiceCustomerType" class="form-label">Tipo di cliente</label>
        <input type="text" id="productServiceCustomerType" class="form-control"
          formControlName="productServiceCustomerType" [readonly]="true">
        <div
          *ngIf="form.get('productServiceCustomerType')?.touched && form.get('productServiceCustomerType')?.errors || formInvalid">
          <span class="form-error">{{validatorErrorMessage('productServiceCustomerType')}}</span>
        </div>
      </div>
      <div class="col-6 mb-3" *ngIf="!isNew; else registerProvince">
        <label for="province">Provincia</label>
        <app-province-filter [provincia]="form.get('province')?.value" [includeAll]="true"
          (valueChange)="updateProvincia($event)"></app-province-filter>
      </div>
      <ng-template #registerProvince>
        <div class="col-6 mb-3" #registerProvince>
          <label for="province mb-3">Registra le province:</label>
          <hr>
          <app-province-select (valuesChange)="handleValuesChange($event)"></app-province-select>
        </div>
      </ng-template>
    </div>

  </form>
  <div class="d-flex flex-row-reverse gap-2">
    <button class="btn btn-primary text-light" (click)="savePrice()"><i class="bi bi-floppy-fill text-light"></i>
      Salva</button>
    <button *ngIf="!isNew" class="btn btn-danger text-light" (click)="deletePrice()"><i
        class="bi bi-x-lg text-light pointer"></i>
      Elimina</button>
  </div>
</div>

<app-alert *ngIf="showDeleteConfirm" (save)="confirmDelete($event)" [data]="{ type: undefined }"
  [title]="'Conferma eliminazione'" [message]="'Sei sicuro di voler eliminare il pagamento ' + selectedPrice?.id + '?'">
</app-alert>

<ng-template #resultRow let-r="result" let-t="term">
  <span class="me-2">{{r.type}} - {{r.docRef}}:</span>
  <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>
