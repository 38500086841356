import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FHService, Product, ModalService } from 'npx-family-happy-common';
import { Subject, takeUntil } from 'rxjs';
import { ProductServiceDashboard } from '../../product-services/product-services';
import { CommonModule } from '@angular/common';
import { FHServiceSelectComponent } from 'src/app/shared/components/custom-select/fh-service-select/fh-service-select.component';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, FHServiceSelectComponent, AlertComponent, NgbTypeaheadModule]
})
export class ProductDetailComponent implements OnInit, OnDestroy {

  form = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    name: new UntypedFormControl(null, [Validators.required]),
    businessLine: new UntypedFormControl(null, [Validators.required]),
    customerType: new UntypedFormControl(null, [Validators.required]),
    serviceName: new UntypedFormControl(null, [Validators.required]),
    serviceID: new UntypedFormControl(null, [Validators.required]),
    serviceScript: new UntypedFormControl(''),
    stripeID: new UntypedFormControl(''),
    type: new UntypedFormControl(null, [Validators.required]),
    service: new UntypedFormControl(null, [Validators.required]),
    disabled: new UntypedFormControl(null)
  })

  selectedProduct: Product | undefined;
  isNew: boolean = false
  showDeleteConfirm:boolean = false;
  formInvalid = false
  private unsubscribe = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute, private router:Router, private productService: ProductServiceDashboard, private modalService:ModalService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe((param) => {
      const id = param.get('id') ?? -1;
      if (id === 'create') {
        this.isNew = true;
      } else {
        if (id === -1) {
          this.goBackToProducts();
        } else {
          this.productService.getProductByID(+id).subscribe((product: Product) => {
            this.selectedProduct = product;
            this.initForm(product);
          })
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  saveProduct(){
    if(this.form.valid){
      const formValue = this.form.value;
      const product: Product = {
        id: formValue.id,
        stripeID: formValue.stripeID,
        name: formValue.name,
        service: {
          id: formValue.serviceID,
          businessLine: formValue.businessLine,
          customerType: formValue.customerType,
          name: formValue.serviceName,
          script: '',
          disabled: formValue.disabled
        },
        type: formValue.type,
      }
      this.productService.saveProduct(product).subscribe(() => this.modalService.showSuccessMessage('Prodotto aggiornato'));
    } else {
      this.formInvalid = true
    }
  }

  deleteProduct(){
    this.showDeleteConfirm = true;
  }

  confirmDelete(answer: boolean){
    if (answer && this.selectedProduct && this.selectedProduct.id > -1) {
      this.productService.deleteProductById(this.selectedProduct.id).subscribe(() => {
        this.modalService.showSuccessMessage('Prodotto eliminato');
        this.goBackToProducts();
      });
    }
    this.showDeleteConfirm = false;
  }

  updateFHService(service:FHService | undefined){
    if(service === undefined) {
      this.form.controls.businessLine.reset();
      this.form.controls.customerType.reset();
      this.form.controls.serviceName.reset();
      this.form.controls.serviceID.reset();
      this.form.controls.serviceScript.reset();
      this.form.controls.service.reset();
    }
    this.form.controls.businessLine.setValue(service?.businessLine)
    this.form.controls.customerType.setValue(service?.customerType)
    this.form.controls.serviceName.setValue(service?.name)
    this.form.controls.serviceID.setValue(service?.id)
    this.form.controls.serviceScript.setValue(service?.script)
    this.form.controls.service.setValue(service)

  }

  validatorErrorMessage(fieldName:string){
    const field = this.form.get(fieldName);
    let message = ''
    if(field?.errors){
      if(field?.errors['required']){
        message = `Questo campo è obbligatorio`
      }
    }
    return message
  }


  goBackToProducts(){
    this.router.navigate(['/products/'])
  }
  initForm(product:Product){
    this.form.setValue({
      ...product,
      name: product.name,
      type: product.type,
      businessLine: product.service.businessLine,
      customerType: product.service.customerType,
      serviceName: product.service.name,
      serviceID: product.service.id,
      serviceScript: product.service.script,
      disabled:product.service.disabled
    })
  }
}
