import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-mail-template-tooltip',
	templateUrl: './mail-variables-tooltip.component.html',
  styleUrls: ['./mail-variables-tooltip.component.scss'],
  standalone:true,
  imports:[CommonModule, NgbTooltipModule]
})

export class MailVariableTooltipComponent {
  @Input() label!: string;
  @Input() description!: string;
  @Input() textValue!: string;
  @Input() variable:any = {}
  @Output() save = new EventEmitter<string>();

  constructor(){}
}
