import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet, Routes } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalService } from 'npx-family-happy-common';
import { CommonModule } from '@angular/common';
import { HeaderBarComponent } from './shared/components/header-bar/header-bar.component';
import { SideMenuComponent } from './shared/components/side-menu/side-menu.component';
import { LoadingModalComponent } from './shared/components/modals/loading-modal/loading-modal.component';
import { OutcomeModalComponent } from './shared/components/modals/outcome-modal/outcome-modal.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastComponent } from './shared/components/toast/toast.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AppModule } from './app.module';
import { ToastService } from './shared/services/toast-service/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone:true,
  imports:[CommonModule, HeaderBarComponent, SideMenuComponent, LoadingModalComponent, OutcomeModalComponent, NgbToastModule,
    ToastComponent , RouterOutlet, AngularFireModule, AppModule],
})
export class AppComponent implements AfterViewInit {
  title = 'piattaforma-babysitter';
  showMenu = false;
  showHeader = true;
  loading!: boolean;
  showOutcome!: BehaviorSubject<boolean>;
  modalMessage!: string;
  outcomeMessage!: string;
  outcomeType!: string;

  @ViewChild(ToastComponent) toastComponent!: ToastComponent;

  constructor(private router: Router, private modalService: ModalService, private toastService:ToastService) {
    this.modalService.showLoading.asObservable().subscribe((show) => {
      setTimeout(() => this.loading = show, 0)
    });
    this.showOutcome = this.modalService.showOutcome;
    this.modalService.message.subscribe(message => {
      this.modalMessage = message;
    });
    this.modalService.outcomeMessage.subscribe(message => {
      this.outcomeMessage = message;
    });
    this.modalService.outcomeType.subscribe(type => {
      this.outcomeType = type;
    })
    localStorage.removeItem('PiattaformaBabysitter.filters');
    this.router.events.subscribe((navigation) => {
      if (navigation instanceof NavigationEnd) {
        this.showHeader = !(navigation.url.startsWith('/login') || navigation.url.startsWith('/register'));
      }
    })
  }
  ngAfterViewInit(): void {
    this.toastService.setChildComponent(this.toastComponent);
  }
}

