export interface CaregiverScore {
  docRef: string;
  overall: number;
  scoreOffset: number;
  subRenewal: number;
  chosen: number;
  goodBehavior: number;
  parentPayment: number;
  disostuzioneCertified: number;
  referral: number;
  referenceScore: number;
  postSharing: number;
  googleReviews: number;
  latestUpdate: Date;
}

export enum CaregiverScoreType {
  SUBRENEWAL='SUBRENEWAL',
  CHOSEN='CHOSEN',
  GOOD_BEHAVIOR='GOOD_BEHAVIOR',
  PARENT_PAYMENT='PARENT_PAYMENT',
  DISOSTRUZIONE_CERTIFIED='DISOSTRUZIONE_CERTIFIED',
  REFERRAL='REFERRAL',
  REFERENCES='REFERENCES',
  POST_SHARING='POST_SHARING',
  GOOGLE_REVIEWS='GOOGLE_REVIEWS',
  OFFSET='OFFSET'
}
